import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Voltar from '../../components/Links/Voltar';

import { connect } from 'react-redux';
import * as actionsProdutos from '../../actions/produtos';
import * as actionsCategorias from '../../actions/categorias';
import AlertGeral from '../../components/Alert/Geral';
import { Link } from 'react-router-dom';

import InputSimples from '../../components/Inputs/Simples';
import TextAreaSimple from '../../components/Inputs/TextAreaSimple';
import SearchCategories from '../../components/Search/Categories';
import { formatMoneyOnChange } from '../../utils';

class NovoProduto extends Component {

    state = {
        nome: "",
        descricao: "",
        categoria: [],
        preco: "",
        promocao: "",
        aviso: null,
        erros: {},
        categoriesItems: [],
        success: false
    }

    getCategorias(props){
        const { usuario, getCategorias } = props;
        if(usuario) getCategorias(usuario.loja);
    }

    componentDidMount(){
        this.getCategorias(this.props);
    }
    componentDidUpdate(prevProps){
        if( !prevProps.usuario && this.props.usuario ) this.getCategorias(this.props);
    }

    searchCategories = (search) => {
		const _searchFormatted = search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

		const _search = this.props.categorias.filter(fiItem => fiItem.nome.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(_searchFormatted))
        return this.setState({ categoriesItems: _search });
    }

	callbackCategories = (data) => {
		return this.setState({ categoria: data })
    }

    validate(){
        const { nome, descricao, categoria, preco } = this.state;
        const erros = {};

        if(!nome) erros.nome = "Preencha aqui com o nome do produto";
        if(!categoria) erros.categoria = "Preencha aqui com a categoria do produto";
        if(!descricao) erros.descricao = "Preencha aqui com a descrição do produto";
        if(!preco) erros.preco = "Preencha aqui com o preço do produto";

        this.setState({ erros });
        return !( Object.keys(erros).length > 0 );
    }

    goAdd(){
        const { usuario, salvarProduto } = this.props;
        if(!usuario) return null;
        
        if(!this.validate()) return null;

        salvarProduto(this.state, usuario.loja, (error) => {
            this.setState({ aviso: { status: !error, msg: error ? error.message : "Produto criado com sucesso" } });
            !error && this.setState({ success: true })
        });
    }

    onChangeInput = (field, value) => this.setState({ [field]: value })

    renderDados(){
        const { nome, descricao, categoria, preco, promocao, erros, aviso } = this.state;
        const { categorias } = this.props;

        if(!categoria || !categorias) return null;

		var _currentCategories = [];
		categorias.filter(fiItem => categoria.map(item => fiItem._id === item && _currentCategories.push(fiItem)))

        return (
            <div className="content">
                <div className="Page-Title">
                    <Titulo tipo="h1" titulo="Novo Produto"/>
                    <AlertGeral aviso={aviso} />
                </div>

                <InputSimples type="text" label="Nome" value={nome} onChange={(e) => this.onChangeInput("nome", e.target.value)} error={erros.nome} />

                <TextAreaSimple type="text" label="Descrição" value={descricao} onChange={(e) => this.onChangeInput("descricao", e.target.value)} error={erros.descricao} />
                
                <InputSimples type="text" label="Preço" value={preco} onChange={(e) => this.onChangeInput("preco", formatMoneyOnChange(e.target.value))} error={erros.preco} />

                <InputSimples type="text" label="Valor Promocional" value={promocao} onChange={(e) => this.onChangeInput("promocao", formatMoneyOnChange(e.target.value))} error={erros.promocao} />

                <SearchCategories data={this.state.categoriesItems || []} currentCategories={_currentCategories || []} searchAction={(search) => this.searchCategories(search)} callback={(newData) => this.callbackCategories(newData)} />
                    
                <div className="ButtonSubmit">
                    <button onClick={() => this.goAdd()}>Criar Agora</button>
                </div>

            </div>
        )
    }

    renderSuccess(){
        return (
            <div className="success">
              <h1>Tudo certo!</h1>
              <p>Produto criado com sucesso, clique no botão abaixo para ver mais :D</p>
              <div className="action">
                <Link to={`/produtos`}>Ver Produtos</Link>
              </div>
            </div>
        )
    }

    render(){
        const { success } = this.state;

        return (
            <div className="newProduct container-centered">
                <div className="head">
					<Voltar history={this.props.history} />
				</div>
                { success ? this.renderSuccess() : this.renderDados() }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    produto: state.produto.produto,
    categorias: state.categoria.categorias,
    usuario: state.auth.usuario
});

export default connect(mapStateToProps, { ...actionsCategorias, ...actionsProdutos })(NovoProduto);