import React,{ Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Pesquisa from '../../components/Inputs/Pesquisa';
import Paginacao from '../../components/Paginacao/Simples';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../actions/produtos';
import ProductTable from '../../components/Table/ProductTable';
import Loading from '../../components/Loading';
import { formatMoney } from '../../utils';

import { ReactComponent as IconStar } from '../../assets/saturnia-star.svg';
import {CSVLink} from "react-csv";
import { saveAs } from 'file-saver'; // For downloading the file

class Produtos extends Component {

    state = {
        pesquisa: "",
        atual: 0,
        limit: 100,
        ordem: "decrescente",
        refreshing: false
    }

    getProdutos(props){
        const { atual, limit, pesquisa, ordem } = this.state;

        if(!this.props.usuario) return null;

        if(pesquisa) this.props.getProdutosPesquisa(pesquisa, ordem, atual, limit, this.props.usuario.loja);
        else this.props.getProdutos(ordem, atual, limit, this.props.usuario.loja);
    }

    componentDidMount(){
		if(this.props.history.location.state && this.props.history.location.state.currentPage) return this.setState({ atual: this.props.history.location.state.currentPage });
        this.getProdutos(this.props);
        this.props.limparProduto();
    }

    componentDidUpdate(prevProps){
        if(!prevProps.usuario && this.props.usuario) this.getProdutos(this.props);
    }

    handleSubmitPesquisa(){
        this.setState({ atual: 0 }, () => this.getProdutos(this.props));
    }

    refreshList = () => {
        const { atual, limit, pesquisa, ordem } = this.state;

		this.setState({ refreshing: true })
		
        if(pesquisa) this.props.getProdutosPesquisa(pesquisa, ordem, atual, limit, this.props.usuario.loja);
        else this.props.getProdutos(ordem, atual, limit, this.props.usuario.loja);

		const timer = setTimeout(() => {
			this.setState({ refreshing: false })
			clearTimeout(timer)
		}, 1800);
	}

    onChangePesquisa = (ev) => this.setState({ pesquisa: ev.target.value })

    changeNumeroAtual = (atual) => this.setState({ atual }, () => this.getProdutos(this.props));

    changeOrdem = (ev) => this.setState({ ordem: ev.target.value }, () => this.getProdutos(this.props));

    renderToggleAvailable(produto) {
        return (
            <div className={`toggle ${produto.disponibilidade ? 'true' : 'false'}`} onClick={() => this.props.updateProduto({ ...produto, disponibilidade: produto.disponibilidade ? 'indisponivel' : 'disponivel' }, produto._id, this.props.usuario.loja, () => this.refreshList())}>
                <div className={`toggle-selector ${produto.disponibilidade ? 'true' : 'false'}`}></div>
            </div>
        )
    }

    handleChangeHightlight = (produto) => {
        if(!produto.destaque && this.props.produtos?.docs?.filter(item => item.destaque).length >= 8) return window.alert("Quantidade máxima em destaque atingida! (8 produtos)")
        this.props.updateProduto({ ...produto, destaque: !produto.destaque, disponibilidade: produto?.disponibilidade ? 'disponivel' : 'indisponivel' }, produto._id, this.props.usuario.loja, () => this.refreshList())
    }

    renderHightlight(produto) {
        return <IconStar onClick={() => this.handleChangeHightlight(produto)}
        height={20} width={20} style={{ position: 'absolute', left: 15, top: 'calc(50% - 10px)', cursor: 'pointer', fill: produto?.destaque ? 'rgb(245 199 69)' : 'rgba(0,0,0,.3)' }} />
    }

    generateXML = () => {
        const { produtos } = this.props;

        const fbprod = [];
        produtos?.docs?.forEach((item) => {
            fbprod?.push({
                'id': item._id,
                'title': item.titulo,
                'description': item.descricao,
                'availability': item.disponibilidade?'in stock':'out of stock',
                'condition': 'new',
                'price': `${item.preco} BRL`,
                'link': `https://loja.juliafloresrj.com.br/product/${item.title}?id=${item._id}`,
                'image_link': `https://apiecommerce.juliafloresrj.com.br/public/images/${item.fotos[0]}`,
                'brand': 'Julia Flores'
            });
        });

        let xml = `<?xml version="1.0"?>
<rss xmlns:g="http://base.google.com/ns/1.0" version="2.0">
<channel>
<title>Test Store</title>
<link>http://www.juliafloresrj.com.br</link>
<description>Julia Flores RJ - A Floricultura mais bem avaliada do Rio de Janeiro.</description>\n`;

        // Loop through each item and add it to the XML
        fbprod.forEach(item => {
            xml += `<item>
<g:id>${item.id}</g:id>
<g:title>${item.title}</g:title>
<g:description>${item.description}</g:description>
<g:link>${item.link}</g:link>
<g:image_link>${item.image_link}</g:image_link>
<g:brand>${item.brand}</g:brand>
<g:condition>${item.condition}</g:condition>
<g:availability>${item.availability}</g:availability>
<g:price>${item.price}</g:price>
</item>\n`;
        });

        xml += `</channel>
</rss>`;

        // Create a Blob from the XML string
        const blob = new Blob([xml], { type: 'text/xml' });

        // Save the Blob as a file
        saveAs(blob, 'products.xml');
    };

    render(){
        const { pesquisa, ordem, refreshing } = this.state;
        const { produtos } = this.props;
        
        const dados = [];
        const fbprod = [];
        produtos?.docs?.forEach((item) => {
            dados?.push({
                "Ações": `/produto/${item._id}`,
                "Highlight": this.renderHightlight(item),
                "Produto": item.titulo,
                "Preço": formatMoney(item.preco),
                "Quantidade": item.quantidade || "Não informada",
                "Disponibilidade": this.renderToggleAvailable(item)
            });
            fbprod?.push({
                'id': item._id,
                'title': item.titulo,
                'description': item.descricao,
                'availability': item.disponibilidade?'in stock':'out of stock',
                'condition': 'new',
                'price': `${item.preco} BRL`,
                'link': `https://loja.juliafloresrj.com.br/product/${item.title}?id=${item._id}`,
                'image_link': `https://apiecommerce.juliafloresrj.com.br/public/images/${item.fotos[0]}`,
                'brand': 'Julia Flores'
            });
        });

        return produtos ? (
            <div className="Products">
                
                <div className="Page-Title">
                    <Titulo tipo="h1" titulo="Produtos"/>
                </div>

                <div className="actionsTollBar">
                    <Link to="/newproduct">
                        <div className="btnIcon plus" style={{ marginLeft: 0 }}></div>
                    </Link>
                    <Pesquisa valor={pesquisa}
                        placeholder={"Pesquisar Produto"}
                        onChange={(ev) => this.onChangePesquisa(ev)}
                        onClick={() => this.handleSubmitPesquisa()}/>
                </div>

                <ProductTable cabecalho={["Highlight", "Produto", "Preço", "Quantidade", "Disponibilidade"]} currentPage={this.state.atual} gridClass="Products" refreshState={''} dados={dados} refreshList={() => this.refreshList()} />

                <CSVLink data={fbprod}  filename='produtos_meta.csv'
                         target='_blank'
                ><div className="btnIcon fas fa-file-alt" title={"Download CSV - META"}></div></CSVLink>
                <button title="XML facebook store" className="btnIcon fas fa-file-code" onClick={this.generateXML}></button>
                <Paginacao atual={this.state.atual} total={this.props.produtos ? this.props.produtos.total : 0} 
                    limite={this.state.limit} onClick={(numeroAtual) => this.changeNumeroAtual(numeroAtual)} />
                
            </div>
        ) : <Loading />;
    }
}

const mapStateToProps = state => ({
    produtos: state.produto.produtos,
    usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Produtos);