import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/pedidos';

import AlertGeral from '../../components/Alert/Geral';
import DynamicSelector from '../../components/Lista/DynamicSelector';

function StatusRecods({ records, admin, order, setNovoStatusPagamento, setNovoStatusEntrega, refreshList }) {
	const [warning, setWarning] = useState(null);

	function newStatePayment({ status, justifyText }) {
		setWarning(null);
		if(!status) return setWarning({ aviso: { status: false, msg: "Preencha o campo para enviar um novo status" }});
		
		setNovoStatusPagamento({ status, justifyText: justifyText || null }, order.pagamento._id || order.pagamento, order._id, admin.loja, (error) => {
			if(error) return setWarning({ status: false, msg: error.message });
			else refreshList();
		});
	}

  function newStateDelivery({ status, justifyText }) {
		setWarning(null)
		if(!status) return setWarning({ aviso: { status: false, msg: "Preencha o campo para enviar um novo status" }});
		
		setNovoStatusEntrega({ status, justifyText: justifyText || null }, order.entrega._id, order._id, admin.loja, (error) => {
			if(error) return setWarning({ status: false, msg: error.message })
			else refreshList();
		});
	}

	if(!records) {
		return <div></div>;
	}else {
		return (
			<>
				<div className="status-container">
					<h2>Status de Pagamento</h2>
					<AlertGeral aviso={warning}/>
					<DynamicSelector available={order.cancelado} type="Pagamento" records={records.pagamento}
									 onAdd={({status, justifyText}) => newStatePayment({status, justifyText})}/>
				</div>
				<div className="status-container">
					<h2>Status de Entrega</h2>
					<AlertGeral aviso={warning}/>
					<DynamicSelector available={order.cancelado} type="Entrega" records={records.entrega}
									 onAdd={({status, justifyText}) => newStateDelivery({status, justifyText})}/>
				</div>
			</>
		)
	}
}

export default connect(null, actions)(StatusRecods);