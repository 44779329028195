import React from 'react';

const InputSimples = ({ type, label, value, onChange, error }) => (
	<div className="Input-Simples">
		{ label && (<label>{label}</label>) }
		<input type={type} value={value} onChange={onChange} className={`${error ? "input-error" : ""}`}/>
		{ error && (<small className="small-danger">{error}</small>) }
	</div>
)

export default InputSimples;