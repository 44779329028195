import { useEffect, useState } from 'react'
import Loading from '../../components/Loading';
import { formatMoney } from '../../utils'

export default function PaymentsReports({ reports, handleFind, clearReports, loading }) {
  const [initial, setInitial] = useState("")
  const [final, setFinal] = useState("")

  useEffect(() => {
    clearReports();
  }, []);

  return (
    <div className="report-section">
      <h3>Faturamento</h3>
      <h2>Listagem dos pedidos com <span>Pagamentos Aprovados.</span></h2>
      <div className="report-search">
        <div className="input-search">
          <label>Do dia</label>
          <input type="date" min="2021-04-23" name="date" autoComplete="new-password" placeholder="Do dia" onChange={(e) => setInitial(e.target.value)} />
        </div>
        <div className="input-search">
          <label>Ao dia</label>
          <input type="date" name="date" autoComplete="new-password" placeholder="Ao dia" onChange={(e) => setFinal(e.target.value)} />
        </div>
        <button onClick={() => handleFind({ initial, final })}>Gerar Relatório</button>
      </div>
      
      { loading ? <Loading /> : (reports && (
          <div className="report-details">
            <div className="totalAmount">
              <label>Faturamento</label>
              <span>{formatMoney(reports.total)}</span>
            </div>
            <div className="paymentMethods">
              <div className="item">
                <i className="icon fas fa-credit-card"></i>
                <label>{reports.paymentMethods.creditCard.total} {reports.paymentMethods.creditCard.total === 1 ? `venda` : 'vendas'}</label>
                <span>{formatMoney(reports.paymentMethods.creditCard.totalAmount)}</span>
              </div>
              <div className="item">
                <span className="icon pix">PIX</span>
                <label>{reports.paymentMethods.pix.total} {reports.paymentMethods.pix.total === 1 ? `venda` : 'vendas'}</label>
                <span>{formatMoney(reports.paymentMethods.pix.totalAmount)}</span>
              </div>
              <div className="item">
                <i className="icon fas fa-store"></i>
                <label>{reports.paymentMethods.presential.total} {reports.paymentMethods.presential.total === 1 ? `venda` : 'vendas'}</label>
                <span>{formatMoney(reports.paymentMethods.presential.totalAmount)}</span>
              </div>
          </div>
        </div>
      )) }
    </div>
  )
}