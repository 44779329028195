import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { apiLinkImages, merchantCenterAccountId } from '../../config';
import { Notification, notify } from '../../utils/notification';
import {getProductGoogle, listProductsGoogle} from "../../actions/google-applications";

export default function MerchantCenter({ admin, products, saveProducts, refreshList, getProduct }) {

  const [productsSelected, setProductsSelected] = useState([]);
  const [save, setSave] = useState(true);
  const [notification, setNotification] = useState('');
  
  const [loading, setLoading] = useState(true);
  const [time, setTime] = useState(Date.now());
  
  useEffect(() => {
    if(loading){
      var newList = []
      products?.docs.forEach(item => {
        if(item.addedMerchantCenter) newList.push(item._id)
      })
      setProductsSelected(newList);
    }
    
    if(save){
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        setLoading(false);
        clearInterval(interval);
      };
    }
  }, [products?.docs, time])

  function onChangeProduct(state, productId) {
    const list = state ? productsSelected.filter(fiItem => fiItem !== productId) : [ ...productsSelected, productId ];
    setProductsSelected(list)
    setSave(false)
  }

  function handleSave() {
    setLoading(true)
    saveProducts(productsSelected, admin.loja)
    notify("Produtos atualizados com sucesso.", setNotification)
    setSave(true)
    setTimeout(() => {
      refreshList();
      setLoading(false)
    }, 3000)
  }

  return loading ? <Loading /> : (
    <div className="MerchantCenter">
      <div className="head">
        <div className="text">
          <h1>Produtos conectados</h1>
          <p>O Google Merchant Center pode levar até 5 minutos para sincronizar os dados dos produtos, por conta da avaliação de conteúdo.</p>
        </div>
        <div className="actions">
          <button onClick={() => window.open(`https://merchants.google.com/mc/items?a=${merchantCenterAccountId}&hl=pt&pli=1`)}>Ir para o Merchant Center <i class="fas fa-external-link-alt"></i></button>
        </div>
      </div>

      <Notification notification={notification} />
      { !save && <button className="button-float" onClick={() => handleSave()}>Salvar</button>}

      <div className="products">
        {products?.docs.map((item, index) => {

          const verify = productsSelected.filter(fiItem => fiItem === item._id)[0] ? true : false;

          const minutos = (Math.abs(new Date(item.updatedAt) - new Date()) / 1000) / 60;

          return (
            <div key={index} className={`product${minutos < 2 ? " disabled" : ''}`} onClick={() => minutos > 2 && onChangeProduct(verify, item._id)}>
              <div className="picture" style={{ backgroundImage: `url(${apiLinkImages}/public/images/${item.fotos[0]})` }}></div>
              <div className="details">
                <h4>{item.titulo}</h4>
                {/*<h4 onMouseOver={getProductGoogle(item._id)}>{item.titulo}</h4>*/}
                {/*<h6 onMouseOver={listProductsGoogle()}>INFO</h6>*/}
                <div className={`checkbox ${minutos < 2 ? 'sync' : ''} ${verify ? 'active' : ''}`}>
                  { minutos > 2 ? <i className="fas fa-check"></i> : <p><i className={`fas fa-arrow-${verify ? 'up' : 'down'}`}></i> Sincronizando...</p>}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}