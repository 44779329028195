import {
	GET_ENTREGAS,
	GET_ENTREGA,
	LIMPAR_ENTREGA,
	ATUALIZAR_ENTREGA,
	REPORTS_SHIPPING
} from '../actions/types';

export default (state = {}, action) => {
	switch(action.type){
		case GET_ENTREGAS:
			return {
				...state,
				entregas: action.payload.entregas
			}
		case GET_ENTREGA:
			return {
				...state,
				entrega: action.payload.entrega,
				entregaRegistros: action.payload.registros
			}
		case LIMPAR_ENTREGA:
			return {
				...state,
				entrega: null
			}
		case ATUALIZAR_ENTREGA:
			return {
				...state,
				entrega: {
					...state.entrega,
					entrega: {
						...state.entrega.entrega
					}
				}
			}
		case REPORTS_SHIPPING:
			return {
				...state,
				reports: action.payload
			}
		default:
			return state;
	}
}