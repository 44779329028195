import React, { Component } from 'react';
import moment from 'moment';
import Titulo from '../../components/Texto/Titulo';
import Tabela from '../../components/Tabela/Simples';
import Paginacao from '../../components/Paginacao/Simples';

import { connect } from 'react-redux';
import * as actions from '../../actions/avaliacoes';

class Avaliacoes extends Component {

	state = { atual: 0, limit: 10 }

	getAvaliacoes(){
		const { atual, limit } = this.state;
		const { usuario, produto } = this.props;
		if(!usuario || !produto) return;
		this.props.getAvaliacoes(produto._id, usuario.loja, atual, limit);
	}

	componentDidMount(){
		this.getAvaliacoes(this.props);
	}
	componentDidUpdate(prevProps){
		if((!prevProps.usuario || !prevProps.produto) && this.props.usuario && this.props.produto)
		this.getAvaliacoes(this.props);
	}

	changeNumeroAtual = (atual) => {
		this.setState({ atual }, () => {
			this.getAvaliacoes();
		});
	}

	render(){
		const { avaliacoes, produto } = this.props;
		const dados = [];
		(avaliacoes ? avaliacoes.docs : []).forEach((item) => {
			dados.push({
				"Ações": `/avaliacao/${item._id}`,
				"Cliente": item.nome,
				"Status": item.status === 2 ? "Aprovada" : "Aguardando Aprovação",
				"Pontuacao": item.pontuacao,
				"Data": moment(item.createdAt).format("DD/MM/YYYY") 
			});
		});

		return (
			<div className="Avaliacoes full-width">
				<div className="Page-Title">
					<Titulo tipo="h1" titulo={`Avaliações - ${produto ? produto.titulo : ""}`}/></div>

				<div className="TableMaxima">
					<Tabela cabecalho={["Cliente", "Status", "Pontuacao", "Data"]} dados={dados}/>
				</div>

				<Paginacao atual={this.state.atual} 
					total={avaliacoes ? avaliacoes.total : 0} 
					limite={this.state.limit} 
					onClick={(numeroAtual) => this.changeNumeroAtual(numeroAtual)}/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	avaliacoes: state.avaliacao.avaliacoes,
	produto: state.produto.produto
});

export default connect(mapStateToProps, actions)(Avaliacoes);