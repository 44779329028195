import { useEffect, useState } from 'react';
import Table from '../../components/Table';

export default function Products({ products }) {
	const [dados, setDados] = useState([]);

	useEffect(() => {
		if(!products) return;

		const _dados = [];
		products?.map((item) => {
			_dados.push({
				"Produto": item.titulo,
				"Disponibilidade": item.disponibilidade ? "Disponível" : "Indisponível",
				"Ações": `/produto/${item._id}`
			});
		});
		
		setDados(_dados);
	}, [products])
		
	return (
		<div className="products">
			<h2>Produtos Conectados</h2>
			<Table cabecalho={["Produto", "Disponibilidade"]} dados={dados} gridClass="productsCategory" />		
		</div>
	);
}