import React, {Component} from 'react';

import Titulo from '../../components/Texto/Titulo';
import {TextoDados} from '../../components/Texto/Dados';
import InputSelect from '../../components/Inputs/Select';
import BlocoImagens from '../../components/Imagens/Bloco';
import Voltar from '../../components/Links/Voltar';

import {connect} from 'react-redux';
import * as actions from '../../actions/produtos';
import AlertGeral from '../../components/Alert/Geral';
import SearchCategories from '../../components/Search/Categories';
import ButtonToolbar from '../../components/Button/Toolbar';

import InputSimples from '../../components/Inputs/Simples';
import TextAreaSimple from '../../components/Inputs/TextAreaSimple';
import {formatMoney, formatMoneyOnChange} from '../../utils';

class DetalhesDoProduto extends Component {

    generateStateProduto = (props) => ({
        nome: props.produto ? props.produto.titulo : "",
        disponibilidade: props.produto ? (props.produto.disponibilidade ? "disponivel" : "indisponivel") : "",
        quantidade: props.produto ? props.produto.quantidade : "",
        descricao: props.produto ? props.produto.descricao : "",
        categoria: props.produto ? props.produto.categoria : "",
        fotos: props.produto ? props.produto.fotos : "",
        preco: props.produto ? formatMoney(props.produto.preco) : "",
        promocao: props.produto ? formatMoney(props.produto.promocao) : "",
        sku: props.produto ? props.produto.sku : ""
    });

    constructor(props) {
        super();
        this.state = {
            ...this.generateStateProduto(props),
            aviso: null,
            erros: {},
            categoriesItems: []
        }
    }

    componentWillUpdate(nextProps) {
        if ((!this.props.produto && nextProps.produto) || (this.props.produto && nextProps.produto && this.props.produto.updatedAt !== nextProps.produto.updatedAt))
            this.setState(this.generateStateProduto(nextProps));
    }

    searchCategories = (search) => {
        const _searchFormatted = search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        const _search = this.props.categorias.filter(fiItem => fiItem.nome.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(_searchFormatted))
        this.setState({categoriesItems: _search});
        return console.log(this.state.categoriesItems)
    }

    callbackCategories = (data) => {
        return this.props.updateCategories(data, this.props.produto._id, this.props.usuario.loja, (error) => error && console.log(error));
    }

    validate() {
        const {nome, descricao, categoria, preco} = this.state;
        const erros = {};

        if (!nome) erros.nome = "Preencha aqui com o nome do produto";
        if (!descricao) erros.descricao = "Preencha aqui com a descrição do produto";
        if (!categoria) erros.categoria = "Preencha aqui com a categoria do produto";
        if (!preco) erros.preco = "Preencha aqui com o preço do produto";

        this.setState({erros});
        return !(Object.keys(erros).length > 0);
    }

    updateProduto() {
        const {usuario, produto, updateProduto} = this.props;
        if (!usuario || !produto || !this.validate()) return null;
        updateProduto(this.state, produto._id, usuario.loja, (error) => {
            this.setState({
                aviso: {
                    status: !error,
                    msg: error ? error.message : "Produto atualizado com sucesso!"
                }
            });
        });
    }

    deleteProduct = () => {
        const {produto, usuario, deleteProduct} = this.props;
        if (window.confirm("Você realmente deseja excluir este produto ?")) {
            deleteProduct(produto._id, usuario.loja, (error) => {
                if (error) return null;
                this.props.history.goBack()
                alert("Deletado com sucesso!")
            })
        }
    }

    onChangeInput = (field, value) => this.setState({[field]: value, changes: true});

    renderDados() {
        const {nome, disponibilidade, quantidade, descricao, categoria, preco, promocao, sku, erros} = this.state;
        const {categorias} = this.props;

        if (!categoria || !categorias) return null;

        var _currentCategories = [];
        categorias.filter(fiItem => categoria.map(item => fiItem._id === item && _currentCategories.push(fiItem)))

        return (
            <div>
                <InputSimples label="Nome" error={erros.nome} value={nome}
                              onChange={(e) => this.onChangeInput("nome", e.target.value)}/>

                <TextoDados chave="Disponíbilidade" valor={(
                    <InputSelect nome="disponibilidade" value={disponibilidade} opcoes={[
                        {label: "Disponível", value: "disponivel"},
                        {label: "Indisponível", value: "indisponivel"}
                    ]} onChange={(ev) => this.onChangeInput("disponibilidade", ev.target.value)}/>
                )}/>

                <InputSimples label="Quantidade" error={erros.quantidade} value={quantidade}
                              onChange={(e) => this.onChangeInput("quantidade", e.target.value)}/>

                <SearchCategories data={this.state.categoriesItems || []} currentCategories={_currentCategories || []}
                                  searchAction={(search) => this.searchCategories(search)}
                                  callback={(newData) => this.callbackCategories(newData)}/>

                <TextAreaSimple label="Descrição" error={erros.descricao} value={descricao} row={10} resize="none"
                                onChange={(e) => this.onChangeInput("descricao", e.target.value)}/>

                <InputSimples label="Preco" error={erros.preco} value={preco}
                              onChange={(e) => this.onChangeInput("preco", formatMoneyOnChange(e.target.value))}/>

                <InputSimples label="Valor Promocional" error={erros.promocao} value={promocao}
                              onChange={(e) => this.onChangeInput("promocao", formatMoneyOnChange(e.target.value))}/>

            </div>
        )
    }

    onRemove = (id) => {
        const {usuario, produto} = this.props;
        if (!usuario || !produto) return null;

        const {fotos: _fotos} = this.state;
        let removeImageFile = _fotos[id];
        const fotos = _fotos.filter((foto, index) => index !== id);
        if (window.confirm("Você realmente deseja remover esta imagem ("+removeImageFile+")?")) {
            this.props.removeProdutoImagens(fotos, removeImageFile, produto._id, usuario.loja, (error) => {
                this.setState({
                    aviso: {
                        status: !error,
                        msg: error ? error.message : "Foto do produto removida com sucesso!"
                    }
                });
            });
        }
    }

    handleUploadFoto = (ev) => {
        const {usuario, produto} = this.props;
        if (!usuario || !produto) return null;

        const data = new FormData();
        data.append("files", ev.target.files[0]);

        this.props.updateProdutoImagens(data, produto._id, usuario.loja, (error) => {
            this.setState({
                aviso: {
                    status: !error,
                    msg: error ? error.message : "Fotos do produto atualizadas com sucesso!"
                }
            });
        });
    }

    renderImagens() {
        const {fotos} = this.state;
        return <BlocoImagens imagens={(fotos || [])} handleSubmit={this.handleUploadFoto} onRemove={this.onRemove}/>
    }

    render() {
        if (!this.props.produto) return null;
        return (
            <>
                <div className="head">
                    <Voltar history={this.props.history} historyPath={"/produtos/"}/>
                    <ButtonToolbar type="danger" label="Excluir Produto" icon="fas fa-trash"
                                   onClick={() => this.deleteProduct()}/>
                </div>
                <div className="content">
                    <div className="Page-Title"
                         style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <Titulo tipo="h1" titulo={this.props.produto.titulo}/>
                        <div className="ButtonSubmit">
                            <button onClick={() => this.updateProduto()}>Salvar</button>
                        </div>
                        <AlertGeral aviso={this.props.produto.aviso}/>
                    </div>
                    {this.renderDados()}
                    {this.renderImagens()}
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    produto: state.produto.produto,
    categorias: state.categoria.categorias,
    usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(DetalhesDoProduto);