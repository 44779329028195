import { useEffect, useState } from 'react';
import Voltar from '../../components/Links/Voltar';
import InputSimples from '../../components/Inputs/Simples';

export default function Details({ history, client, updateClient, notify, setNotification }) {

	const [clientState, setClientState] = useState({});

	useEffect(() => {
		if(!client) return;
		setClientState({ name: client.nome, email: client.email, phone: client.telefones[0], cpf: client.cpf })
	}, [client])

	function handleUpdate(){
		updateClient(clientState, client._id, client.loja, (err, success) => {
			if(err) return notify(err.message, setNotification, "error");
			
			notify("Cliente atualizado com sucesso!", setNotification)
		})
	}

	return (
		<div className="details">
			<div className="head">
        <Voltar history={history} />
				<h1>Cliente</h1>
      </div>

			<div className="body">
				<div className="item">
					<InputSimples type="text" value={clientState.name} label="Nome" onChange={(e) => setClientState({ ...clientState, name: e.target.value })} />
					<InputSimples type="text" value={clientState.cpf} label="CPF" onChange={(e) => setClientState({ ...clientState, cpf: e.target.value })} />
				</div>
				<div className="item">
					<InputSimples type="text" value={clientState.email} label="E-mail" onChange={(e) => setClientState({ ...clientState, email: e.target.value })} />
					<InputSimples type="text" value={clientState.phone} label="Telefone" onChange={(e) => setClientState({ ...clientState, phone: e.target.value })} />
				</div>
			
				<div className="ButtonSubmit">
					<button onClick={handleUpdate}>Atualizar dados</button>
				</div>
			</div>
		</div>
	)
}