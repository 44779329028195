import { useState, useEffect } from 'react';

import { connect } from 'react-redux'
import * as actions from '../../actions/email-marketing'

import Campaigns from './campaigns';
import Audiences from './audiences';
import Templates from './templates';
import { verifyAdmin } from '../../utils/verify-admin'

function EmailMarketing({ history, admin, clientes, leads, campaigns, templates, listCampaigns, listTemplates, saveTemplate, removeTemplate, audiences, listAudiences, listClientsAudience, listLeadsAudience, saveAudience, removeAudience, createCampaign }){

  const [section, setSection] = useState("campaigns")

  function refreshCampaigns() {
    listCampaigns(admin.loja, null)
  }

  function refreshTemplates() {
    listTemplates(admin.loja, null)
  }

  function refreshAudiences() {
    listAudiences(admin.loja, null)
  }

  useEffect(() => {
    if(admin && !campaigns) refreshCampaigns()
    if(admin && !templates) refreshTemplates()
    if(admin && !audiences) refreshAudiences()
    if(admin && !clientes) listClientsAudience(admin.loja, () => {})
    if(admin && !leads) listLeadsAudience(admin.loja, () => {})
  }, [templates, audiences])

  if(!admin || (admin && !verifyAdmin(admin, 'owner'))) {
    return null;
  }

  return (
    <div className="EmailMarketing">
      <div className="container-centered">
        <div className="wrapper">
          <h2><i className="fas fa-envelope-open-text"></i> E-mail Marketing</h2>
          
          <div className="menu">
            <button onClick={() => setSection("campaigns")} className={section === "campaigns" && `active`}><i className="fas fa-paper-plane"></i> Campanhas</button>
            <button onClick={() => setSection("audiences")} className={section === "audiences" && `active`}><i className="fas fa-users"></i> Audiências</button>
            <button onClick={() => setSection("templates")} className={section === "templates" && `active`}><i className="fas fa-paint-roller"></i> Templates de E-mails</button>
          </div>

          { section === "campaigns" && <Campaigns admin={admin} campaigns={campaigns} refreshList={refreshCampaigns} templates={templates} audiences={audiences} clientsLength={clientes ? clientes.length : 0} leadsLength={leads ? leads.length : 0} createCampaign={createCampaign} /> }
          { section === "audiences" && <Audiences admin={admin} audiences={audiences} refreshList={refreshAudiences} clientes={clientes} leads={leads} listClientsAudience={listClientsAudience} listLeadsAudience={listLeadsAudience} saveAudience={saveAudience} removeAudience={removeAudience} /> }
          { section === "templates" && <Templates admin={admin} templates={templates} refreshList={refreshTemplates} saveTemplate={saveTemplate} removeTemplate={removeTemplate} /> }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  admin: state.auth.usuario,
  campaigns: state.emarketing.campaigns,
  templates: state.emarketing.templates,
  clientes: state.emarketing.clientes,
  leads: state.emarketing.leads,
  audiences: state.emarketing.audiences
});

export default connect(mapStateToProps, actions)(EmailMarketing);