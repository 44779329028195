import React from 'react';
import { ReactComponent as IconLoading } from '../assets/icon-flower-rotate.svg'

const Loading = () => {
  return (
    <div className="loading">
      <IconLoading />
    </div>
  )
}

export default Loading;