import React from 'react';

const Modal = ({ h1, cancelFunction, confirmFunction }) => {
  return (
    <div className="modal">
      <div className="overlay"></div>
      <div className="modal-content">
        <div className="content">
          <h1>{h1}</h1>
          <div className="actions">
            <button onClick={cancelFunction} className="cancel">Não</button>
            <button onClick={confirmFunction} className="confirm">Sim</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal;