import { useEffect } from 'react';

export const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
      const handleClickOutside = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
            callback();
          }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}

export function formatMoney(originalValue) {
  if(!originalValue) return 'R$ 0,00';

  var total = Number(originalValue).toFixed(2);
  
  const centavos = total.slice(-2);
  const centenas = total.slice(-6, -3);
  const milhares = total.slice(-9, -6);
  const milhoes = total.slice(-12, -9);
  const bilhoes = total.slice(-15, -12);
  const trilhoes = total.slice(-18, -15);

  total = `${trilhoes && `${trilhoes}.`}${bilhoes && `${bilhoes}.`}${milhoes && `${milhoes}.`}${milhares && `${milhares}.`}${centenas},${centavos}`;

  return `R$ ${total}`;
}

export const formatMoneyOnChange = (value) => {
  var total = value.replace("R$", "").replace(",", "").replace(".", "").replace(" ", "");
  if(total.length > 9) return 'R$ 0,00';
  
  const centavos = total.slice(-2);
  const centenas = total.slice(-5, -2);
  const milhares = total.slice(-8, -5);
  const milhoes = total.slice(-11, -8);
  const bilhoes = total.slice(-14, -11);
  const trilhoes = total.slice(-17, -14);

  value = `${trilhoes && `${trilhoes}.`}${bilhoes && `${bilhoes}.`}${milhoes && `${milhoes}.`}${milhares && `${milhares}.`}${centenas && `${centenas},`}${centavos}`;

  return `R$ ${value}`;
}