import React from 'react';

const Paginacao = ({ total, atual, limite, onClick }) => {
	const numeroPaginas = Math.ceil(total / limite);
	const currentPage = atual / limite;

	return numeroPaginas && (
		<div className="Paginacao flex horizontal">
			{
				[...Array(numeroPaginas)?.keys()]?.map((numero, idx) => {
					const numeroAtualDaPagina = numero * limite;

					if(((currentPage + 3) < numero) || ((currentPage - 3) > numero)) return null;

					return (
						<div className={`paginacao-item ${numeroAtualDaPagina === atual ? "paginacao-item-active":""}`} onClick={() => onClick(numeroAtualDaPagina)} key={idx}>
							{ numero + 1 }
						</div>
					);
				})
			}
			{ (currentPage >= 0 && ((currentPage + 4) < numeroPaginas) ) && <label>+{numeroPaginas - (currentPage + 4)}</label>}
		</div>
	)
}

export default Paginacao;