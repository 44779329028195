import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import moment from 'moment';
import { formatCPF } from '/';

import {
  GET_CLIENTE,
	GET_CLIENTES,
  GET_PRODUTOS,
  GET_UPSELLS,
  NEW_ORDER
} from './types';
import {formatMoney} from "./index";
import React from "react";

export const getClientesPesquisa = (termo, loja) => {
	return function(dispatch){
  axios.get(`${api}/${versao}/api/clientes/search/${termo}?loja=${loja}`, getHeaders())
  .then(response => dispatch({ type: GET_CLIENTES, payload: response.data }))
  .catch(errorHandling);
	}
}

export const getProdutosPesquisa = (termo, loja) => {
  return function(dispatch){
    axios.get(`${api}/${versao}/api/produtos/search/${termo}?loja=${loja}`, getHeaders())
    .then(response => { console.log(response.data); dispatch({ type: GET_PRODUTOS, payload: response.data })})
    .catch(errorHandling);
  }
}

export const getUpsellsPesquisa = (termo, loja) => {
  return function(dispatch){
    axios.get(`${api}/${versao}/api/upsell/search/${termo}?loja=${loja}`, getHeaders())
    .then(response => dispatch({ type: GET_UPSELLS, payload: response.data }))
    .catch(errorHandling);
  }
}

export const newOrderManual = ({ form, client, cart, cartUpsell }, cb) => {
  return function(dispatch){

    const upsells = (cartUpsell?.items?cartUpsell.items:[]);
    // const carrinho = [...cart.items, upsells].map((item) => {
    // const carrinho = [...cartUpsell.items, ...cart.items].map(item => ({
    //   produto: item._id,
    //   quantidade: item.quantity,
    //   precoUnitario: item.price,
    //   upsell: item.type && item.type === "upsell" ? item._id : null
    // }));


    var carrinho = [];

    [...cart.items, upsells].map(item => {
      if (item._id){
        carrinho.push({
          produto: item._id,
          quantidade: item.quantity,
          precoUnitario: item.price,
          upsell: item.type && item.type === "upsell" ? item._id : null
        });
      }
    });

    cart.items.forEach(item => item._id>0 ?? carrinho.push({produto: item._id,
        quantidade: item.quantity,
        precoUnitario: item.price,
        upsell: item.type && item.type === "upsell" ? item._id : null}));


    const data = {
      client,
      loja: client.loja,
      observation: form.observation,
      carrinho,
      entrega: {
        custo: form.deliveryValue,
        tipo: "manual",
        prazo: form.deliveryDate,
        endereco: {
          local: form.deliveryStreet,
          numero: form.deliveryNumber,
          complemento: form.deliveryComplement,
          bairro: form.deliveryNeighbor,
          cidade: "Rio de Janeiro",
          estado: "RJ",
          CEP: "22795-171",
          pais: "Brasil"
        },
        shippingValue: form.deliveryValue,
        shippingDate: moment(form.deliveryDate).format('DD/MM/YYYY') || "Não informado",
        shippingPeriod: form.deliveryPeriod || "Não informado",
        shippingSender: form.senderAnon === "Anônimo" ? "Anônimo" : form.senderShipping,
        shippingRecipient: form.senderReciever,
        shippingMessageSelect: form.messageCard.length > 0 ? true : false,
        shippingMessage: form.messageCard.length > 0 ? form.messageCard : ""
      },
      pagamento: {
        valor: Number(cart.total + (cartUpsell?.total? cartUpsell.total : 0)) + Number(form.deliveryValue),
        valueCart: cart.total,
        forma: "manual",
        parcelas: 1,
        enderecoEntregaIgualCobranca: false,
        endereco: {
          local: "manual",
          numero: "manual",
          complemento: "manual",
          bairro: "manual",
          cidade: "manual",
          estado: "manual",
          CEP: "manual",
          pais: "manual"
        },
        cartao: undefined
      }
    }

    axios.post(`${api}/${versao}/api/pedidos/admin?loja=${client.loja}`, data, getHeaders())
    .then(response => {
      dispatch({ type: NEW_ORDER, payload: response.data });
      dispatch(cb(response.data));
    })
    .catch(e => cb(e));
  }
};

export const createNewCliente = (form, loja, cb) => {
  const _cpf = form.clientCpf && form.clientCpf.length <= 11 ? formatCPF(form.clientCpf) : form.clientCpf;
  return function(dispatch){
    axios.post(`${api}/${versao}/api/clientes/manual?loja=${loja}`, { nome: form.clientName, email: form.clientEmail, cpf: _cpf, telefones: [ form.clientPhone ] }, getHeaders())
    .then(response => {
      dispatch({ type: GET_CLIENTE, payload: response.data })
      cb(null, response.data)
    })
    .catch(e => cb(e));
  }
}