import React, { Component } from 'react';

import Logo from '../../../images/logo.png';

import ReactToPrint from "react-to-print";
import { FiPrinter, FiX, FiMail } from 'react-icons/fi';

class ComponentToPrint extends React.Component {

  state = { printing: false }

  render() {
    const { data, cliente } = this.props;
		if(!data) return null;

    return (
      <div className="OrderPrint_wrapper">
        <div className="column">

          <div className="_header">
            <img src={Logo} alt="JuliaFloresRJ" />
            <div className="_cardInfo">
              <span>Pedido #{data.simpleId || data.entrega.simpleId}</span>
              { data.entrega.shippingMessageSelect ? 
                (<span className="_card"><FiMail /> Cartão <span className="_bold">Adicionado</span></span>) : 
                (<span className="_card not"><FiMail /> Cartão <span className="_bold">Não Adicionado</span></span>)
              }
            </div>
          </div>

          <div className="_items">
            <div className="_item">
              <span className="title">Data da entrega:</span>
              <span className="text">{data.entrega.shippingDate}</span>
            </div>
            <div className="_item">
              <span className="title">Periodo:</span>
              <span className="text">{data.entrega.shippingPeriod}</span>
            </div>
          </div>

          <div className="_items">
            <div className="_item">
              <span className="title">Quem envia:</span>
              <span className="text">{data.entrega.shippingSender}  - {data.cliente?.telefones[0] || cliente.telefones[0] || ""}</span>
            </div>

            <div className="_item">
              <span className="title">Quem Recebe:</span>
              <span className="text">{data.entrega.shippingRecipient}</span>
            </div>
          </div>

          <div className="_item">
            <span className="title">Endereço da entrega:</span>
            <span className="text">{data.entrega.endereco.local}, n°{data.entrega.endereco.numero} - {data.entrega.endereco.bairro}</span>
            { data.entrega.endereco.complemento && (<span className="text">Complemento: {data.entrega.endereco.complemento}</span>)}
          </div>

          <div className="_item">
            <span className="title">Produtos:</span>
            { (data.carrinho || data.pedido.carrinho).map((item, idx) => (
              <div>
                <span className="text">{item.quantidade} </span>
                <span className="text">{item.produto ? item.produto.titulo : item.upsell ? item.upsell.titulo : "Upsell"}</span>
              </div>
            )) }
          </div>

          { data.observation && (
            <div className="_item">
              <span className="title">Observação:</span>
              <span className="text">{ data.observation }</span>
            </div>
          ) }

          { /* Campo de obs. */ }

        </div>

        <div className="column">

          <div className="_header">
            <img src={Logo} alt="JuliaFloresRJ" />
            <div className="_cardInfo">
              <span>Pedido #{data.simpleId || data.entrega.simpleId}</span>
              { data.entrega.shippingMessageSelect ? 
                (<span className="_card"><FiMail /> Cartão <span className="_bold">Adicionado</span></span>) : 
                (<span className="_card not"><FiMail /> Cartão <span className="_bold">Não Adicionado</span></span>)
              }
            </div>
          </div>

          <div className="_items">
            <div className="_item">
              <span className="title">Data da entrega:</span>
              <span className="text">{data.entrega.shippingDate}</span>
            </div>
            <div className="_item">
              <span className="title">Periodo:</span>
              <span className="text">{data.entrega.shippingPeriod}</span>
            </div>
          </div>

          <div className="_items">
            <div className="_item">
              <span className="title">Quem envia:</span>
              <span className="text">{data.entrega.shippingSender} - {data.cliente.telefones[0]}</span>
            </div>

            <div className="_item">
              <span className="title">Quem Recebe:</span>
              <span className="text">{data.entrega.shippingRecipient}</span>
            </div>
          </div>

          <div className="_item">
            <span className="title">Endereço da entrega:</span>
            <span className="text">{data.entrega.endereco.local}, n°{data.entrega.endereco.numero} - {data.entrega.endereco.bairro}</span>
            { data.entrega.endereco.complemento && (<span className="text">Complemento: {data.entrega.endereco.complemento}</span>)}
          </div>

          <div className="_item">
            <span className="title">Produtos:</span>
            { (data.carrinho || data.pedido.carrinho).map((item, idx) => (
              <div>
                <span className="text">{item.quantidade} </span>
                <span className="text">{item.produto ? item.produto.titulo : item.upsell ? item.upsell.titulo : "Upsell"}</span>
              </div>
            )) }
          </div>

          { data.observation && (
            <div className="_item">
              <span className="title">Observação:</span>
              <span className="text">{ data.observation }</span>
            </div>
          ) }

        </div>
      </div>
    )
  }
}

class OrderPrint extends Component {
	
  render(){
    return (
      <>
        <div className="OrderPrint">
          <ComponentToPrint data={this.props.data} ref={el => (this.componentRef = el)} />
          <div className="actions">
            <ReactToPrint 
              trigger={() => <button className="btntool btntool-success"><FiPrinter /></button>}
              content={() => this.componentRef}
            />
            <button onClick={this.props.onClick} className="btntool btntool-danger"><FiX /></button>
          </div>
        </div>
      </>
    )    
	}
}

export default OrderPrint;