import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import {
	GET_PEDIDOS,
	GET_PEDIDO,
	LIMPAR_PEDIDO,
	CANCELAR_PEDIDO,
	ATUALIZAR_ENTREGA,
	LIMPAR_PEDIDOS
} from './types';

export const getPedidos = (atual, limit, loja, filters = []) => {
	const _filtered = filters ? filters.join('+') : '';
	return function(dispatch){
		axios.get(`${api}/${versao}/api/pedidos/admin?offset=${atual}&limit=${limit}&loja=${loja}&sortType=decrescente&filters=${_filtered}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_PEDIDOS, payload: response.data })
		})
		.catch(errorHandling);
	}
}

export const getPedidosPesquisa = (termo, atual, limit, loja, filters = [], cb = null) => {
	const _filtered = filters ? filters.join('+') : '';
	return function(dispatch){
		axios.get(`${api}/${versao}/api/pedidos/search/${termo}?offset=${atual}&limit=${limit}&loja=${loja}&sortType=decrescente&filters=${_filtered}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_PEDIDOS, payload: response.data });
			cb && cb();
		})
		.catch(errorHandling);
	}
}

export const getPedido = (id, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/pedidos/admin/${id}?loja=${loja}`, getHeaders())
		.then(response => dispatch({ type: GET_PEDIDO, payload: response.data }))
		.catch(errorHandling);
	}
}

export const cancelarPedido = (id, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/pedidos/admin/cancel/${id}?loja=${loja}`, { cancelado: true }, getHeaders())
		.then(response => {
			dispatch({ type: CANCELAR_PEDIDO, payload: response.data })
			cb(null);
		})
		.catch(e => cb(errorHandling(e)));
	}
}

export const updateSeen = (id, loja, status, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/pedidos/admin/${id}?loja=${loja}`, { status }, getHeaders())
		.then(response => {
			cb()
		})
		.catch(e => console.log(e));
	}
}

export const limparPedido = () => ({ type: LIMPAR_PEDIDO });

export const limparPedidos = () => ({ type: LIMPAR_PEDIDOS });

export const setNovoStatusPagamento = ({ status, justifyText }, id, idPedido, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/pagamentos/${id}?loja=${loja}&pedido=${idPedido}`, { status, justifyText }, getHeaders())
		.then(response => {
			dispatch(getPedido(idPedido, loja))
			cb(null);
		})
		.catch(e => cb(errorHandling(e)));
	}
}

export const setNovoStatusEntrega = ({ status, justifyText }, id, idPedido, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/entregas/${id}?loja=${loja}&pedido=${idPedido}`, { status, justifyText: justifyText }, getHeaders())
		.then(response => {
			dispatch(getPedido(idPedido, loja))
			cb(null);
		})
		.catch(e => cb(errorHandling(e)));
	}
}

export const updateEntrega = ({ status, responsavel }, id, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/entregas/admin/${id}?loja=${loja}`, { status, responsavel }, getHeaders())
		.then(response => {
			dispatch({ type: ATUALIZAR_ENTREGA, payload: response.data })
			cb(null);
		})
		.catch(e => cb(errorHandling(e)));
	}
}