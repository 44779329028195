import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from '../../actions/google-applications';

import MerchantCenter from './merchant-center';

function GoogleApplications({ admin, produtos, listProducts, saveProducts, getProductGoogle }) {

  useEffect(() => {
    if(admin && !produtos) listProducts("decrescente", 0, 180, admin?.loja)
  }, [])

  function refreshList() {
    admin && listProducts("decrescente", 0, 180, admin?.loja);
  }

  return (
    <div className="GoogleApplications container-centered">
      <div className="wrapper">
        <h2><i className="fab fa-google"></i> Applicações Google</h2>
        
        <div className="menu">
          <button className="active"><i className="fas fa-tag"></i> Google Merchant Center</button>
        </div>

        <MerchantCenter admin={admin} products={produtos} saveProducts={saveProducts} refreshList={refreshList} getProduct={getProductGoogle} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  admin: state.auth.usuario,
  produtos: state.produto.produtos
});

export default connect(mapStateToProps, actions)(GoogleApplications);