import React, { Component } from 'react';

import Titulo from '../../components/Texto/Titulo';
import Input from '../../components/Inputs/Simples';
import Button from '../../components/Button/Simples';

class RecuperarSenha extends Component {

	state = {
		email: ""
	}

	onChangeInput = (field, ev) => this.setState({ [field]: ev.target.value });

	render(){
		const { email } = this.state;
		return (
			<div className="Recuperar-Senha">
				<Titulo tipo="h1" titulo="Xleg Brazil"/>
				<p>Para recuperar sua senha, digite seu email abaixo.</p>
				<p>Enviaremos um email com o link para a redefinição de senha.</p>
				
				<div>
					<Input type="email" label="E-mail" value={email} onChange={(ev) => this.onChangeInput("email", ev)} />
					<Button type="success" rota="/resetar-senha/1" label="Redefinir Senha" />
				</div>
			</div>
		)
	}
}

export default RecuperarSenha;