import {getHeaders} from './localStorage';
import axios from 'axios';
import {api, versao} from '../config';
import errorHandling from './errorHandling';
import {
    GET_PRODUTOS,
    GET_PRODUTO,
    LIMPAR_PRODUTO
} from './types';

export const getProdutos = (ordem, atual, limit, loja) => {
    return function (dispatch) {
        axios.get(`${api}/${versao}/api/produtos?offset=${atual}&limit=${limit}&loja=${loja}&sortType=${ordem}`, getHeaders())
            .then(response => dispatch({type: GET_PRODUTOS, payload: response.data}))
            .catch(errorHandling);
    }
}

export const getProdutosPesquisa = (termo, ordem, atual, limit, loja) => {
    return function (dispatch) {
        axios.get(`${api}/${versao}/api/produtos/search/${termo}?offset=${atual}&limit=${limit}&loja=${loja}&sortType=${ordem}`, getHeaders())
            .then(response => dispatch({type: GET_PRODUTOS, payload: response.data}))
            .catch(errorHandling);
    }
}

export const salvarProduto = (produto, loja, cb) => {
    const _amountPrice = String(produto.preco).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPrice = Number(`${_amountPrice.slice(-11, -2)}.${_amountPrice.slice(-2)}`);

    const _amountPricePromotion = String(produto.promocao).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    let formattedPromotion = Number(`${_amountPricePromotion.slice(-11, -2)}.${_amountPricePromotion.slice(-2)}`);

    if (isNaN(formattedPromotion)) {
        formattedPromotion = 0;
    }
    return function (dispatch) {
        axios.post(`${api}/${versao}/api/produtos?loja=${loja}`, {
            titulo: produto.nome,
            quantidade: produto.quantidade,
            descricao: produto.descricao,
            categoria: produto.categoria,
            preco: formattedPrice,
            promocao: formattedPromotion
        }, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null);
            })
            .catch((e) => cb(errorHandling(e)));
    }
}

export const getProduto = (id, loja, cb) => {
    return function (dispatch) {
        axios.get(`${api}/${versao}/api/produtos/${id}?loja=${loja}`, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb && cb(response.data);
            })
            .catch(errorHandling);
    }
}

export const limparProduto = () => ({type: LIMPAR_PRODUTO});

export const updateProduto = (produto, id, loja, cb) => {

    if (String(produto.preco).slice(0, 2) !== "R$") produto.preco = produto.preco.toFixed(2);
    const _amountPrice = String(produto.preco).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPrice = Number(`${_amountPrice.slice(-11, -2)}.${_amountPrice.slice(-2)}`).toFixed(2);

    if (String(produto.promocao).slice(0, 2) !== "R$") produto.promocao = produto.promocao.toFixed(2);
    const _amountPricePromotion = String(produto.promocao).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPromotion = Number(`${_amountPricePromotion.slice(-11, -2)}.${_amountPricePromotion.slice(-2)}`).toFixed(2);

    return function (dispatch) {
        axios.put(`${api}/${versao}/api/produtos/${id}?loja=${loja}`, {
            titulo: produto.nome,
            descricao: produto.descricao,
            disponibilidade: produto.disponibilidade === "disponivel" ? "true" : "false",
            destaque: produto.destaque,
            quantidade: produto.quantidade,
            categoria: produto.categoria,
            preco: formattedPrice,
            promocao: formattedPromotion
        }, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null);
            })
            .catch(e => cb(errorHandling(e)));
    }
}

export const removeProdutoImagens = (fotos, removeImageFile, id, loja, cb) => {
    return function (dispatch) {
        axios.put(`${api}/${versao}/api/produtos/${id}?loja=${loja}`, {fotos, removeImageFile}, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null);
            })
            .catch(e => cb(errorHandling(e)));
    }
}

export const updateProdutoImagens = (data, id, loja, cb) => {
    return function (dispatch) {
        axios.put(`${api}/${versao}/api/produtos/images/${id}?loja=${loja}`, data, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null);
            })
            .catch(e => cb(errorHandling(e)));
    }
}

export const updateCategories = (categories, id, loja, cb) => {
    return function (dispatch) {
        axios.put(`${api}/${versao}/api/produtos/${id}/categories?loja=${loja}`, {categories}, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null)
                return;
            })
            .catch(e => cb(e));
    }
}

export const deleteProduct = (id, loja, cb) => {
    return function (dispatch) {
        axios.delete(`${api}/${versao}/api/produtos/${id}?loja=${loja}`, getHeaders())
            .then(response => {
                dispatch({type: GET_PRODUTO, payload: response.data});
                cb(null)
                return;
            })
            .catch(e => cb(e));
    }
}