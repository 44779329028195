import { useState, useEffect } from "react";
import InputAutoWidth from "../../../components/Inputs/AutoWidth"
import { notify } from "../../../utils/notification";

export default function EditAudience({ audience, clientes, active, closeModal, refreshList, saveAudience, setNotification, removeAudience }) {

  const [title, setTitle] = useState(audience.title || '')

  const [selectedClients, setSelectedClients] = useState(audience.leads)
  const [audienceClientsType, setAudienceClientsType] = useState(audience.leadsType)

  const [deleting, setDeleting] = useState(false)

  function onChangeManual(client) {
    const verifyClient = selectedClients.filter(fiClient => fiClient._id === client._id).length;

    var newArray = [...selectedClients];
    if(verifyClient === 0) {
      newArray.push(client)
    } else {
      newArray = newArray.filter(fiClient => fiClient._id !== client._id)
    }

    setSelectedClients(newArray)

    return;
  }

  function handleUpdate() {
    saveAudience({ _id: audience._id, title, leads: audienceClientsType === "manual" ? selectedClients : [], leadsType: audienceClientsType }, audience.loja, (response) => {
      refreshList();
      return notify("Audiência atualizada com sucesso!", setNotification);
    })
    return closeModal();
  }

  useEffect(() => {
    setSelectedClients(audience.leads)
    setTitle(audience.title)
    setAudienceClientsType(audience.leadsType)
  }, [audience])

  async function handleRemove() {
    closeModal()
    await removeAudience(audience._id, audience.loja, (response) => {
      refreshList();
      return notify("Audiência removida com sucesso!", setNotification);
    })
    return setDeleting(false);
  }

  return (
    <div className={`modalAudience${active ? ' active' : ''}`}>
      <div className={`content${deleting ? ' min' : ''}`}>
        { !deleting ? (
          <>
            <div className="close" onClick={() => closeModal()}>
              <i className="fas fa-times"></i>
            </div>

            <InputAutoWidth initialTitle={title} buttonTitle="Ok" callback={(_title) => setTitle(_title)} />

            <div className="list-items">
              <p>Selecione os <span>clientes que participarão</span> desta lista:</p>
              { audienceClientsType && (
                <>
                  <div className="item" onClick={() => setAudienceClientsType("leads")}>
                    <div className={`input-radio${audienceClientsType === "leads" ? ' active' : ''}`}></div>
                    <label>Leads que ainda não compraram</label>
                  </div>
                  <div className="item" onClick={() => setAudienceClientsType("allclients")}>
                    <div className={`input-radio${audienceClientsType === "allclients" ? ' active' : ''}`}></div>
                    <label>Todos os clientes da loja</label>
                  </div>
                  <div className="item" onClick={() => setAudienceClientsType("manual")}>
                    <div className={`input-radio${audienceClientsType === "manual" ? ' active' : ''}`}></div>
                    <label>Selecionar manualmente</label>
                  </div>
                </>
              )}
            </div>

            { (audienceClientsType === "manual") && (
              <div className="selectLeads">
                { clientes && clientes.map(client => {
                  const clientSelected = selectedClients.filter(fiItem => fiItem._id === client._id)[0];

                  return (
                    <div className="item" key={client._id}>
                      <input type="checkbox" id={client._id} defaultChecked={clientSelected && (clientSelected._id === client._id)} onChange={() => onChangeManual(client)} />
                      <label htmlFor={client._id}>{client.name} - {client.email}</label>
                    </div>
                  )
                }) }
              </div>
            )}

            <div className="actions-edit">
              <button className="remove" onClick={() => setDeleting(true)}>Remover Audiência</button>
              <button className="update" onClick={handleUpdate}>Atualizar</button>
            </div>
          </>
        ) : (
          <div className="deleting">
            <h2>Deseja realmente remover a audiência: <br/><span>{audience.title} ?</span></h2>

            <div className="actions">
              <button className="cancel" onClick={() => setDeleting(false)}>Cancelar</button>
              <button className="accept" onClick={handleRemove}>Confirmar</button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}