import React, { Component } from 'react';

import Logo from '../../../images/logo.png';

import ReactToPrint from "react-to-print";

class ComponentToPrint extends React.Component {
  render() {
    const { dataDelivery } = this.props;
		if(!dataDelivery) return null;

    return (
      <div className="cardDelivery">
        <div className="cardDelivery_header">
          <div className="spanFromTo">
            <span>De</span>
            <h6>{dataDelivery.shippingSender}</h6>
          </div>
          <div className="spanFromTo">
            <span>Para</span>
            <h6>{dataDelivery.shippingRecipient}</h6>
          </div>
        </div>
        <h5 className={`cardMessage ${
            (dataDelivery.shippingMessage.length > 120 && dataDelivery.shippingMessage.length < 300) ? 'small' : '' ||
            (dataDelivery.shippingMessage.length > 300) ? 'smaller' : ''
          }`}>{dataDelivery.shippingMessage}</h5>
        <img className="logo" src={Logo} alt="Julia Flores RJ" />
      </div>
    )
  }
}

class Entrega extends Component {
	render(){
    return (
      <div className="cardDelivery_wrapper">
        <ReactToPrint
          trigger={() => <button className="printButton">Imprimir Cartão</button>}
          content={() => this.componentRef}
        />
        <ComponentToPrint dataDelivery={this.props.dataDelivery} ref={el => (this.componentRef = el)} />
      </div>
    )    
	}
}

export default Entrega;