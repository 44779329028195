import React from 'react';
import { api } from '../../config';

function SingleImage({ handleSubmit, image, temp }){
	return (
		<div className="single-image product-image" style={temp ? { backgroundImage: `url("${image}` } : { backgroundImage: `url("${api}/public/images/${image}` }}>
			<label htmlFor="single" className="icon-photo"><i className="fas fa-camera"></i></label>
			<input type="file" id="single" onChange={handleSubmit} /> 
		</div>
	)
}

export default SingleImage;