const Input = ({ register, type, name, placeholder, errors, onChange, value }) => {

  return (
    <div className="Input">
      <input type={type} {...register} name={name} placeholder={placeholder} value={value} onChange={onChange} />
      {errors[name] && errors[name].type === "required" && <span className="danger">Obrigatório *</span>}
    </div>
  )
}

export default Input;