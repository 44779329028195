import { combineReducers } from 'redux';

import authReducer from './auth_reducer';
import pedidoReducer from './pedido_reducer';
import entregaReducer from './entrega_reducer';
import clienteReducer from './cliente_reducer';
import categoriaReducer from './categoria_reducer';
import produtoReducer from './produto_reducer';
import upsellReducer from './upsell_reducer';
import variacaoReducer from './variacao_reducer';
import avaliacaoReducer from './avaliacao_reducer';
import configuracaoReducer from './configuracao_reducer';
import reportsReducer from './reports_reducer';
import emarketingReducer from './emarketing_reducer';

const reducers = combineReducers({
	auth: authReducer,
	pedido: pedidoReducer,
	entrega: entregaReducer,
	cliente: clienteReducer,
	categoria: categoriaReducer,
	produto: produtoReducer,
	upsell: upsellReducer,
	variacao: variacaoReducer,
	avaliacao: avaliacaoReducer,
	configuracao: configuracaoReducer,
	reports: reportsReducer,
	emarketing: emarketingReducer
});

export default reducers;