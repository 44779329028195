import React, { useEffect, useState } from 'react';

const _types = [
  { text: "Aprovados", value: "approved" },
  { text: "Pendentes", value: "pending" },
  // { text: "Cancelados", value: "canceled" },
  { text: "Negados", value: "denied" }
]

const OrderFilter = ({ refreshList, currentAction }) => {

  const [openToggle, setToggle] = useState(false);
  
  const onChangeCheck = (value) => {
    let filters = JSON.parse(window.localStorage.getItem('orderFilters')) || [];
    let newArray = [...filters, value];
    if (filters.includes(value)) {
      newArray = newArray.filter(fiValue => fiValue !== value);
    }
    window.localStorage.setItem('orderFilters', JSON.stringify(newArray))
    currentAction();
    return refreshList();
  }

  let _filters = JSON.parse(window.localStorage.getItem('orderFilters')) || [];
  
  return (
    <div className="Filters">
      <div className={`icon icon-toggle${openToggle ? ' active' : ''}`} onClick={() => setToggle(!openToggle)}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`wrapper-items${openToggle ? ' active' : ''}`}>
        <div className="items">
          { (_types || []).map((item, idx) => (
            <div key={idx} className="option">
              <input type="checkbox" id={item.value} defaultChecked={_filters.filter(fiItem => fiItem === item.value)[0] === item.value ? true : false} name="options" onChange={() => onChangeCheck(item.value)} />
              <label className="text" htmlFor={item.value}>{item.text}</label>
            </div>
          )) }
        </div>
      </div>
    </div>
  )
}

export default OrderFilter;