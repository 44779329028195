import { useEffect, useState } from 'react';
import Titulo from '../../../components/Texto/Titulo';

import { connect } from 'react-redux';
import * as actionsProdutos from '../../../actions/produtos';
import * as actionsUpsells from '../../../actions/upsells';
import { api } from '../../../config';
import { formatMoney } from '../../../actions';

import Loading from '../../../components/Loading';

function UpsellInProduct({ admin, upsells, produto, updateUpsellProduct }) {

	const [upsellsList, setUpsellsList] = useState([]);

	useEffect(() => {
		if(upsellsList.length === produto?.upsell.length || (!produto && upsellsList.length === 0)) return;
		var reducedList = []
		produto?.upsell.forEach(item => {
			reducedList.push(item._id)
		})
		return setUpsellsList(reducedList);
	}, [produto, upsellsList])

	const onChange = async (upsellid, productid) => {
		await updateUpsellProduct(upsellid, productid, admin.loja, (err, success) => {
			if(err) return console.log(err);
			console.log(success)
			if(success.length > 0) return setUpsellsList(success);
			else return setUpsellsList([])
		});
	}

	if(!produto || !upsells || !upsellsList) return (<Loading />);

	return produto && (
		<div className="card" style={{ marginTop: '40px' }}>
			<Titulo tipo="h3" titulo="Upsell" />
			
			<div className="TableBoxes">
				{ upsells?.docs.map((item, idx) => {
						let isActive = upsellsList.filter(fiUpsell => fiUpsell === item._id)[0];
						return (
							<div key={idx} className={`item${isActive ? ' active' : '' }${ !item.disponibilidade ? ' disable' : '' }`} style={{ backgroundImage: `url("${api}/public/images/${item.fotos[0]}` }}>
								<div className="details">
									<div className="text">
										<span className="title">{item.titulo}</span>
										<span className="price">{formatMoney(item.preco)}</span>
									</div>
									<div className="action" onClick={() => onChange(item._id, produto._id)}>
										{ isActive === item._id && <i className="fas fa-check"></i> }
									</div>
								</div>
							</div>
						)
					})
				}
			</div>			
		</div>
	)
}

const mapStateToProps = state => ({
	admin: state.auth.usuario,
	upsells: state.upsell.upsells,
	produto: state.produto.produto
});

export default connect(mapStateToProps, { ...actionsProdutos, ...actionsUpsells })(UpsellInProduct);