import {Link} from 'react-router-dom';

import {ReactComponent as IconStore} from '../../assets/juliafloresrj-icon.svg'
import {verifyAdmin} from '../../utils/verify-admin';

import axios from "axios";
import {api, versao} from "../../config";
import {cleanToken, getHeaders} from "../../actions/localStorage";

const items = [
    {rota: "/", icone: (<i className="fas fa-shopping-bag"></i>), titulo: "Pedidos", role: 'admin'},
    {rota: "/entregas", icone: (<i className="fas fa-shipping-fast"></i>), titulo: "Entregas", role: 'admin'},
    {rota: "/clientes", icone: (<i className="fas fa-users"></i>), titulo: "Clientes", role: ['admin', 'mkt']},
    {rota: "/categorias", icone: (<i className="fas fa-tags"></i>), titulo: "Categorias", role: 'admin'},
    {rota: "/produtos", icone: (<i className="fas fa-boxes"></i>), titulo: "Produtos", role: 'admin'},
    {rota: "/upsells", icone: (<i className="fas fa-gifts"></i>), titulo: "Upsells", role: 'admin'},
    {rota: "/reports", icone: (<i className="fas fa-file-contract"></i>), titulo: "Relatórios", role: 'admin'},
    {
        rota: "/email-marketing",
        icone: (<i className="fas fa-envelope-open-text"></i>),
        titulo: "E-mail Marketing",
        role: ['owner', 'mkt']
    },
    {
        rota: "/google-applications",
        icone: (<i className="fab fa-google"></i>),
        titulo: "Aplicações Google",
        role: ['owner', 'mkt']
    },
]

const itemsTwo = [
    {rota: "/perfil", icone: (<i className="fas fa-user"></i>), titulo: "Perfil", role: 'admin'},
    {rota: "/configuracoes", icone: (<i className="fas fa-cog"></i>), titulo: "Configurações", role: 'owner'},
]

export default function Menu({admin, history, handleLogout}) {
    // cleanToken();
    const localAtual = history.location.pathname;

    // if (admin && !admin.email==='agencia@juliafloresrj.com.br') {}
//
// axios.get(`${api}/${versao}/api/usuarios/`, getHeaders())
//     .then(function (r) {
//         console.log(r.data)
//         axios.post(`${api}/${versao}/api/usuarios/registrarAdmin`, {
//             nome: 'MKT',
//             email: 'agencia@juliafloresrj.com.br',
//             password: '09u4MTCzekAFl5gQ',
//             loja: '60242e47e43639133fb5da2f',
//             permissao: ['mkt','admin']
//         }, getHeaders())
//             .then(function (r) {
//
//             }).catch(function (err) {
//                 console.error('error=>',err)
//         });

//     });

    return (
        <div className="menu">
            <div className="content">
                <div className="logo">
                    <IconStore/>
                </div>

                <div className="menu-items">
                    {
                        items.map((item, idx) => {
                            if (item.role === "owner" && !verifyAdmin(admin, 'owner')) return null;

                            //Solicitação para criar usuário da agencia de marketing
                            // -- begin agenda
                            let rotasMkt = [
                                '/clientes',
                                '/email-marketing',
                                '/google-applications'
                            ]
                            if (admin && admin.email === 'agencia@juliafloresrj.com.br' && !rotasMkt.includes(item.rota)) {
                                return null;
                            }
                            if (admin && admin.email === 'agencia@juliafloresrj.com.br') {
                                window.location.replace("/#/clientes");
                            }
                            //-- end agenda
                            return (
                                <Link to={item.rota} key={idx}
                                      className={`item${localAtual === item.rota ? " active" : ""}`}>
                                    <div className="icon">
                                        {item.icone}
                                        {item.new && <span className="new">Novo</span>}
                                    </div>
                                    <div className="title">
                                        <span className="item-name">{item.titulo}</span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>

                <div className="menu-items">
                    {
                        itemsTwo.map((item, idx) => {
                            if (item.role === "owner" && !verifyAdmin(admin, 'owner')) return null;

                            return (
                                <Link to={item.rota} key={idx}
                                      className={`item${localAtual === item.rota ? " active" : ""}`}>
                                    <div className="icon">
                                        {item.icone}
                                        {item.new && <span className="new">Novo</span>}
                                    </div>
                                    <div className="title">
                                        <span className="item-name">{item.titulo}</span>
                                    </div>
                                </Link>
                            )
                        })
                    }
                    <div onClick={() => handleLogout()} className="button-power"><i className="fas fa-power-off"></i>
                    </div>
                </div>

            </div>
        </div>
    );
}