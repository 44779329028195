import React from 'react';
import { Link } from 'react-router-dom';
import { FaCreditCard } from 'react-icons/fa';

const Table = ({ cabecalho, dados, refreshState, refreshList, gridClass, currentPage }) => (
	<div className={`Table${gridClass ? ` ${gridClass}` : ''}${refreshState ? ' refreshing' : ''}`}>
    
    <div className="head">
      { cabecalho.map((item, idx) => (item !== "Seen" && <div key={idx}>{item}</div> )) }
      { gridClass === "Orders" && <div>Situação</div>}
      { refreshList && (
        <button className="refreshButton" onClick={refreshList}>
          <i className="fas fa-redo"></i>
        </button>
      ) }
    </div>
    <div className="body">
      { dados.map((linha, idx) => (
          <Link to={{ pathname: linha["Ações"], state: { currentPage, url: "/" } }} key={idx} className={`item${linha["Seen"] === 1 && !linha["manual"] ? ' novo' : ''}${linha["manual"] ? ' manual' : ''}${linha["Situação"] === "Cancelado" ? ' canceled' : linha["Situação"] === "Pagamento Aprovado" ? ' accept' : ''}`}>
            { cabecalho.map((item, index) => item !== "Seen" && item !== "Valor" && <span key={index}>{linha[item] || ""}</span>) }
            { linha["Valor"] && (
              <span className={`paymentInfo ${linha["Situação"] === "Cancelado" ? "cancelled" : ""}`}>
                { linha["paymentMethod"] && (
                  <span className={`paymentMethod ${linha["paymentMethod"]}`}>
                    { linha["paymentMethod"] === "creditCard" && (<FaCreditCard />) }
                    { linha["paymentMethod"] === "PIX" && ("PIX") }
                  </span>
                ) }
                <div className="price">
                  <span>{linha["Valor"]}</span>
                  <span>Liq. {linha["valueCart"]}</span>
                </div>
              </span>
            ) }
            { linha["Situação"] && 
                <div className="status">
                  <span className={`pay${linha["Situação"].split("Pagamento").join("").split(" ").join("").split("PIX").join("Pix")}`}>
                    {linha["Situação"] || ""}
                  </span>
                </div>}
          </Link>
      )) }
    </div>
	</div>
);

export default Table;