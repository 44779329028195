import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as EditIcon } from '../../assets/saturnia-edit.svg';

const ProductTable = ({ cabecalho, dados, refreshState, refreshList, gridClass, currentPage }) => (
	<div className={`Table${gridClass ? ` ${gridClass}` : ''}${refreshState ? ' refreshing' : ''}`}>
    
    <div className="head">
      { cabecalho.map((item, idx) => {
        if(item === "Highlight") return <div></div>;
        return (item !== "Seen" && <div key={idx}>{item}</div> )
      }) }
      { gridClass === "Orders" && <div>Situação</div>}
      { refreshList && (
        <button className="refreshButton" onClick={refreshList}>
          <i className="fas fa-redo"></i>
        </button>
      ) }
    </div>
    <div className="body">
      { dados.map((linha, idx) => (
          <div key={idx} className="item">
            { cabecalho.map((item, index) => item !== "Seen" && item !== "Valor" && <span key={index}>{linha[item] || ""}</span>) }
            <Link className="absoluteEdit" to={{ pathname: linha["Ações"], state: { currentPage, url: "/" } }} >
              <EditIcon />
            </Link>
          </div>
      )) }
    </div>
	</div>
);

export default ProductTable;