export const LOGIN_USER = 'LOGIN_USER',
             LOGOUT_USER = 'LOGOUT_USER',

             GET_PEDIDOS = "GET_PEDIDOS",
             GET_PEDIDO = "GET_PEDIDO",
             LIMPAR_PEDIDO = "LIMPAR_PEDIDO",
             LIMPAR_PEDIDOS = "LIMPAR_PEDIDOS",
             CANCELAR_PEDIDO = "CANCELAR_PEDIDO",

             GET_ENTREGAS = 'GET_ENTREGAS',
             GET_ENTREGA = 'GET_ENTREGA',
             LIMPAR_ENTREGA = 'LIMPAR_ENTREGA',
             ATUALIZAR_ENTREGA = 'ATUALIZAR_ENTREGA',

             GET_CLIENTES = "GET_CLIENTES",
             GET_CLIENTES_ALL = "GET_CLIENTES_ALL",
             GET_CLIENTE = "GET_CLIENTE",
             GET_CLIENTE_PEDIDOS = "GET_CLIENTE_PEDIDOS",
             REMOVE_CLIENTE = "REMOVE_CLIENTE",
             LIMPAR_CLIENTE = "LIMPAR_CLIENTE",

             GET_CATEGORIAS = "GET_CATEGORIAS",
             GET_CATEGORIA = "GET_CATEGORIA",
             LIMPAR_CATEGORIA = "LIMPAR_CATEGORIA",
             REMOVE_CATEGORIA = "REMOVE_CATEGORIA",
             GET_CATEGORIA_PRODUTOS = "GET_CATEGORIA_PRODUTOS",

             GET_PRODUTOS = "GET_PRODUTOS",
             GET_PRODUTO = "GET_PRODUTO",
             LIMPAR_PRODUTO = "LIMPAR_PRODUTO",

             GET_UPSELLS = "GET_UPSELLS",
             GET_UPSELL = "GET_UPSELL",
             GET_UPSELLPRODUTOS = "GET_UPSELLPRODUTOS",
             LIMPAR_UPSELL = "LIMPAR_UPSELL",
             DELETE_UPSELL = "DELETE_UPSELL",
             LIMPAR_UPSELLS = "LIMPAR_UPSELLS",

             GET_VARIACOES = 'GET_VARIACOES',
             GET_VARIACAO = 'GET_VARIACAO',
             LIMPAR_VARIACAO = 'LIMPAR_VARIACAO',
             REMOVE_VARIACAO = 'REMOVE_VARIACAO',

             GET_AVALIACOES = 'GET_AVALIACOES',
             GET_AVALIACAO = 'GET_AVALIACAO',
             LIMPAR_AVALIACAO = 'LIMPAR_AVALIACAO',
             REMOVE_AVALIACAO = 'REMOVE_AVALIACAO',

             GET_CONFIGURACOES = "GET_CONFIGURACOES",
             LIMPAR_CONFIGURACOES = 'LIMPAR_CONFIGURACOES',

             REPORTS_SHIPPING = 'REPORTS_SHIPPING',
             NEW_ORDER = 'NEW_ORDER',

             GET_REPORT_PAYMENT = 'GET_REPORT_PAYMENT',
             GET_REPORT_RE_OFFER_ACTION = 'GET_REPORT_RE_OFFER_ACTION',
             GET_REPORT_PRODUCTS = 'GET_REPORT_PRODUCTS',
             CLEAR_REPORTS = 'CLEAR_REPORTS',

             GET_TEMPLATES = 'GET_TEMPLATES',
             GET_CLIENTS_AUDIENCE = 'GET_CLIENTS_AUDIENCE',
             GET_LEADS_AUDIENCE = 'GET_LEADS_AUDIENCE',
             GET_AUDIENCES = 'GET_AUDIENCES',
             GET_CAMPAIGNS = 'GET_CAMPAIGNS';