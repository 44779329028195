import React, { Component } from 'react';
import moment from 'moment';

import Loading from '../../components/Loading';

import { connect } from 'react-redux';
import * as actions from '../../actions/pedidos';
import { formatMoney } from '../../actions';

import Titulo from '../../components/Texto/Titulo';
import Pesquisa from '../../components/Inputs/Pesquisa';
import Filters from '../../components/Filters/Orders';
import OrdersTable from '../../components/Table/orders';
import Paginacao from '../../components/Paginacao/Simples';

import Modal from '../../components/Modal/Simple';
import { Link } from 'react-router-dom';
import {api} from "../../config";

class Pedidos extends Component {

	state = { pesquisa: "", atual: 0, limit: 20, refreshing: false, pendingView: false }

	getPedidos(){
		const { atual, limit, pesquisa } = this.state;
		const { usuario } = this.props;
		
		if(!usuario) return null;
		if(pesquisa) this.props.getPedidosPesquisa(pesquisa, atual, limit, usuario.loja, JSON.parse(window.localStorage.getItem('orderFilters')));
		else this.props.getPedidos(atual, limit, usuario.loja, JSON.parse(window.localStorage.getItem('orderFilters')));
	}
	
	componentDidMount(){
		// if(this.props.history.location.state && this.props.history.location.state.currentPage) console.log({ atual: this.props.history });
		this.getPedidos();
		if(localStorage.getItem("pendingView")){
			this.setState({ pendingView: true });
		}
		window.addEventListener("mousedown", ()=>{
			clearInterval(blinkInterval);
			document.title = 'Dashboard'; // Set the original title
		});
		let blinkInterval;


//		if (!document.createEvent('TouchEvent')) {
//			this.enableNotifications();
//			}
		const fetchRecordCount = () => {
			fetch(api+'/record-count')
				.then((response) => response.json())
				.then((data) => {
					// Process the last modified date
					const lastModified = data.lastModified;
					// Do something with the last modified date
					// Compare the last modified date with the previous value stored in localStorage
					const previousLastModified = localStorage.getItem('lastModified');
					if (lastModified !== previousLastModified) {
						this.createNotification();
						// Toggle the title and apply blinking effect as desired
						// if (!document.hasFocus()) {//only on not focus
						// 	let blinkInterval;
							const toggleTitle = () => {
								document.title = (document.title === 'Novo Pedido!') ? ' ' : 'Novo Pedido!';
							};
							blinkInterval = setInterval(toggleTitle, 500);

							// Stop blinking after 5 min
							setTimeout(() => {
								clearInterval(blinkInterval);
								document.title = 'Dashboard'; // Set the original title
							}, 300000);
						// }
					}

					// Store the current last modified date in localStorage for future comparisons
					localStorage.setItem('lastModified', lastModified);
				})
				.catch((error) => {
					// Handle errors
					console.error('Error fetching last modified date:', error);
				});
		};

// Call fetchRecordCount initially
// 		fetchRecordCount();

// Set up interval to call fetchRecordCount every 30 seconds
		setInterval(fetchRecordCount, 30000);
	}

	enableNotifications(){
		let self = this;
		if (Notification.permission === "granted") {
			this.createNotification();
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission().then(function (permission) {
				if (permission === "granted") {
					self.createNotification();
				}
			});
		}
	}

	createNotification() {
		const notification = new Notification("Novo pedido!", {
			body: "Novo pedido recebido!",
			icon: "https://admin.juliafloresrj.com.br/favicons/favicon.ico", // Replace with your custom icon URL
		});

		// Optional: Add event listener to handle clicks on the notification
		notification.onclick = function () {
			// Define what should happen when the user clicks the notification
			// e.g., redirect to a specific page or perform an action
		};

		// Optional: Add event listener to handle closing the notification
		notification.onclose = function () {
			// Define what should happen when the notification is closed
		};

		// Make the browser tab blink (Note: This part might not work in all browsers)
		// this.blinkTab();
	}

	// blinkTab() {
	// 	var title = document.title;
	// 	var isPageActive = true;
	//
	// 	var blinkInterval = setInterval(function () {
	// 		if (!isPageActive) {
	// 			document.title = "New Notification!";
	// 		} else {
	// 			document.title = title;
	// 			clearInterval(blinkInterval);
	// 		}
	// 		isPageActive = !isPageActive;
	// 	}, 1000);
	// }

	//blink end

	handleSubmitPesquisa(){
		const { atual, limit, pesquisa } = this.state;
		const { usuario } = this.props;
		
		if(pesquisa?.length > 0) {	
			this.setState({ refreshing: true })
			this.setState({ atual: 0 }, () => {
				if(!usuario) return null;
				this.props.getPedidosPesquisa(pesquisa?.split(' ').join('').toLowerCase().replace(/[^\w\s]/gi, ''), atual, limit, usuario.loja, JSON.parse(window.localStorage.getItem('orderFilters')), () => this.setState({ refreshing: false }));
			});
		}
	}

	onChangePesquisa = (ev) => this.setState({ pesquisa: ev.target.value });

	changeNumberCurrent = (newNumber) => {
		this.setState({ atual: newNumber }, () => {
			this.getPedidos();
		});
	}

	refreshList = () => {
		this.setState({ refreshing: true })
		this.props.limparPedidos();
		this.getPedidos();
		
		const timer = setTimeout(() => {
			this.setState({ refreshing: false })
			clearTimeout(timer)
		}, 3000);
	}

	cancelSeen = () => {
		localStorage.removeItem("pendingView")
		this.setState({ pendingView: false })
	}

	confirmSeen = async () => {
		const idSeen = localStorage.getItem("pendingView");
		await this.props.seenOrder(idSeen, this.props.usuario.loja, 2)
		localStorage.removeItem("pendingView")
		this.setState({ pendingView: false });
		this.refreshList();
	}

	render(){
		const { pesquisa, refreshing, pendingView } = this.state;
		const { pedidos } = this.props;

		const dados = [];
		(pedidos ? pedidos.docs : []).forEach((item) => {
			if(!item || !item.pagamento) return;
			dados.push({
				"Ações": `/pedido/${item.pagamento.pedido._id}`,
				"Cliente": item.pagamento.pedido.cliente ? item.pagamento.pedido.cliente.nome : "",
				"Data Compra": moment(item.pagamento.pedido.createdAt).format("DD/MM/YYYY"),
				"Data Entrega": item.pagamento.pedido.entrega ? item.pagamento.pedido.entrega.shippingDate : "Não encontrado",
				"Valor": formatMoney(item.pagamento.valor || 0),
				"valueCart": formatMoney(item.pagamento.valueCart ? item.pagamento.valueCart : 0),
				"Situação": item.pagamento.pedido.cancelado ? "Cancelado" : item ? item.pagamento.status : "Não encontrado",
				"Seen": item.pagamento.pedido.seen,
				"manual": item.pagamento.pedido.manualOrder ? item.pagamento.pedido.manualOrder : false,
				"paymentMethod": item.pagamento.forma || "Não encontrado",
				"ID": item.pagamento.pedido.simpleId ? item.pagamento.pedido.simpleId : item.pagamento.pedido._id,
				pedido: item.pagamento.pedido,
				registros: {
					pagamento: (item.registros || []).reduce((all, item) => (item.tipo === "pagamento") ? all.concat([item]) : all, []),
					entrega: (item.registros || []).reduce((all, item) => (item.tipo === "entrega") ? all.concat([item]) : all, [])
				},
				pagamento: {
					parcelas: item.pagamento.parcelas,
					status: item.pagamento.status
				},
				cliente: {
					whatsapp: item.pagamento.pedido.cliente.telefones[0]
				}
			});
		});

		return (
			<>

				{ pendingView && (
					<Modal h1="Deseja marcar este pedido como visualizado?" cancelFunction={() => this.cancelSeen()} confirmFunction={() => this.confirmSeen()} />
				) }

				{ pedidos ? (
					<div className="SectionOrders">
						<div className="Page-Title">
							<Titulo tipo="h1" titulo="Pedidos"/>
						</div>

						<div className="actionsTollBar">
							<Filters refreshList={() => this.refreshList()} currentAction={() => this.setState({ atual: 0 })} />
							<Link to="/neworder">
								<div className="btnIcon plus"></div>
							</Link>
							<Pesquisa valor={pesquisa}
								placeholder={"Pesquise pelo Nome, Telefone, CPF, E-mail ou ID do pedido"}
								onChange={(ev) => this.onChangePesquisa(ev)}
								onClick={() => this.handleSubmitPesquisa()}/>
						</div>

						<OrdersTable admin={this.props.usuario} cabecalho={["ID", "Cliente", "Data Compra", "Data Entrega", "Valor", "Seen" ]} gridClass="Orders" refreshState={refreshing} dados={dados} refreshList={() => this.refreshList()} />

						<Paginacao atual={this.state.atual}
							total={this.props.pedidos?.total || 1} 
							limite={this.state.limit} 
							onClick={(newNumber) => this.changeNumberCurrent(newNumber)}/>
					</div>
				) : <Loading /> }
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	pedidos: state.pedido.pedidos,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Pedidos);
