import { useState, useRef, useEffect } from "react";

export default function InputAutoWidth({ initialTitle, buttonTitle, callback }) {
  
  const [title, setTitle] = useState(initialTitle)
  const [titleWidth, setTitleWidth] = useState(100);
  const [titleChanging, setTitleChanging] = useState(false);

  const titleSpan = useRef();
  const inputTitle = useRef();

  useEffect(() => {
    setTitle(initialTitle)
  }, [initialTitle])

  return (
    <div className="InputAutoWidth">
      <span className={!titleChanging && "visible"} ref={titleSpan} onClick={() => { setTitleChanging(true); inputTitle.current.focus() } }>{title} <i className="fas fa-pencil-alt"></i></span>
      <div className="input-edit">
        <input ref={inputTitle} className={titleChanging && "visible"} maxLength={26} type="text" style={{ width: titleWidth }} autoFocus spellCheck="false" value={title} onChange={(e) => { setTitle(e.target.value); setTitleWidth(titleSpan.current.offsetWidth); }} />
        { titleChanging && <button onClick={() => { setTitleChanging(false); callback(title) }}>{buttonTitle}</button> }
      </div>
    </div>
  )
}