import React, { Component } from 'react';

import Loading from '../../components/Loading';

import { connect } from 'react-redux';
import * as actions from '../../actions/entregas';

import Titulo from '../../components/Texto/Titulo';
import Pesquisa from '../../components/Inputs/Pesquisa';
import DynamicTable from '../../components/Table/DynamicTable';
import Paginacao from '../../components/Paginacao/Simples';


class Entregas extends Component {

	state = { pesquisa: "", atual: 0, limit: 30, refreshing: false }

	getEntregas(){
		const { atual, limit, pesquisa } = this.state;
		const { usuario } = this.props;

		if(!usuario) return null;
		const loja = usuario.loja;

		if(pesquisa) this.props.getPedidosPesquisa(pesquisa, atual, limit, loja);
		else this.props.getEntregas(atual, limit, loja);
	}

	componentDidMount(){
		this.getEntregas();
	}

	componentDidUpdate(prevProps){
		if(!prevProps.usuario && this.props.usuario) this.getEntregas();
	}

	handleSubmitPesquisa(){
		this.setState({ atual: 0 }, () => {
			const { atual, limit, pesquisa } = this.state;
			const { usuario } = this.props;
			if(!usuario) return null;
			const loja = usuario.loja;
			this.props.getPedidosPesquisa(pesquisa, atual, limit, loja);
		});
		
	}

	onChangePesquisa = (ev) => this.setState({ pesquisa: ev.target.value });

	changeNumeroAtual = (atual) => {
		this.setState({ atual }, () => {
			this.getEntregas();
		});
	}

	refreshList = () => {
		const { atual, limit } = this.state;
		this.setState({ refreshing: true })
		this.props.getEntregas(atual, limit, this.props.usuario.loja);

		const timer = setTimeout(() => {
			this.setState({ refreshing: false })
			clearTimeout(timer)
		}, 2000);
	}

	render(){
		const { entregas, usuario } = this.props;

		const dados = [];
		(entregas ? entregas.docs : []).forEach((entrega) => {
			if(!entrega) return null;
			dados.push({
				"ID": entrega.simpleId ? entrega.simpleId : entrega._id,
				"Local": entrega.endereco.bairro,
				"Situação": entrega.status,
				"Data Entrega": entrega.shippingDate,
				"Período": entrega.shippingPeriod,
				"Seen": entrega.pedido.seen,
				entrega
			});
		});

		return (
			<>
				{ entregas ? (
					<div className="SectionDelivery">
		
						<div className="Page-Title">
							<Titulo tipo="h1" titulo="Entregas"/>
						</div>

						{/* <div className="actionsTollBar">
							<Pesquisa valor={this.state.pesquisa}
								placeholder={"Pesquise aqui pelo nome do cliente..."}
								onChange={(ev) => this.onChangePesquisa(ev)}
								onClick={() => this.handleSubmitPesquisa()}/>
						</div> */}
		
						<DynamicTable admin={usuario} cabecalho={["ID","Local","Data Entrega","Período","Situação","Seen"]} refreshState={this.state.refreshing} dados={dados} refreshList={() => this.refreshList()} />
						
						<Paginacao atual={this.state.atual}
							total={entregas ? entregas.total : 0} 
							limite={this.state.limit} 
							onClick={(numeroAtual) => this.changeNumeroAtual(numeroAtual)}/>
					</div>
				) : (<Loading />)}
			</>
		);
	}
}

const mapStateToProps = state => ({
	entregas: state.entrega.entregas,
	reports: state.entrega.reports,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Entregas);