import { useEffect, useState } from 'react';
import Voltar from '../../components/Links/Voltar';
import InputSimples from '../../components/Inputs/Simples';
import { TextoDados } from '../../components/Texto/Dados';
import InputSelect from '../../components/Inputs/Select';

import { connect } from 'react-redux';
import * as actions from '../../actions/categorias';
import Loading from '../../components/Loading';

function Details({ history, category, update, notify, setNotification }) {

	const [categoryState, setCategoryState] = useState({});

	useEffect(() => {
		if(!category) return;
		setCategoryState({ name: category.nome, ordem: category.ordem, available: category.disponibilidade ? 'disponivel' : 'indisponivel' })
	}, [category])

	function handleUpdate(){
		update(categoryState, category._id, category.loja, (err, success) => {
			if(err) return notify(err.message, setNotification, "error");
			
			notify("Categoria atualizada com sucesso!", setNotification)
		})
	}

	return !category ? <Loading /> : (
		<div className="details">
			<div className="head">
        <Voltar history={history} />
				<h1>{categoryState.name}</h1>
      </div>

			<div className="body">
				<InputSimples type="text" value={categoryState.name} label="Nome" onChange={(e) => setCategoryState({ ...categoryState, name: e.target.value })} />
				<InputSimples type="number" value={categoryState.ordem} label="Ordem" onChange={(e) => setCategoryState({ ...categoryState, ordem: e.target.value })} />

				<TextoDados chave="Disponíbilidade" valor={(
					<InputSelect nome="disponibilidade" value={categoryState.available} opcoes={[
						{ label: "Disponível", value: "disponivel" },
						{ label: "Indisponível", value: "indisponivel" }
					]} onChange={(e) => setCategoryState({ ...categoryState, available: e.target.value })} />
				)} />
			
				<div className="ButtonSubmit">
					<button onClick={handleUpdate}>Atualizar dados</button>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = state => ({
	categoria: state.categoria.categoria,
	usuario: state.auth.usuario	
});

export default connect(mapStateToProps, actions)(Details);