import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import { TextoDados } from '../../components/Texto/Dados';
import InputValor from '../../components/Inputs/Valor';
import ListaDinamicaSimples from '../../components/Lista/DinamicaSimples';

import AlertGeral from '../../components/Alert/Geral';
import { connect } from 'react-redux';
import * as actions from '../../actions/configuracoes';

var timer;

class ConfigStore extends Component {

	generateStateConfiguracao = (props) => ({
		nome: props.loja ? props.loja.nome : "",
		CNPJ: props.loja ? props.loja.cnpj : "",
		email: props.loja ? props.loja.email : "",
		storeStarsProductMessage: props.loja ? props.loja.storeStarsProductMessage : "",
		endereco: props.loja ? (props.loja.endereco ? props.loja.endereco.local : "") : "",
		numero: props.loja ? (props.loja.endereco ? props.loja.endereco.numero : "") : "",
		bairro: props.loja ? (props.loja.endereco ? props.loja.endereco.bairro : "") : "",
		cidade: props.loja ? (props.loja.endereco ? props.loja.endereco.cidade : "") : "",
		estado: props.loja ? (props.loja.endereco ? props.loja.endereco.estado : "") : "",
		cep: props.loja ? (props.loja.endereco ? props.loja.endereco.CEP : "") : "",
		pais: props.loja ? (props.loja.endereco ? props.loja.endereco.pais : "") : "",

		telefones: props.loja ? props.loja.telefones : [],

		language: props.loja ? props.loja.language : ""
	});

	state = {
		...this.generateStateConfiguracao(this.props),
		aviso: null,
		erros: {}
	}

	getConfiguracao(props){
		const { usuario } = props;
		if(!usuario) return null;
		this.props.getConfiguracao(usuario.loja);
	}

	componentDidMount(){
		this.getConfiguracao(this.props);
	}
	componentDidUpdate(prevProps){
		if(!prevProps.usuario && this.props.usuario) this.getConfiguracao(this.props);
		if(!prevProps.loja && this.props.loja) this.setState(this.generateStateConfiguracao(this.props));
	}

	validate(){
		const { nome, CNPJ, email, storeStarsProductMessage, endereco, numero, bairro, cidade, estado, cep, pais } = this.state;
		const erros = {};

		if(!nome) erros.nome = "Preencha aqui com o nome da loja";
		if(!CNPJ) erros.CNPJ = "Preencha aqui com o CNPJ da loja";
		if(!email) erros.email = "Preencha aqui com o email da loja";
		if(!storeStarsProductMessage) erros.storeStarsProductMessage = "Preencha aqui a mensagem";
		if(!endereco) erros.endereco = "Preencha aqui com o endereco da loja";
		if(!numero) erros.numero = "Preencha aqui com o numero da loja";
		if(!bairro) erros.bairro = "Preencha aqui com o bairro da loja";
		if(!cidade) erros.cidade = "Preencha aqui com a cidade da loja";
		if(!estado) erros.estado = "Preencha aqui com o estado da loja";
		if(!cep) erros.cep = "Preencha aqui com o cep da loja";
		if(!pais) erros.pais = "Preencha aqui com o pais da loja";

		this.setState({ erros });
		return !(Object.keys(erros).length > 0);
	}

	updateConfigs = () => {
		const { usuario } = this.props;
		if(!usuario || !this.validate()) return null;

		clearTimeout(timer)
		
		timer = setTimeout(() => {
			this.props.updateConfiguracao(this.state, usuario.loja, (error) => {
				this.setState({
					aviso: {
						status: !error,
						msg: error ? error.message : "Configurações atualizada com sucesso!"
					}
				});
				error && console.log(error)
			});
			clearTimeout(timer)
		}, 500)

		return
	}

	handleSubmit = (field, value) => {

		this.setState({ [field]: value }, () => this.validate());
		this.updateConfigs()
	}

	renderDadosConfiguracao(){
		const { nome, CNPJ, email, storeStarsProductMessage, telefones, erros } = this.state;
		return (
			<div className="item-section">
				<div className="item-double">
					<div>
						<TextoDados chave="Loja" valor={
							<InputValor noStyle value={nome} name="nome" erro={erros.nome} handleSubmit={(valor) => this.handleSubmit( "nome", valor )} />
						} />
						<TextoDados chave="CNPJ" valor={
							<InputValor noStyle value={CNPJ} name="CNPJ" erro={erros.CNPJ} handleSubmit={(valor) => this.handleSubmit( "CNPJ", valor )} />
						} />
						<TextoDados chave="E-mail" valor={
							<InputValor noStyle value={email} name="email" erro={erros.email} handleSubmit={(valor) => this.handleSubmit( "email", valor )} />
						} />
						<TextoDados chave="Mensagem Produtos Home" valor={
						<InputValor noStyle value={storeStarsProductMessage} name="storeStarsProductMessage" erro={erros.storeStarsProductMessage} handleSubmit={(valor) => this.handleSubmit( "storeStarsProductMessage", valor )} />
					} />
					</div>
					<div className="telephones">
						<label>Telefones</label>
						<ListaDinamicaSimples dados={telefones} onAdd={this.onAdd} onRemove={this.onRemove} />
					</div>
				</div>
			</div>
		)
	}

	renderDadosEndereco(){
		const { endereco, numero, bairro, cidade, estado, cep, pais, erros } = this.state;
		return (
			<div className="item-section">
				<div className="item-double">
					<TextoDados chave="Endereco" valor={
						<InputValor noStyle value={endereco} name="endereco" erro={erros.endereco} handleSubmit={(valor) => this.handleSubmit( "endereco", valor )} />
					} />
					<TextoDados chave="Número" valor={
						<InputValor noStyle value={numero} name="numero" erro={erros.numero} handleSubmit={(valor) => this.handleSubmit( "numero", valor )} />
					} />
				</div>
				<div className="item-double">
					<TextoDados chave="Bairro" valor={
						<InputValor noStyle value={bairro} name="bairro" erro={erros.bairro} handleSubmit={(valor) => this.handleSubmit( "bairro", valor )} />
					} />
					<TextoDados chave="Cidade" valor={
						<InputValor noStyle value={cidade} name="cidade" erro={erros.cidade} handleSubmit={(valor) => this.handleSubmit( "cidade", valor )} />
					} />
				</div>
				<div className="item-triple">
					<TextoDados chave="Estado" valor={
						<InputValor noStyle value={estado} name="estado" erro={erros.estado} handleSubmit={(valor) => this.handleSubmit( "estado", valor )} />
					} />
					<TextoDados chave="CEP" valor={
						<InputValor noStyle value={cep} name="cep" erro={erros.cep} handleSubmit={(valor) => this.handleSubmit( "cep", valor )} />
					} />
					<TextoDados chave="Pais" valor={
						<InputValor noStyle value={pais} name="pais" erro={erros.pais} handleSubmit={(valor) => this.handleSubmit( "pais", valor )} />
					} />
				</div>
			</div>
		)
	}

	onAdd = (valor) => {
		if(!valor) return;
		const { telefones } = this.state;
		this.setState({ telefones: [...telefones, valor] });
		this.updateConfigs()
	}

	onRemove = (idx) => {
		if(idx === undefined) return;
		const { telefones } = this.state;
		this.setState({ telefones: telefones.filter((item, index) => index !== idx ) });
		this.updateConfigs()
	}

	render(){
		return (
			<div className="config-general">
				<AlertGeral aviso={this.state.aviso} />
				{ this.renderDadosConfiguracao() }
				{ this.renderDadosEndereco() }
			</div>
		)
	}
}

const mapStateToProps = state => ({
	loja: state.configuracao.loja,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(ConfigStore);