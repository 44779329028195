import moment from "moment"
import { useEffect, useState } from "react"
import { Notification } from "../../../utils/notification"
import EditAudience from "./edit"
import NewAudience from "./new"
import Loading from '../../../components/Loading'

export default function Audiences({ admin, audiences, refreshList, clientes, leads, listClientsAudience, listLeadsAudience, saveAudience, removeAudience }) {

  const [modalNew, setModalNew] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)

  const [notification, setNotification] = useState({ active: false, text: "" });
  const [audienceCurrent, setAudienceCurrent] = useState({});

  useEffect(() => {
    !clientes && listClientsAudience(admin.loja, (_clientes) => _clientes)
  })

  function handleEditAudience(audience) {
    setAudienceCurrent(audience)
    setModalEdit(true)
  }

  return (!audiences || !clientes) ? <Loading /> : (
    <div className="audiences">
      <Notification notification={notification} />
      <div className="section-actions">
        <button onClick={() => setModalNew(true)}><i className="fas fa-plus"></i> Nova Audiência</button>

        <NewAudience loja={admin.loja} clientes={clientes} active={modalNew} closeModal={() => setModalNew(false)} refreshList={refreshList} saveAudience={saveAudience} setNotification={setNotification} />
        
        <EditAudience audience={audienceCurrent} clientes={clientes} active={modalEdit} closeModal={() => setModalEdit(false)} refreshList={refreshList} saveAudience={saveAudience} setNotification={setNotification} removeAudience={removeAudience} />
      </div>
      
      { audiences && audiences.map((item, index) => (
        <div className="audience" key={index}>
          <div className="information">
            <h1>{item.title}</h1>
            { item.createdAt === item.updatedAt ? (
              <p><span className="status createdAt">Adicionada em</span> {moment(item.createdAt).format("DD/MM/YYYY")} às {moment(item.createdAt).format("HH:mm")}</p>
            ) : (
              <p><span className="status updatedAt">Atualizada em</span> {moment(item.updatedAt).format("DD/MM/YYYY")} às {moment(item.updatedAt).format("HH:mm")}</p>
            )}
          </div>
          <div style={{ display: 'flex'}}>
            <div className="audience-info">
              <span className="leads-number">
                <i className="fas fa-users"></i> {item.leadsType === "manual" ? item.leads.length : (item.leadsType === "leads" ? leads.length : clientes.length)}
              </span>
              <span className="leads-info">pessoas na lista</span>
            </div>
            <div className="actions">
              <button className="edit-audience" title="Editar Audiência" onClick={() => handleEditAudience(item)}><i className="fas fa-pencil-alt"></i></button>
              {/* <button className="send-campaign" title="Criar nova campanha"><i className="fas fa-paper-plane"></i></button> */}
            </div>
          </div>
        </div>
      )) }
    </div>
  )
}