import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import {
    GET_CONFIGURACOES,
    LIMPAR_CONFIGURACOES
} from './types';

export const getConfiguracao = (loja) => {
    return function(dispatch){
        axios.get(`${api}/${versao}/api/lojas/${loja}`, getHeaders())
        .then(response => dispatch({ type: GET_CONFIGURACOES, payload: response.data }))
        .catch(errorHandling);
    }
}

export const updateConfiguracao = (dados, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/lojas/${loja}?loja=${loja}`, {
            nome: dados.nome,
            cnpj: dados.CNPJ,
            email: dados.email,
            storeStarsProductMessage: dados.storeStarsProductMessage,
            endereco: {
                local: dados.endereco,
                numero: dados.numero,
                bairro: dados.bairro,
                cidade: dados.cidade,
                estado: dados.estado,
                CEP: dados.cep,
                pais: dados.pais
            },
            telefones: dados.telefones,
        }, getHeaders())
        .then(response => {
            dispatch({ type: GET_CONFIGURACOES, payload: response.data });
            cb(null)
        })
        .catch((e) => cb(errorHandling(e)));
    }
}

export const updateNeighborhoods = (neighborhoods, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/lojas/${loja}?loja=${loja}`, { bairros: neighborhoods }, getHeaders())
        .then(response => {
            dispatch({ type: GET_CONFIGURACOES, payload: response.data });
            cb(null)
        })
        .catch((e) => cb(errorHandling(e)));
    }
}

export const updateBlockedDates = (dates, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/lojas/${loja}?loja=${loja}`, { blockedDates: dates }, getHeaders())
        .then(response => {
            dispatch({ type: GET_CONFIGURACOES, payload: response.data });
            cb(null)
        })
        .catch((e) => cb(errorHandling(e)));
    }
}

export const updateDiscounts = (discounts, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/lojas/${loja}?loja=${loja}`, { discounts }, getHeaders())
        .then(response => {
            dispatch({ type: GET_CONFIGURACOES, payload: response.data });
            cb(null)
        })
        .catch((e) => cb(errorHandling(e)));
    }
}

export const limparConfiguracao = () => ({ type: LIMPAR_CONFIGURACOES })