import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './store';

import { HashRouter as Router, Route } from 'react-router-dom';

import base from './containers/HOC/Base';
import noAuth from './containers/HOC/NoAuth';

import { initApp } from './actions'

// CONTAINER COM BASE
import Pedidos from './containers/Pedidos';

import Entregas from './containers/Entregas';
import Entrega from './containers/Entrega';

import Clientes from './containers/Clientes';
import Cliente from './containers/Cliente';
import NewClient from './containers/Clientes/newclient';

import Categorias from './containers/Categorias';
import Categoria from './containers/Categoria';
import NovaCategoria from './containers/Categorias/novaCategoria';

import Produtos from './containers/Produtos';
import Produto from './containers/Produto';

import Avaliacoes from './containers/Avaliacoes';
import Avaliacao from './containers/Avaliacao';

import Reports from './containers/Reports';

import Configuracoes from './containers/Configuracoes';
import Perfil from './containers/Perfil';

import Upsells from './containers/Upsells';
import Upsell from './containers/Upsell';
import NovoUpsell from './containers/Upsells/novoUpsell';

// CONTAINER SEM BASE
import Login from './containers/Login';
import RecuperarSenha from './containers/RecuperarSenha';
import ResetarSenha from './containers/RecuperarSenha/ResetarSenha';

import NewOrder from './containers/NewOrder';
import NovoProduto from './containers/Produtos/novoProduto';

import EmailMarketing from './containers/EmailMarketing';

import GoogleApplications from './containers/GoogleApplications';

class App extends Component {

  componentDidMount(){
    initApp();
  }

  render(){
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route path={"/"} exact component={base(Pedidos)}/>

            <Route path={"/entregas"} exact component={base(Entregas)}/>
            <Route path={"/entrega/:id"} exact component={base(Entrega)}/>

            <Route path={"/clientes"} exact component={base(Clientes)}/>
            <Route path={"/cliente/:id"} exact component={base(Cliente)}/>
            <Route path={"/newclient"} exact component={base(NewClient)}/>

            <Route path={"/categorias"} exact component={base(Categorias)}/>
            <Route path={"/categoria/:id"} exact component={base(Categoria)}/>

            <Route path={"/produtos"} exact component={base(Produtos)}/>
            <Route path={"/produto/:id"} exact component={base(Produto)}/>

            <Route path={"/upsells"} exact component={base(Upsells)}/>
            <Route path={"/upsell/:id"} exact component={base(Upsell)}/>
            
            <Route path={"/avaliacoes/:id"} exact component={base(Avaliacoes)}/>
            <Route path={"/avaliacao/:id"} exact component={base(Avaliacao)}/>

            <Route path={"/configuracoes"} exact component={base(Configuracoes)}/>
            <Route path={"/perfil"} exact component={base(Perfil)}/>
            
            <Route path={"/login"} exact component={noAuth(Login)}/>
            <Route path={"/recuperar-senha"} exact component={noAuth(RecuperarSenha)}/>
            <Route path={"/resetar-senha/:token"} exact component={noAuth(ResetarSenha)}/>

            <Route path={"/neworder"} exact component={base(NewOrder)}/>
            <Route path={"/newproduct"} exact component={base(NovoProduto)}/>
            <Route path={"/newcategory"} exact component={base(NovaCategoria)}/>
            <Route path={"/newupsell"} exact component={base(NovoUpsell)}/>

            <Route path={"/reports"} exact component={base(Reports)}/>
            <Route path={"/email-marketing"} exact component={base(EmailMarketing)}/>
            <Route path={"/google-applications"} exact component={base(GoogleApplications)}/>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;