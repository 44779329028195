import { useEffect, useState } from "react"
import { connect } from 'react-redux';
import * as productActions from '../../actions/produtos';
import * as upsellActions from '../../actions/upsells';
import * as configsActions from '../../actions/configuracoes';
import { apiLinkImages } from "../../config";

import { formatMoney, formatMoneyOnChange } from "../../utils";
import { Notification, notify } from '../../utils/notification';

function Discount({ loja, getProduto, getUpsell, getProdutosPesquisa, getUpsellsPesquisa, produtos, upsells, updateDiscounts }) {
  const [notification, setNotification] = useState("");
  const [discounts, setDiscounts] = useState([]);
  const [formState, setFormState] = useState({});
  const [search, setSearch] = useState({ query: '' });

  function handleRemove(code){
    const _newList = discounts.filter(_discount => _discount.code !== code);
    setDiscounts(_newList)

    updateDiscounts(_newList, loja._id, (data) => {
      return notify("Cupom removido com sucesso!", setNotification, "success");
    })
  }

  function handleSubmit(e){
    e.preventDefault();

    if(!formState.code) return notify("Você precisa informar o código do cupom!", setNotification, "error");
    if(!formState.type) return notify("Você precisa informar o tipo de cupom!", setNotification, "error");

    if(formState.type === "product" && !formState.productDetails) return notify("Você precisa selecionar um produto para este cupom!", setNotification, "error");
    if(formState.type === "value" && !formState.value) return notify("Você precisa informar o valor para este cupom!", setNotification, "error");

    var formattedValue;
    if(formState.type === "value" && formState.value) {
      const _amountValue = String(formState.value).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
      formattedValue = _amountValue.length <= 2 ? Number(_amountValue).toFixed(2) : Number(`${_amountValue.slice(-11, -2)}.${_amountValue.slice(-2)}`);
    }

    if(discounts.filter(_discount => _discount.code === formState.code).length > 0) return notify("Este cupom já existe!", setNotification, "error");

    const newDiscont = { code: formState.code, type: formState?.type, productDetails: formState.productDetails, product: formState.type === "product" ? formState.productDetails._id : null, value: formattedValue };
    const newArrayDiscounts = [ ...discounts, newDiscont ];
    setDiscounts(newArrayDiscounts);
    setFormState({ code: '', type: '' });
    setSearch({ query: '', products: [] });

    updateDiscounts(newArrayDiscounts, loja._id, (data) => {
      return notify("Cupom adicionado com sucesso!", setNotification, "success");
    })
  }

  useEffect(() => {
    if(search?.query?.length > 0) {
      getProdutosPesquisa(search.query, "decrescente", 0, 1000, loja._id);
      getUpsellsPesquisa(search.query, "decrescente", 0, 1000, loja._id);
      const products = (produtos?.docs || upsells) ? [ ...produtos?.docs, ...upsells ] : [];
      if(products?.length > 0) setSearch({ ...search, products });
    }
  }, [search?.query]);

  useEffect(() => {
    async function loadingDiscounts() {
      var _discounts = await Promise.all(loja.discounts?.map(async (discount) => {
        if(!discount.product) return { ...discount };

        return {
          ...discount,
          productDetails: await new Promise(async (resolve, reject) => {
            await getProduto(discount.product, loja._id, (response) => {
              response.produto && resolve(response.produto)
            })
  
            await getUpsell(discount.product, loja._id, (response) => {
              response.upsell && resolve(response.upsell);
            })
          })
        }
      }))

      setDiscounts(_discounts)
    }

    if(discounts.length === 0 && loja) loadingDiscounts();
  }, [])

  return (
    <div className="discount">
      <Notification notification={notification} />
      <h3><span className="new">NOVO</span> Cupons de desconto <span className="success">disponíveis</span></h3>

      <div className="list">
        {discounts.map((discount, i) => (
          <div key={i} className="item">
            <div className="info">
              <label>Código</label>
              <span>#{discount?.code}</span>
            </div>

            {discount?.type === "product" ? (
              <div className="info">
                <label>Produto</label>
                <span>{discount?.productDetails?.titulo}</span>
              </div>
            ) : (
              <div className="info">
                <label>Valor</label>
                <span>{formatMoney(discount?.value)}</span>
              </div>
            )}

            <div className="remove" onClick={() => handleRemove(discount.code)}>
              <span></span>
              <span></span>
            </div>
          </div>
        ))}
      </div>

      <form onSubmit={handleSubmit} className="new-discount">
        <div className="grid">
          <div className="input code">
            <span>#</span>
            <input maxLength={15} placeholder="CODIGO" value={formState.code} onChange={(e) => setFormState({ ...formState, code: e.target.value.toUpperCase() })} />
          </div>

          <div className="checkboxes">
            <div className="checkbox">
              <input id="product" name="product" type="radio" value="product" checked={formState?.type === "product"} onChange={() => setFormState({ ...formState, type: "product" })} />
              <label htmlFor="product">Cupom de produto</label>
            </div>
            <div className="checkbox">
              <input id="value" name="product" type="radio" value="value" checked={formState?.type === "value"} onChange={() => setFormState({ ...formState, type: "value" })} />
              <label htmlFor="value">Cupom de valor</label>
            </div>
          </div>
        </div>

        {formState?.type && (
          <div className="grid">
            {formState?.type === "product" && (
              <div className="input product">
                {formState?.productDetails ? (
                  <div className="result">
                    <div className="image" style={{ backgroundImage: `url('${apiLinkImages}/public/images/${formState.productDetails?.fotos[0]}')` }}></div>
                    <span>{formState.productDetails?.titulo}</span>
                    <div className="remove" onClick={() => setFormState({ ...formState, product: null, productDetails: null })}>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : <input placeholder="Pesquisar por produto" value={search?.query} onChange={(e) => setSearch({ query: e.target.value })} /> }
                {search?.products?.length > 0 && (
                  <div className="results">
                    {search?.products?.map((product, i) => (
                      <div key={i} className="result" onClick={() => {setFormState({ ...formState, productDetails: product });setSearch({})}}>
                        <div className="image" style={{ backgroundImage: `url('${apiLinkImages}/public/images/${product?.fotos[0]}')` }}></div>
                        <span>{product?.titulo}</span>
                      </div>
                    ))}
                  </div>
                )}             
              </div>
            )}
            {formState?.type === "value" && (
              <div className="input product">
                <input placeholder="Valor de desconto a ser aplicado" value={formState?.value} onChange={(e) => setFormState({ ...formState, value: formatMoneyOnChange(e.target.value) })} />
              </div>
            )}

            <button type="submit" className="addnew">Adicionar</button>
          </div>
        )}
        
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
	loja: state.configuracao.loja,
	usuario: state.auth.usuario,
  produtos: state.produto.produtos,
  upsells: state.upsell.upsells
});

export default connect(mapStateToProps, { ...productActions, ...upsellActions, ...configsActions })(Discount);