import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import ButtonSimples from '../../components/Button/Simples';
import InputSimples from '../../components/Inputs/Simples';
import Voltar from '../../components/Links/Voltar';

import { connect } from 'react-redux';
import AlertGeral from '../../components/Alert/Geral';
import * as actions from '../../actions/categorias';

class NovaCategoria extends Component {

	state = {
		nome: "",
		erros: {},
		aviso: null
	}

	salvarCategoria(){
		if(!this.state.nome) return alert("Por favor, preencha o nome da categoria.")
		
		this.props.salvarCategoria(this.state, this.props.usuario.loja, (error) => {
			this.setState({
				aviso: {
					status: !error,
					msg: error ? error.message : "Categoria adicionada com sucesso!"
				}
			});
		});
	}

	onChangeInput = (field, value) => this.setState({ [field]: value });
	
	render(){
		const { nome, codigo, erros } = this.state;
		return (
			<div className="Category container-centered">
				<div className="head">
					<Voltar history={this.props.history} />
					<h1>Nova Categoria</h1>
				</div>

				<div className="items">
					<InputSimples name="nome" label="Nome:" value={nome} 
					erro={erros.nome} onChange={(ev) => this.onChangeInput("nome", ev.target.value) } />
					
					<div className="ButtonSubmit">
                <button onClick={() => this.salvarCategoria()}>Adicionar</button>
            </div>
				</div>
			</div>
		)
	}

}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(NovaCategoria);