import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import { transformeDate } from './index';
import {
	GET_CLIENTES,
	GET_CLIENTE,
	GET_CLIENTE_PEDIDOS,
	REMOVE_CLIENTE,
	LIMPAR_CLIENTE, GET_CLIENTES_ALL
} from './types';

export const getClientes = (atual, limit, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes?loja=${loja}&offset=${atual}&limit=${limit}&sortType=decrescente`, getHeaders())
		.then(response => dispatch({ type: GET_CLIENTES, payload: response.data }))
		.catch(errorHandling);
	}
}

export const getClientesAll = (atual, limit, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes?loja=${loja}&offset=${atual}&limit=${limit}&sortType=decrescente`, getHeaders())
		.then(response => dispatch({ type: GET_CLIENTES_ALL, payload: response.data }))
		.catch(errorHandling);
	}
}

export const getClientesPesquisa = (termo, atual, limit, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes/search/${termo}?loja=${loja}&offset=${atual}&limit=${limit}&sortType=decrescente`, getHeaders())
		.then(response => dispatch({ type: GET_CLIENTES, payload: response.data }))
		.catch(errorHandling);
	}
}

export const getCliente = (id, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes/admin/${id}?loja=${loja}`, getHeaders())
		.then(response => dispatch({ type: GET_CLIENTE, payload: response.data }))
		.catch(errorHandling);
	}
}

export const getClientePedidos = (id, atual, limit, loja) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes/admin/${id}/pedidos?loja=${loja}&offset=${atual}&limit=${limit}&sortType=decrescente`, getHeaders())
		.then(response => dispatch({ type: GET_CLIENTE_PEDIDOS, payload: response.data }))
		.catch(errorHandling);
	}
}

export const limparCliente = (id, loja) => ({ type: LIMPAR_CLIENTE });

export const updateClient = (client, id, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/clientes/admin/${id}?loja=${loja}`, {
			nome: client.name,
			email: client.email,
			telefones: [client.phone],
			cpf: client.cpf
		}, getHeaders())
		.then(response => {
			dispatch({ type: GET_CLIENTE, payload: response.data });
			cb(null, response.data);
		})
		.catch((e) => cb(errorHandling(e)));
	}
}

export const removerCliente = (id, loja, cb) => {
	return function(dispatch){
		axios.delete(`${api}/${versao}/api/clientes/admin/${id}?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: REMOVE_CLIENTE, payload: response.data });
			cb(null);
		})
		.catch((e) => cb(errorHandling(e)));
	}
}

export const createNewCliente = (form, loja, cb) => {
  return function(){
    axios.post(`${api}/${versao}/api/clientes/manual?loja=${loja}`, { nome: form.name, email: form.email, cpf: form.cpf, telefones: [ form.phone ] }, getHeaders())
    .then(response => {
      cb(null, response.data)
    })
    .catch(e => cb(e));
  }
}