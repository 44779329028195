import Menu from './Menu'

import { connect } from 'react-redux';
import * as actions from '../../actions';

function Dashboard({ admin, history, handleLogout, children }) {
	return (
		<div className="dashboard">
			<Menu admin={admin} history={history} handleLogout={handleLogout} />
			<div className="TemplateBody">
				{children}
			</div>
		</div>
	);
}

const mapStateToProps = state => ({
	admin: state.auth.usuario
})

export default connect(mapStateToProps, actions)(Dashboard);