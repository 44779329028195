import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import {
    GET_UPSELLS,
    GET_UPSELL,
    GET_UPSELLPRODUTOS,
    LIMPAR_UPSELL,
    LIMPAR_UPSELLS,
    DELETE_UPSELL,
    GET_PRODUTO
} from './types';

export const getUpsells = (ordem, atual, limit, loja) => {
    return function(dispatch){
        axios.get(`${api}/${versao}/api/upsell?offset=${atual}&limit=${limit}&loja=${loja}&sortType=${ordem}`, getHeaders())
        .then(response => dispatch({ type: GET_UPSELLS, payload: response.data }))
        .catch(errorHandling);
    }
}

export const getUpsellsPesquisa = (termo, ordem, atual, limit, loja) => {
    return function(dispatch){
        axios.get(`${api}/${versao}/api/upsell/search/${termo}?offset=${atual}&limit=${limit}&loja=${loja}&sortType=${ordem}`, getHeaders())
        .then(response => dispatch({ type: GET_UPSELLS, payload: response.data }))
        .catch(errorHandling);
    }
}

export const getUpsell = (id, loja, cb) => {
    return function(dispatch){
        axios.get(`${api}/${versao}/api/upsell/${id}?loja=${loja}`, getHeaders())
        .then(response => {
            dispatch({ type: GET_UPSELL, payload: response.data })
            cb && cb(response.data);
        })
        .catch(errorHandling);
    }
}

export const getUpsellProdutos = (id, loja) => {
    return function(dispatch){
        axios.get(`${api}/${versao}/api/upsell/${id}/produtos?loja=${loja}`, getHeaders())
        .then(response => dispatch({ type: GET_UPSELLPRODUTOS, payload: response.data }))
        .catch(errorHandling);
    }
}

export const deleteUpsell = (admin, id, loja, cb) => {
    return function(dispatch){
        axios.delete(`${api}/${versao}/api/upsell/${id}?loja=${loja}`, getHeaders())
        .then(response => {
            dispatch({ type: DELETE_UPSELL, payload: response.data })
            cb(null)
        })
        .catch(e => errorHandling(e));
    }
}

export const updateUpsell = (produto, id, loja, cb) => {
    if(String(produto.preco).slice(0, 2) !== "R$") produto.preco = produto.preco.toFixed(2);
    const _amountPrice = String(produto.preco).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPrice = Number(`${_amountPrice.slice(-11, -2)}.${_amountPrice.slice(-2)}`).toFixed(2);

    const _amountPricePromotion = String(produto?.promocao || '0').replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPromotion = Number(`${_amountPricePromotion.slice(-11, -2)}.${_amountPricePromotion.slice(-2)}`).toFixed(2);

    console.log({
        titulo: produto.titulo,
        disponibilidade: Boolean(produto?.disponibilidade),
        preco: Number(formattedPrice),
        promocao: Number(formattedPromotion)
    })

    return function(dispatch){
        axios.put(`${api}/${versao}/api/upsell/${id}?loja=${loja}`, {
            titulo: produto.titulo,
            disponibilidade: Boolean(produto?.disponibilidade),
            preco: formattedPrice,
            promocao: formattedPromotion || 0
        }, getHeaders())
        .then(response => {
            dispatch({ type: GET_UPSELL, payload: response.data });
            cb(null);
        })
        .catch(e => cb(errorHandling(e)));
    }
}

export const updateUpsellImage = (data, id, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/upsell/images/${id}?loja=${loja}`, data, getHeaders())
        .then(response => {
            dispatch({ type: GET_UPSELL, payload: response.data });
            cb(null);
        })
        .catch(e => cb(errorHandling(e)));
    }
}

export const updateUpsellProduct = (upsellid, productid, loja, cb) => {
    return function(dispatch){
        axios.put(`${api}/${versao}/api/upsell/remove/${upsellid}?loja=${loja}`, { productid: productid } , getHeaders())
        .then(response => {
            dispatch({ type: GET_PRODUTO, payload: response.data })
            cb(null, response.data.produto.upsell);
        })
        .catch(e => cb(e));
    }
}

export const criarUpsell = (upsell, loja, cb) => {
    const _amountPrice = String(upsell.preco).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    const formattedPrice = Number(`${_amountPrice.slice(-11, -2)}.${_amountPrice.slice(-2)}`);
    upsell.preco = formattedPrice;

    return function(dispatch){
        axios.post(`${api}/${versao}/api/upsell?loja=${loja}`, upsell, getHeaders())
        .then(response => {
            dispatch({ type: GET_UPSELL, payload: response.data });
            cb(null, response.data.upsell);
        })
        .catch((e) => cb(errorHandling(e)));
    }
}


export const limparUpsell = () => ({ type: LIMPAR_UPSELL });
export const limparUpsells = () => ({ type: LIMPAR_UPSELLS });
