import { useEffect, useState } from "react"
import moment from "moment"

import { Notification } from "../../../utils/notification"
import NewCampaign from "./new"
import Loading from "../../../components/Loading"

export default function Campaigns({ admin, campaigns, templates, audiences, clientsLength, leadsLength, createCampaign, refreshList }) {

  const [modal, setModal] = useState(false)
  const [notification, setNotification] = useState(({ active: false, text: "" }))

  return !campaigns ? <Loading /> : (
    <div className="campaigns">
      <Notification notification={notification} />

      <NewCampaign loja={admin.loja} active={modal} close={() => { setModal(false); refreshList() }} templates={templates} audiences={audiences} clientsLength={clientsLength} leadsLength={leadsLength} createCampaign={createCampaign} setNotification={setNotification} />

      <div className="section-actions">
        <button onClick={() => setModal(true)}><i className="fas fa-plus"></i> Nova Campanha</button>  
      </div>

      { campaigns?.map((campaign, index) => (
        <div className="campaign" key={index}>
          <div className="preview">
            <div className="preview-html-overlay"></div>
            <div className="preview-html" dangerouslySetInnerHTML={{ __html: campaign.template.html}}></div>
          </div>
          <div className="information">
            <h1>{campaign.title}</h1>
            <p><span className="status createdAt">Criada em</span> {moment(campaign.createdAt).format("DD/MM/YYYY")} às {moment(campaign.createdAt).format("HH:mm")}</p>

            <div className="audience-info">
              <i className="fas fa-paper-plane"></i> Enviada para {campaign.recipients.length} pessoas.
            </div>
          </div>
        </div>
      )) }

    </div>
  )
}