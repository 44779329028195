import axios from 'axios';
import { api, versao } from '../config';
import { getHeaders } from './localStorage';
import errorHandling from './errorHandling';
import { GET_PRODUTOS } from './types';


export const listProducts = (ordem, atual, limit, loja) => {
  return function(dispatch){
    axios.get(`${api}/${versao}/api/produtos?offset=${atual}&limit=${limit}&loja=${loja}&sortType=${ordem}`, getHeaders())
    .then(response => dispatch({ type: GET_PRODUTOS, payload: response.data }))
    .catch(errorHandling);
  }
}

export const saveProducts = (products, loja) => {
  return function() {
    axios.post(`${api}/${versao}/api/google-applications/atualizar?loja=${loja}`, { products }, getHeaders())
    .then(response => console.log(response.data))
    .catch(errorHandling);
  }
}

export const getProductGoogle = (product) => {
  return function() {
    axios.get(`${api}/${versao}/api/google-applications/get?loja=60242e47e43639133f&id=`+product, getHeaders())
        .then(response => console.log(response.data))
        .catch(errorHandling);
  }
}

export const listProductsGoogle = () => {
  return function() {
    axios.get(`${api}/${versao}/api/google-applications/list?loja=60242e47e43639133f`, getHeaders())
        .then(response => console.log(response.data))
        .catch(errorHandling);
  }
}