import { GET_TEMPLATES, GET_CLIENTS_AUDIENCE, GET_LEADS_AUDIENCE, GET_AUDIENCES, GET_CAMPAIGNS } from '../actions/types';

export default (state = {}, action) => {
	switch(action.type){
    case GET_TEMPLATES:
      return {
        ...state,
        templates: action.payload.templates
      }
    case GET_CLIENTS_AUDIENCE:
      return {
        ...state,
        clientes: action.payload.clientes
      }
    case GET_LEADS_AUDIENCE:
      return {
        ...state,
        leads: action.payload.leads
      }
    case GET_AUDIENCES:
      return {
        ...state,
        audiences: action.payload.audiences
      }
    case GET_CAMPAIGNS: 
      return {
        ...state,
        campaigns: action.payload.campaigns
      }
    default:
      return state;
	}
}