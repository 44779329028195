import React from 'react';
import { Link } from 'react-router-dom';

const TabelaSimples = ({ cabecalho, dados }) => (
	<div className="TabelaSimples">
		<table className="simples">
			<tr className="trCabecalho">
				{ cabecalho.map((item, idx) => ( <th key={idx}>{item}</th> )) }
			</tr>
			<tbody>
				{ dados.map((linha, idx) => (
						<tr key={idx}>
						<Link to={linha["Ações"]}>
						{ cabecalho.map((item, index) => (<td key="index">{linha[item] || ""}</td>)) }
						</Link>
						</tr>
				)) }
			</tbody>
		</table>
	</div>
);

export default TabelaSimples;