import React, { Component } from 'react';

import Titulo from '../../components/Texto/Titulo';
import Input from '../../components/Inputs/Simples';
import Button from '../../components/Button/Simples';

class ResetarSenha extends Component {

	state = {
		senha: "",
		confirmarSenha: ""
	}

	onChangeInput = (field, ev) => this.setState({ [field]: ev.target.value });

	render(){
		const { senha, confirmarSenha } = this.state;
		return (
			<div className="Resetar-Senha">
				<Titulo tipo="h1" titulo="Xleg Brazil"/>

				<p>Digite sua nova senha e confirme no campo abaixo.</p>
				
				<div>
					<Input type="password" label="Nova senha" value={senha} onChange={(ev) => this.onChangeInput("senha", ev)} />
					<Input type="password" label="Confirmar Senha" value={confirmarSenha} onChange={(ev) => this.onChangeInput("confirmarSenha", ev)} />
					<Button type="success" rota="/login" label="Redefinir Senha" />
				</div>
			</div>
		)
	}
}

export default ResetarSenha;