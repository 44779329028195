import { useState } from "react";
import InputAutoWidth from "../../../components/Inputs/AutoWidth"
import { notify } from "../../../utils/notification";

export default function NewAudience({ loja, clientes, active, closeModal, refreshList, saveAudience, setNotification }) {

  const [title, setTitle] = useState('Nova Audiência')

  const [selectedClients, setSelectedClients] = useState([])
  const [audienceClientsType, setAudienceClientsType] = useState('')

  function onChangeManual(client) {
    const verifyClient = selectedClients.filter(fiClient => fiClient._id === client._id).length;

    var newArray = [...selectedClients];
    if(verifyClient === 0) {
      newArray.push(client)
    } else {
      newArray = newArray.filter(fiClient => fiClient._id !== client._id)
    }

    setSelectedClients(newArray)

    return;
  }

  function handleAdd() {
    saveAudience({ title, leads: audienceClientsType === "manual" ? selectedClients : [], leadsType: audienceClientsType }, loja, (audience) => {
      refreshList();
      clearStates();
      return notify("Audiência criada com sucesso!", setNotification);
    })
    return;
  }

  function clearStates() {
    setTitle('Nova Audiência')
    setSelectedClients([])
    setAudienceClientsType('')
    closeModal()
  }

  return (
    <div className={`modalAudience${active ? ' active' : ''}`}>
      <div className="content">
        <div className="close" onClick={() => clearStates()}>
          <i className="fas fa-times"></i>
        </div>
        <InputAutoWidth initialTitle={title} buttonTitle="Ok" callback={(_title) => setTitle(_title)} />

        <div className="list-items">
          <p>Selecione os <span>clientes que participarão</span> desta lista:</p>
          <div className="item" onClick={() => setAudienceClientsType("leads")}>
            <div className={`input-radio${audienceClientsType === "leads" ? ' active' : ''}`}></div>
            <label>Leads que ainda não compraram <div className="new">Novo</div></label>
          </div>
          <div className="item" onClick={() => setAudienceClientsType("allclients")}>
            <div className={`input-radio${audienceClientsType === "allclients" ? ' active' : ''}`}></div>
            <label>Todos os clientes da loja</label>
          </div>
          <div className="item" onClick={() => setAudienceClientsType("manual")}>
            <div className={`input-radio${audienceClientsType === "manual" ? ' active' : ''}`}></div>
            <label>Selecionar manualmente</label>
          </div>
        </div>

        { audienceClientsType === "manual" && (
          <div className="selectLeads">
            { clientes.map((client, index) => (
              <div className="item" key={index}>
                <input type="checkbox" id={`lead[${index}]`} onChange={() => onChangeManual(client)} />
                <label htmlFor={`lead[${index}]`}>{client.name} - {client.email}</label>
              </div>
            )) }
          </div>
        )}

        <button onClick={handleAdd}>Adicionar</button>
      </div>
    </div>
  )
}