import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions/clientes';

import Details from './details';
import Orders from './orders';

import { Notification, notify } from '../../utils/notification';
import Loading from '../../components/Loading';

function Client({ admin, match, history, getCliente, client, updateClient, limparCliente }) {

	const [notification, setNotification] = useState("");

	useEffect(() => {
		if(!client) getCliente(match.params.id, admin?.loja);
		return () => {
			limparCliente();
		};
	}, [match.params.id])
	
	return !client ? <Loading /> : (
		<div className="ClientView container-centered">
			<Notification notification={notification} />
			<Details history={history} client={client} updateClient={updateClient} notify={notify} setNotification={setNotification} />
			<Orders id={match.params.id} />
		</div>
	)
}

const mapStateToProps = state => ({
	admin: state.auth.usuario,
	client: state.cliente.cliente
});

export default connect(mapStateToProps, actions)(Client);