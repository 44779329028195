import moment from "moment";
import { useState } from "react";

import { connect } from 'react-redux';
import * as actions from '../../actions/configuracoes';

function ConfigDates({ loja, updateBlockedDates }) {

  const [displayText, setDisplayText] = useState('');
  const [check1, setCheck1] = useState(false)
  const [check2, setCheck2] = useState(false)
  const [check3, setCheck3] = useState(false)

  const [items, setItems] = useState(() => {
    if(loja) return loja.blockedDates
  });

  function handleChange(e) {
    setDisplayText(e.target.value)
    return
  }

  function addItem() {
    if(!displayText) return alert("Por favor, informe corretamente a data a ser desativada.");

    const _newData = { date: moment(displayText).format("DD/MM/YYYY"), periods: { manha: check1, tarde: check2, noite: check3 } }

    let newState = items.filter(fiItem => fiItem.date !== displayText);
 
    let newArray = [...newState, _newData]
    if(items.includes(_newData)) {
      newArray = items.filter(fiItem => fiItem.date === displayText);
    }

    setItems(newArray)
    updateBlockedDates(newArray, loja._id, (e) => console.log(e))
    
    setDisplayText(``)
  }

  const removeItem = async (stateItems, date) => {
    setDisplayText(``)

    let newState = stateItems.filter(fiItem => fiItem.date !== date);
    
    setItems(newState)
    updateBlockedDates(newState, loja._id, (e) => console.log(e))
  }

  return (
    <div className="config-dates">
      <h3>Datas <span className="danger">não disponíveis</span></h3>

      <div className="inputSearchProducts">

        { items && (
          <div className="categories-items">
            { items.map((item, index) => (
              <div className="item" key={index}>
                <span>{item.date}</span>
                <div className="period">
                  <label>Manhã</label>
                  <span>{item.periods.manha ? 'bloqueado' : 'liberado'}</span>
                </div>
                <div className="period">
                  <label>Tarde</label>
                  <span>{item.periods.tarde ? 'bloqueado' : 'liberado'}</span>
                </div>
                <div className="period">
                  <label>Noite</label>
                  <span>{item.periods.noite ? 'bloqueado' : 'liberado'}</span>
                </div>
                <button className="remove" onClick={() => removeItem(items, item.date)}>
                  <span></span>
                  <span></span>
                </button>
              </div>
            )) }
          </div>
        ) }

        <div className="inputs">
          <input type="date" name="date" autoComplete="new-password" placeholder="Adicionar Data" value={displayText} onChange={handleChange} />
          <div className="checkboxes">
            <div className="checkbox">
              <input id="manha" type="checkbox" value="Manhã" onChange={() => setCheck1(!check1)} />
              <label htmlFor="manha">Manhã</label>
            </div>
            <div className="checkbox">
              <input id="tarde" type="checkbox" value="Tarde" onChange={() => setCheck2(!check2)} />
              <label htmlFor="tarde">Tarde</label>
            </div>
            <div className="checkbox">
              <input id="noite" type="checkbox" value="Noite" onChange={() => setCheck3(!check3)} />
              <label htmlFor="noite">Noite</label>
            </div>
          </div>
          <button onClick={() => addItem()}>Confirmar</button>
        </div>

      </div>
    </div>
  )
}

const mapStateToProps = state => ({
	loja: state.configuracao.loja,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(ConfigDates);