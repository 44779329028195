import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions/clientes';

import { Notification, notify } from '../../utils/notification';
import Voltar from '../../components/Links/Voltar';
import InputSimples from '../../components/Inputs/Simples';
import { formatCPF } from '../../actions';

function NewClient({ admin, match, history, createNewCliente }) {

	const [notification, setNotification] = useState("");
  const [clientState, setClientState] = useState({ name: "", email: "", phone: "", cpf: "" });
  const [success, setSuccess] = useState(false);
  
  function handleAdd() {
    if(!clientState.name) return notify("Por favor, preencha o nome do cliente.", setNotification, "error");
    createNewCliente(clientState, admin?.loja, (err) => {
      if(err) return notify(err.message, setNotification, "error");
      setSuccess(true)
			notify("Cliente cadastrado com sucesso!", setNotification)
      setTimeout(() => {
        history.push("/clientes")
      }, 4000)
    })
  }
	
	return (
		<div className="ClientView container-centered">
			<Notification notification={notification} />

      <div className="details" style={success ? { opacity: '.2', cursor: 'not-allowed' } : { opacity: '1' }}>
        <div className="head">
          <Voltar history={history} />
          <h1>Novo Cliente</h1>
        </div>

        <div className="body">
          <div className="item">
            <InputSimples type="text" value={clientState.name} label="Nome" onChange={(e) => setClientState({ ...clientState, name: e.target.value })} />
            <InputSimples type="text" value={clientState.cpf} label="CPF" onChange={(e) => setClientState({ ...clientState, cpf: formatCPF(e.target.value) })} />
          </div>
          <div className="item">
            <InputSimples type="text" value={clientState.email} label="E-mail" onChange={(e) => setClientState({ ...clientState, email: e.target.value })} />
            <InputSimples type="text" value={clientState.phone} label="Telefone" onChange={(e) => setClientState({ ...clientState, phone: e.target.value })} />
          </div>
        
          <div className="ButtonSubmit">
            <button onClick={!success && handleAdd} style={success ? { cursor: 'not-allowed' } : {}}>Cadastrar Cliente</button>
          </div>
        </div>
      </div>
		</div>
	)
}

const mapStateToProps = state => ({
	admin: state.auth.usuario,
	client: state.cliente.cliente
});

export default connect(mapStateToProps, actions)(NewClient);