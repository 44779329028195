import moment from "moment"
import { useState } from "react"

import InputAutoWidth from "../../../components/Inputs/AutoWidth"
import { notify } from '../../../utils/notification'

export default function NewCampaign({ loja, active, close, templates, audiences, clientsLength, leadsLength, createCampaign, setNotification }) {

  const [title, setTitle] = useState('Novo e-mail')
  const [section, setSection] = useState('one')
  const [templateSelected, setTemplateSelected] = useState({})
  const [audienceSelected, setAudienceSelected] = useState({})

  function closeModal() {
    setSection('one')
    return close(false)
  }

  function clearStates() {
    setTitle('Nova campanha')
    setSection('one')
    setTemplateSelected({})
    setAudienceSelected({})
  }

  function handleAdd() {
    createCampaign({ title, template: templateSelected, audience: audienceSelected }, loja, (response) => {
      notify("Campanha enviada com sucesso!", setNotification)
      clearStates();
      return closeModal();
    })
  }

  if(!templates) return null;

  return (
    <div className={`modalCampaign${active ? ' active' : ''}`}>
      <div className="overlay"></div>
      <div className={`content`}>
        <div className="close" onClick={() => closeModal()}>
          <i className="fas fa-times"></i>
        </div>

        <div className={`section one ${section === 'one' ? 'active' : ''}`}>
          <div className="information">
            <div className="head">
              <h1>Vamos começar uma nova campanha?</h1>
            </div>

            <div className="body">
              <h4>Qual será o <span>título do e-mail?</span></h4>
              <InputAutoWidth initialTitle={title} buttonTitle="Ok" callback={(_title) => setTitle(_title)} />
            </div>

            <div className="actions end">
              <button className="go" onClick={() => setSection('two')}><i className="fas fa-arrow-right"></i></button>
            </div>
          </div>
        </div>

        <div className={`section two ${section === 'two' ? 'active' : ''}`}>
          <div className="information">
            <div className="body">
              <h4><span>Selecione o template</span> de e-mail para esta campanha:</h4>

              <div className="templates">
                { templates.map((template, index) => (
                  <div className={`template${templateSelected._id === template._id ? ' active' : ''}`} key={index} onClick={() => setTemplateSelected({ _id: template._id, title: template.title, html: template.html })}>
                    <div className="information">
                      <h1>{template.title}</h1>
                      { template.createdAt === template.updatedAt ? (
                        <p><span className="status createdAt">Adicionada em</span> {moment(template.createdAt).format("DD/MM/YYYY")} às {moment(template.createdAt).format("HH:mm")}</p>
                      ) : (
                        <p><span className="status updatedAt">Atualizada em</span> {moment(template.updatedAt).format("DD/MM/YYYY")} às {moment(template.updatedAt).format("HH:mm")}</p>
                      )}
                      <div className="actions">
                        {/* <button onClick={() => { setTemplateSelected({ _id: template._id, title: template.title, design: template.design }); setEditor(true) }}>Editar Template</button> */}
                      </div>
                    </div>
                    <div className="preview">
                      <div className="preview-html-overlay"></div>
                      <div className="preview-html" dangerouslySetInnerHTML={{ __html: template.html}}></div>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="actions">
              <button className="go back" onClick={() => setSection('one')}><i className="fas fa-arrow-left"></i></button>
              <button className="go" onClick={() => setSection('three')}><i className="fas fa-arrow-right"></i></button>
            </div>
          </div>
        </div>

        <div className={`section three ${section === 'three' ? 'active' : ''}`}>
          <div className="information">
            <div className="body">
              <h4><span>Conecte</span> a sua audiência:</h4>

              <div className="audiences">
                { audiences && audiences.map((audience, index) => (
                  <div className={`audience ${audienceSelected._id === audience._id ? ' active' : ''}`} key={index} onClick={() => setAudienceSelected({ _id: audience._id, title: audience.title, leads: audience.leads, leadsType: audience.leadsType })}>
                    <div className="information">
                      <h1>{audience.title}</h1>
                      { audience.createdAt === audience.updatedAt ? (
                        <p><span className="status createdAt">Adicionada em</span> {moment(audience.createdAt).format("DD/MM/YYYY")} às {moment(audience.createdAt).format("HH:mm")}</p>
                      ) : (
                        <p><span className="status updatedAt">Atualizada em</span> {moment(audience.updatedAt).format("DD/MM/YYYY")} às {moment(audience.updatedAt).format("HH:mm")}</p>
                      )}
                    </div>
                    <div style={{ display: 'flex'}}>
                      <div className="audience-info">
                        <span className="leads-number">
                          <i className="fas fa-users"></i> {audience.leadsType === "manual" ? audience.leads.length : (audience.leadsType === "leads" ? leadsLength : clientsLength)}
                        </span>
                        <span className="leads-info">pessoas na lista</span>
                      </div>
                    </div>
                  </div>
                )) }
              </div>
            </div>

            <div className="actions">
              <button className="go back" onClick={() => setSection('two')}><i className="fas fa-arrow-left"></i></button>
              <button className="go" onClick={() => setSection('four')}><i className="fas fa-arrow-right"></i></button>
            </div>
          </div>
        </div>

        <div className={`section four ${section === 'four' ? 'active' : ''}`}>
          <div className="body">
            <div className="preview">
              <div className="preview-html" dangerouslySetInnerHTML={{ __html: templateSelected?.html}}></div>
            </div>

            <div className="information">
              <h4><span>Revisão</span> da campanha:</h4>

              <div className="item">
                <h3>Título da campanha:</h3>
                <p>{title}</p>
              </div>

              <div className="item">
                <h3>Template escolhido:</h3>
                <p>{templateSelected?.title}</p>
              </div>
              
              <div className="item">
                <h3>Audiência escolhida:</h3>
                <p>{audienceSelected?.title}</p>
                <span className="people">{audienceSelected?.leadsType === "manual" ? audienceSelected?.leads.length : (audienceSelected.leadsType === "leads" ? leadsLength : clientsLength)} pessoas na lista</span>
              </div>
            </div>
          </div>
          <div className="actions confirm">
            <button className="go back" onClick={() => setSection('three')}><i className="fas fa-arrow-left"></i></button>
            <button className="go send" onClick={handleAdd}><i className="fas fa-arrow-right"></i> Confirmar envio</button>
          </div>
        </div>
      </div>
    </div>
  )
}