import React, { useState } from 'react';
import ButtonSimples from '../Button/Simples';

const PaymentTypes = [
	"Aguardando Pagamento",
	"Pagamento Negado",
	"PIX Agendado",
	"Pagamento Aprovado",
];

const EntregaTypes = [
	"Entrega Agendada",
	"Saiu para Entrega",
	"Pedido Entregue",
	"Pedido Não Entregue",
];

export default function DynamicSelector({ available, records, type, onAdd, onRemove }) {
	const [inputText, setInputText] = useState("");
	const [inputJustify, setInputJustify] = useState("");
	const [onJustify, setOnJustify] = useState(false);

	function onChangeStatus(e) {
		setInputText(e.target.value);
		setOnJustify(true);
	}

	const onChangeJustify = (ev) => setInputJustify(ev.target.value);
	
	function addNewStatus({ status, justifyText }) {
		onAdd({ status, justifyText });
		records.push({ situacao: status, justifyText });

		setInputText("");
		setInputJustify("");
		setOnJustify(false);
	}

	return (
		<div className="status-list">

			{ (records || []).map((item, idx) => (
				<div key={idx} className="status-item">
					<h3>{item.situacao}</h3>
					{ item.justifySituation && (<p className="justify">{item.justify}</p>) }
					{onRemove && (<div>
						<ButtonSimples type="danger" onClick={() => onRemove(idx)} label=" - " />
					</div>)}
				</div>
			)) }

			{ !available && (
				<>
					<div className="selector">
						<select onChange={onChangeStatus}>
							<option>Selecionar</option>
							{ type === "Pagamento" && PaymentTypes.map((item, idx) => <option key={idx} value={item}>{item}</option>) }
							{ type === "Entrega" && EntregaTypes.map((item, idx) => <option key={idx} value={item}>{item}</option>) }
						</select>
						<button onClick={() => addNewStatus({ status: inputText, justifyText: inputJustify })}>Atualizar</button>
					</div>

					{ onJustify && <input type="text" className="input-justify" placeholder="Adicionar Justificativa?" value={inputJustify} onChange={onChangeJustify}/> }
				</>
			) }
		
		</div>
	)
}