export function notify(text, setNotification, type = '', time = 4000){
  if(!text) return null;

  setNotification({ active: true, text, type });
  setTimeout(() => {
    setNotification({ active: false, text: "", type: "" })
  }, time)

  return;
}

export function Notification({ notification }) {
  return (
    <div className={`notification-wrapper${notification.active ? ' active' : ''}${notification.type === 'error' ? ' error' : ''}`}>
      <div className={`notification${notification.active ? ' active' : ''}`}>
        <span>{notification.text}</span>
      </div>
    </div>
  )
}