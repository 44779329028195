import React, { Component } from 'react';

import Voltar from '../../components/Links/Voltar';
import Titulo from '../../components/Texto/Titulo';

import { connect } from 'react-redux';
import * as actions from '../../actions/entregas';

import ButtonToolbar from '../../components/Button/Toolbar';

import CardComponent from '../../components/Card/cardDelivery';

class Entrega extends Component {

	state = { aviso: null }

	componentDidMount(){
		const { usuario } = this.props;
		const { id } = this.props.match.params;
		if(!usuario) return null;
		this.props.getEntrega(id, usuario.loja);
	}

	componentWillUnmount(){
		this.props.limparEntrega();
	}

	cancelarEntrega = () => {
		const { usuario, entrega } = this.props;
		if(!usuario || !entrega) return null;
		if(window.confirm("Você realmente deseja cancelar esta entrega?")){
			this.props.updateEntrega({ status: "Cancelado pela loja", responsavel: usuario.nome }, entrega._id, usuario.loja, (error) => {
				this.setState({
					aviso: { 
						status: !error,
						msg: error ? error.message : "Entrega cancelada com sucesso!" 
					} 
				});
			});
		}
	}

	confirmarEntrega = () => {
		const { usuario, entrega } = this.props;
		if(!usuario || !entrega) return null;
		if(window.confirm("Deseja mesmo confirmar esta entrega? Lembre-se que uma notificação será enviada ao comprador!")){
			this.props.updateEntrega({ status: "Entregue", responsavel: usuario.nome }, entrega._id, usuario.loja, (error) => {
				this.setState({
					aviso: { 
						status: !error,
						msg: error ? error.message : "Entrega confirmada com sucesso!" 
					} 
				});
			});
		}
	}

	render(){
		if(!this.props.entrega) return null;
    const { entrega } = this.props;

		return (
			<div className="content-box entregadetail">
				<div className="content-tools">
					<Voltar history={this.props.history} />
					{ entrega.status !== "Entregue" && (
						<div className="tools">
							<ButtonToolbar type="danger" label="Cancelar Entrega" icon="fas fa-times" onClick={() => this.cancelarEntrega()} />
							<ButtonToolbar type="success" label="Confirmar Entrega" icon="fas fa-check" onClick={() => this.confirmarEntrega()} />
						</div>
					)}
				</div>
				<div className="card">
					<Titulo tipo="h1" titulo={`Entrega #${entrega.simpleId ? entrega.simpleId : entrega._id}`}/>
					<div className="destaque-one">
						<div className="entregaDetails">
							{ entrega.status === "Entregue" ? (
									<div className="status-wrapper">
										<span className="status status-success">{entrega.status}</span>
										{entrega.lastResponsible && (<span className="status-complement">Atualizado por {entrega.lastResponsible}</span>)}
									</div>
								) : (
									<div className="status-wrapper">
										<span className="status status-default">{entrega.status}</span>
									</div>
							) }
							<h4>Data de entrega: {entrega.shippingDate}</h4>
							<h4>Período: {entrega.shippingPeriod}</h4>
							<h4>Quem envia: {entrega.shippingSender}</h4>
							<h4>Quem recebe: {entrega.shippingRecipient}</h4>
						</div>

						{ entrega.shippingMessageSelect ? (<CardComponent dataDelivery={entrega} />) : (<><h1 className="Titulo-primario">Cartão</h1><p>O cliente não adicionou cartão ao pedido.</p></>) }
						
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
  entrega: state.entrega.entrega,
  registros: state.entrega.entregaRegistros,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Entrega);