import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../../utils';
import useDebounce from '../../utils/useDebounce';
import { formatMoney } from '../../actions';
import { api } from '../../config';

const SearchProducts = ({ data, searchAction, cartCallback }) => {

  const [displayValue, setDisplayValue] = useState('');
  const debounceChange = useDebounce(searchAction, 500);

  const [searching, setSearching] = useState(false);
  const [dataSelected, setDataSelected] = useState(false);
  const [items, setItems] = useState([]);
  
  const closeSearch = () => {
    if(dataSelected && items) {
      setSearching(false)
      setDataSelected(false)
      return
    }
    setSearching(false)
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => closeSearch());

  function handleChange(e) {
    !searching && setSearching(true)
    setDisplayValue(e.target.value)
    debounceChange(e.target.value)
    return
  }

  const selectData = async (stateItems, product) => {
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)

    let newState = stateItems.filter(fiItem => fiItem._id !== product._id);
    let _itemexist = stateItems.filter(fiItem => fiItem._id === product._id);
    
    var _product = {
      _id: product._id,
      title: product.titulo,
      price: product.preco,
      picture: product.fotos[0],
      quantity: _itemexist ? _itemexist.length+1 : 1
    }

    let newArray = [...newState, _product]
    if(stateItems.includes(_product)) {
      newArray = stateItems.filter(fiItem => fiItem._id === product._id);
    }
    
    setItems(newArray)
    cartCallback(newArray)
  }

  const removeItem = async (stateItems, productId) => {
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)

    let newState = stateItems.filter(fiItem => fiItem._id !== productId);
    
    setItems(newState)
    cartCallback(newState)
  }

  return (
    <div ref={wrapperRef} className={`inputSearchProducts${ dataSelected ? ' selected' : ''}`} onClick={() => items && setDataSelected(false)}>
      { dataSelected && (<h3>Produtos</h3>) }

      { items && (
        <div className="product-items">
          { items.map((item, idx) => (
            <div className="item" key={idx}>
              <span className="quantity">{item.quantity}</span>
              <div className="image">
                { item.picture && (
                  <img src={`${api}/public/images/${item.picture}`} />
                ) }
              </div>
              <span>{item.title} - {item.price}</span>
              <button className="remove" onClick={() => removeItem(items, item._id)}>
                <span></span>
                <span></span>
              </button>
            </div>
          )) }
        </div>
      ) }

      <input name="products" autoComplete="new-password" placeholder="Adicionar Produto(s)" value={displayValue} onChange={handleChange} onClick={() => !searching && setSearching(true)} />
      
      { (data && searching) && (
        <div className="result-search">
          {/*{console.log("Reset Button", data.docs)}*/}
          { data.docs.length > 0 && data.docs.map((item, idx) => (
            <div key={idx} className="item" onClick={() => selectData(items, item)}>
              {item.titulo} - {formatMoney(item.preco)}
            </div>
          )) }
        </div>
      ) }

    </div>
  )
}

export default SearchProducts;