import React, { useState, useRef } from 'react';
import { formatMoneyOnChange, useOutsideAlerter } from '../../utils';
import useDebounce from '../../utils/useDebounce';

export default function SearchNeighborhood({ data, currentNeighborhoods, searchAction, callbackConfirmAdd }) {
  
  const [displayNeighborhood, setDisplayNeighborhood] = useState('');
  const [displayValue, setDisplayValue] = useState('');
  const debounceChange = useDebounce(searchAction, 500);

  const [searching, setSearching] = useState(false);
  const [dataSelected, setDataSelected] = useState(false);

  const [items, setItems] = useState(currentNeighborhoods);
  
  const closeSearch = () => {
    if(dataSelected && items) {
      setSearching(false)
      setDataSelected(false)
      return
    }
    setSearching(false)
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => closeSearch());

  function handleChange(e) {
    !searching && setSearching(true)
    setDisplayNeighborhood(e.target.value)
    debounceChange(e.target.value)
    return
  }

  function handleChangeValue(e) {
    searching && setSearching(false)
    setDisplayValue(formatMoneyOnChange(e.target.value));
    return
  }

  function addNewNeighborhood({ name, value }) {
    if(!name) return alert("Por favor, preencha corretamente o campo bairro.");
    if(!value) return alert("Por favor, preencha corretamente o campo de valor.");

    if(String(value).slice(0, 2) !== "R$") value = value.toFixed(2);
    const _amountValue = String(value).replace("R$", "").replace(" ", "").replace(".", "").replace(",", "");
    
    const formattedValue = Number(`${_amountValue.slice(-11, -2)}.${_amountValue.slice(-2)}`).toFixed(2);

    let newState = items.filter(fiItem => fiItem.name !== name);
 
    let newArray = [...newState, { name, value: formattedValue }]
    if(items.includes({ name, value: formattedValue })) {
      newArray = items.filter(fiItem => fiItem.name === name);
    }

    setItems(newArray)
    
    callbackConfirmAdd(newArray);
    
    setDisplayNeighborhood(``)
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)
  }

  const removeItem = async (stateItems, neighborhoodName) => {
    setDisplayNeighborhood(``)
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)

    let newState = stateItems.filter(fiItem => fiItem.name !== neighborhoodName);
    
    setItems(newState)
    callbackConfirmAdd(newState);
  }

  return (
    <div ref={wrapperRef} className={`inputSearchProducts${ dataSelected ? ' selected' : ''}`} onClick={() => items && setDataSelected(false)}>

      { items && (
        <div className="categories-items">
          { items.map((item, index) => (
            <div className="item" key={index}>
              <span>{item.name} <span className="value">{ `R$ ${Number(item.value).toFixed(2).split(".").join(",")}` }</span></span>
              <button className="remove" onClick={() => removeItem(items, item.name)}>
                <span></span>
                <span></span>
              </button>
            </div>
          )) }
        </div>
      ) }

      <div className="inputNeighborhoodWithValue">
        <input name="neighborhoods" autoComplete="new-password" placeholder="Adicionar Novo" value={displayNeighborhood} onChange={handleChange} />
        <input name="neighborhoods" autoComplete="new-value" placeholder="Valor" value={displayValue} onChange={handleChangeValue} />
        <button onClick={() => addNewNeighborhood({ name: displayNeighborhood, value: displayValue })}>Confirmar</button>
      </div>
      
      { (data && data.length >= 1 && searching) && (
        <div className="result-search">
          { (data).map((item, index) => (
            <div key={index} className="item" onClick={() => {setDisplayNeighborhood(item.name); setSearching(false)}}>
              { item.name } 
            </div>
          )) }
        </div>
      ) }

    </div>
  )
}