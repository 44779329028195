import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../../utils';
import useDebounce from '../../utils/useDebounce';

const SearchCategories = ({ data, currentCategories, searchAction, callback }) => {
  
  const [displayValue, setDisplayValue] = useState('');
  const debounceChange = useDebounce(searchAction, 500);

  const [searching, setSearching] = useState(false);
  const [dataSelected, setDataSelected] = useState(false);

  const [items, setItems] = useState(currentCategories);
  
  const closeSearch = () => {
    if(dataSelected && items) {
      setSearching(false)
      setDataSelected(false)
      return
    }
    setSearching(false)
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => closeSearch());

  function handleChange(e) {
    !searching && setSearching(true)
    setDisplayValue(e.target.value)
    debounceChange(e.target.value)
    return
  }

  const selectData = async (stateItems, category) => {
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)

    let newState = stateItems.filter(fiItem => fiItem._id !== category._id);
    
    var _product = {
      _id: category._id,
      title: category.nome
    }
 
    let newArray = [...newState, _product]
    if(stateItems.includes(_product)) {
      newArray = stateItems.filter(fiItem => fiItem._id === category._id);
    }
    
    setItems(newArray)
    callback(newArray)
  }

  const removeItem = async (stateItems, productId) => {
    setDisplayValue(``)
    setDataSelected(true)
    setSearching(false)

    let newState = stateItems.filter(fiItem => fiItem._id !== productId);
    
    setItems(newState)
    callback(newState)
  }

  return (
    <div ref={wrapperRef} className={`inputSearchProducts${ dataSelected ? ' selected' : ''}`} onClick={() => items && setDataSelected(false)}>
      <strong className="title">Categorias</strong>

      { items && (
        <div className="categories-items">
          { items.map((item, index) => (
            <div className="item" key={index}>
              <span>{item.title || item.nome}</span>
              <button className="remove" onClick={() => removeItem(items, item._id)}>
                <span></span>
                <span></span>
              </button>
            </div>
          )) }
        </div>
      ) }

      <input name="categories" autoComplete="new-password" placeholder="Adicionar Categoria(s)" value={displayValue} onChange={handleChange} onClick={() => !searching && setSearching(true)} />
      
      { (data && searching) && (
        <div className="result-search">
          { (data || []).map((item, index) => (
            <div key={index} className="item" onClick={() => selectData(items, item)}>
              { item.nome }
            </div>
          )) }
        </div>
      ) }

    </div>
  )
}

export default SearchCategories;