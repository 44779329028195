import { useRef, useState, useEffect } from 'react'
import EmailEditor from 'react-email-editor';
import { Notification, notify } from '../../utils/notification';
import EMarketingDefaultTemplate from './default_template.json'

export default function EmailEditorHtml({ active, templateSelected, setTemplateSelected, closeEditor, admin, saveTemplate }) {

  const emailEditorRef = useRef(null);
  
  const [title, setTitle] = useState(templateSelected.title)
  const [titleWidth, setTitleWidth] = useState(100);
  const [titleChanging, setTitleChanging] = useState(false);

  const [save, setSave] = useState(true)
  
  const titleSpan = useRef();
  const inputTitle = useRef();

  const [notification, setNotification] = useState({ active: false, text: "" });

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      saveTemplate({ _id: templateSelected._id || undefined, title, design, html }, admin.loja, (template) => {
        if(template._id) {
          setSave(true)
          notify("Template salvo com sucesso!", setNotification);
          return setTemplateSelected({ _id: template._id, title: template.title, design: template.design })
        }
      });
      return save;
    });

    
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);

  }
  
  const onReady = () => {
    emailEditorRef?.current?.editor?.addEventListener('design:updated', function(updates) {
      return setSave(false)
    })
  };
  
  useEffect(() => {
    if(templateSelected.design === undefined){
      emailEditorRef.current.editor && emailEditorRef.current.editor.loadDesign(EMarketingDefaultTemplate);
      return setTitle("Template - Sem Título")
    };
    emailEditorRef.current.editor.loadDesign(templateSelected.design)
    setTitle(templateSelected.title)
  }, [templateSelected])

  function handleClose() {
    if(save) return closeEditor();

    if(window.confirm("Deseja salvar este template antes de sair? \n\nSe você clicar em cancelar, poderá perder as alterações.\n")){
      exportHtml()
    }

    setSave(true)
    closeEditor()
    return;
  }

  return (
    <div className={`EmailEditor${active ? ' active' : ''}`}>
      <div className="content">
        <Notification notification={notification} />
        <div className="head">
          <div className="title">
            <span className={!titleChanging && "visible"} ref={titleSpan} onClick={() => { setTitleChanging(true); inputTitle.current.focus() } }>{title} <i className="fas fa-pencil-alt"></i></span>
            <div className="input-edit">
              <input ref={inputTitle} className={titleChanging && "visible"} maxLength={30} type="text" style={{ width: titleWidth }} autoFocus spellCheck="false" value={title} onChange={(e) => { setTitle(e.target.value); setTitleWidth(titleSpan.current.offsetWidth); }} />
              { titleChanging && <button onClick={() => setTitleChanging(false)}>Salvar</button> }
            </div>
          </div>
          <div className={`actions${notification.active ? ' disabled' : ''}`}>
            <button className="save" onClick={exportHtml}><i className="fas fa-save"></i> <span>Salvar Template</span></button>
            <button className="exit" onClick={handleClose}><i className="fas fa-times"></i></button>
          </div>
        </div>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </div>
    </div>
  )
}