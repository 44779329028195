import React, { useState } from 'react';

const AlertGeral = ({ aviso, time }) => {
	const [alert, setAlert] = useState(true);
	
	if(!aviso) return null;
	const { status, msg } = aviso;
	
	const timer = setTimeout(() => {
		clearTimeout(timer);
		setAlert(false);
	}, time ? Number(time) : 5000)
	return (
		alert && (
			<div className={`alert ${aviso ? ` active` : ''} alert-${ status ? "success" : "danger" }`}>
				<span>{msg}</span>
			</div>
		)
	)
}

export default AlertGeral;