import {
	GET_UPSELLS,
	GET_UPSELL,
	GET_UPSELLPRODUTOS,
	LIMPAR_UPSELL,
	LIMPAR_UPSELLS
} from '../actions/types';

export default (state = {}, action) => {
	switch(action.type){
			case GET_UPSELLS:
					return {
							...state,
							upsells: action.payload.upsells
					}
			case GET_UPSELL:
					return {
							...state,
							upsell: action.payload.upsell
					}
			case GET_UPSELLPRODUTOS:
					return {
							...state,
							upsellProdutos: action.payload.produtos
					}
			case LIMPAR_UPSELL:
					return {
							...state,
							upsell: null
					}
			case LIMPAR_UPSELLS:
					return {
							...state,
							upsells: null
					}
			default:
					return state;
	}
}