import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import { GET_TEMPLATES, GET_CLIENTS_AUDIENCE, GET_LEADS_AUDIENCE, GET_AUDIENCES, GET_CAMPAIGNS } from './types';

export const listTemplates = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/email-marketing/templates?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_TEMPLATES, payload: response.data })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const saveTemplate = ({ _id, title, design, html }, loja, cb) => {
	return function(){
		axios.put(`${api}/${versao}/api/email-marketing/templates/save?loja=${loja}`, { _id, title, design, html }, getHeaders())
		.then(response => cb(response.data.template))
		.catch(errorHandling);
	}
}

export const removeTemplate = (templateId, loja, cb) => {
	return function(){
		axios.delete(`${api}/${versao}/api/email-marketing/templates/${templateId}?loja=${loja}`, getHeaders())
		.then(response => {
			return cb({ success: true })
		})
		.catch(error => {
			return cb({ success: false })
		})
	}
}

export const listClientsAudience = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/clientes/all?loja=${loja}`, getHeaders())
		.then(async response => {
			var clients = [];
			await response.data?.clientes?.forEach(client => {
				if(!client?.email || clients?.filter(_client => _client.email === client?.email)[0]) return null;
				clients.push(client)
			})

			dispatch({ type: GET_CLIENTS_AUDIENCE, payload: { clientes: clients } })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const listLeadsAudience = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/leads/all?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_LEADS_AUDIENCE, payload: response.data })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const saveAudience = ({ _id, title, leads, leadsType }, loja, cb) => {
	return function(){
		axios.put(`${api}/${versao}/api/email-marketing/audiences/save?loja=${loja}`, { _id, title, leads, leadsType }, getHeaders())
		.then(response => cb(response.data.audience))
		.catch(errorHandling);
	}
}

export const listAudiences = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/email-marketing/audiences?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_AUDIENCES, payload: response.data })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}

export const removeAudience = (_id, loja, cb) => {
	return function(){
		axios.delete(`${api}/${versao}/api/email-marketing/audience/${_id}?loja=${loja}`, getHeaders())
		.then(response => {
			return cb({ success: true })
		})
		.catch(error => {
			return cb({ success: false })
		})
	}
}

export const createCampaign = ({ title, template, audience }, loja, cb) => {
	return function(){
		axios.post(`${api}/${versao}/api/email-marketing/campaigns?loja=${loja}`, { title, template, audience }, getHeaders())
		.then(response => {
			return cb({ success: true })
		})
		.catch(error => {
			return cb({ success: false })
		})
	}
}

export const listCampaigns = (loja, cb) => {
	return function(dispatch){
		axios.get(`${api}/${versao}/api/email-marketing/campaigns?loja=${loja}`, getHeaders())
		.then(response => {
			dispatch({ type: GET_CAMPAIGNS, payload: response.data })
			return cb(response.data)
		})
		.catch(errorHandling);
	}
}