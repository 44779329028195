import { useState } from 'react'

import { connect } from 'react-redux'
import * as actions from '../../actions/reports'

import PaymentsReports from './payments';
import ProductsReports from './products';
import { verifyAdmin } from '../../utils/verify-admin';
import ReOfferReport from "./reoffer";

function Reports({ admin, paymentReports, productsReports, history, getReportPayment, getReportProducts,
                   clearReports, generatePdfProductsReport, reOfferReports, getReportReOfferAction }){

  const [loadingPayments, setLoadingPayments] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingReOffer, setLoadingReOffer] = useState(false);

  function handleFindPayments({ initial, final }) {
    setLoadingPayments(true)
    getReportPayment({ initial, final }, admin.loja, () => setLoadingPayments(false));
  }

  function handleFindProducts({ initial, final }) {
    setLoadingProducts(true)
    getReportProducts({ initial, final }, admin.loja, () => setLoadingProducts(false));
  }

  function handleFindReOffers({ initial, final }) {
    setLoadingReOffer(true);
      getReportReOfferAction({initial, final}, admin.loja, () => setLoadingReOffer(false));
  }

  return (
    <div className="Reports container-centered">
      <div className="wrapper">
        <h2><i className="fas fa-file-contract"></i> Relatórios</h2>

        { (admin && verifyAdmin(admin, 'owner')) && (
          <PaymentsReports loading={loadingPayments} reports={paymentReports} handleFind={handleFindPayments} clearReports={clearReports} />
        )}
        <ProductsReports loading={loadingProducts} reports={productsReports} handleFind={handleFindProducts} clearReports={clearReports} generatePdfProductsReport={generatePdfProductsReport} />
        {/*<ReOfferReport loading={loadingReOffer} reports={reOfferReports} handleFindOf={handleFindReOffers} clearReports={clearReports} />*/}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  paymentReports: state.reports.payments,
  productsReports: state.reports.products,
  reOfferReports: state.reports.reOfferReducer,
  admin: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Reports);