import { useState } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../actions/configuracoes';
import { verifyAdmin } from '../../utils/verify-admin';

import ConfigStore from './store';
import ConfigNeighborhood from './neighborhood';
import ConfigDates from './dates';
import Discount from './discount';

function Configuration({ admin, history }){
  const [section, setSection] = useState("general");

  if(!admin || (admin && !verifyAdmin(admin, 'owner'))) {
    return null;
  }

  return (
    <div className="Configs container-centered">
      <div className="head">
        <h2><i className="fas fa-cogs"></i> Configurações da Loja</h2>
        <div className="menu">
          <button className={section === "general" ? 'active' : ''} onClick={() => setSection("general")}>Configurações Gerais</button>
          <button className={section === "delivery" ? 'active' : ''} onClick={() => setSection("delivery")}>Entrega</button>
          <button className={section === "discount" ? 'active' : ''} onClick={() => setSection("discount")}>Cupons de Desconto</button>
        </div>
      </div>

      <div className="wrapper">        
        {section === "general" && <ConfigStore /> }
        {section === "delivery" && <ConfigNeighborhood /> }
        {section === "delivery" && <ConfigDates /> }
        {section === "discount" && <Discount /> }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
	loja: state.configuracao.loja,
	admin: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Configuration);