import React,{ Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Pesquisa from '../../components/Inputs/Pesquisa';
import Paginacao from '../../components/Paginacao/Simples';
import { Link } from 'react-router-dom';

import * as actions from '../../actions/upsells';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import ProductTable from '../../components/Table/ProductTable';

class Upsells extends Component {

    state = {
        pesquisa: "",
        atual: 0,
        limit: 30,
        ordem: "decrescente",
        refreshing: false
    }

    getUpsells(props){
        const { atual, limit, pesquisa, ordem } = this.state;

        if(!this.props.usuario) return null;

        if(pesquisa) props.getUpsellsPesquisa(pesquisa, ordem, atual, limit, this.props.usuario.loja);
        else props.getUpsells(ordem, atual, limit, this.props.usuario.loja);
    }

    refreshList = () => {
        const { atual, limit, pesquisa, ordem } = this.state;

		this.setState({ refreshing: true })
		
        if(pesquisa) this.props.getUpsellsPesquisa(pesquisa, ordem, atual, limit, this.props.usuario.loja);
        else this.props.getUpsells(ordem, atual, limit, this.props.usuario.loja);

		const timer = setTimeout(() => {
			this.setState({ refreshing: false })
			clearTimeout(timer)
		}, 1800);
	}

    componentDidMount(){
        this.getUpsells(this.props);
        this.props.limparUpsell();
    }
    componentDidUpdate(prevProps){
        if(!prevProps.usuario && this.props.usuario) this.getUpsells(this.props);
    }

    handleSubmitPesquisa(){
        this.setState({ atual: 0 }, () => this.getUpsells(this.props));
    }

    onChangePesquisa = (ev) => this.setState({ pesquisa: ev.target.value })

    changeNumeroAtual = (atual) => this.setState({ atual }, () => this.getUpsells(this.props));

    changeOrdem = (ev) => this.setState({ ordem: ev.target.value }, () => this.getUpsells(this.props));

    renderBotaoNovo = () => {
        return (
            <Link className="btn btn-primary" to="/upsells/novo">
                <i className="fas fa-plus"></i><span>&nbsp;Adicionar novo</span>
            </Link>
        )
    }

    renderToggleAvailable(upsell) {
        return (
            <div className={`toggle ${upsell?.disponibilidade ? 'true' : 'false'}`} onClick={() => this.props.updateUpsell({ ...upsell, disponibilidade: !upsell?.disponibilidade }, upsell._id, this.props.usuario.loja, () => this.refreshList())}>
                <div className={`toggle-selector ${upsell?.disponibilidade ? 'true' : 'false'}`}></div>
            </div>
        )
    }

    render(){
        const { pesquisa, ordem, refreshing } = this.state;
        const { upsells } = this.props;
        
        const dados = [];
        upsells && upsells.docs && upsells.docs.forEach((item) => {
            dados.push({
                "Ações": `/upsell/${item._id}`,
                "Upsell": item.titulo,
                "Disponibilidade": this.renderToggleAvailable(item),
            });
        });

        return upsells ? (
            <div className="Upsells container-centered">
                <div className="Page-Title">
                    <Titulo tipo="h1" titulo="Upsells"/>
                </div>

                <div className="actionsTollBar">
                    <Link to="/newupsell">
                        <div className="btnIcon plus" style={{ marginLeft: 0 }}></div>
                    </Link>
                    <Pesquisa valor={pesquisa}
                        placeholder={"Pesquisar Upsell"}
                        onChange={(ev) => this.onChangePesquisa(ev)}
                        onClick={() => this.handleSubmitPesquisa()}/>
                </div>

                <ProductTable cabecalho={["Upsell", "Disponibilidade"]} gridClass="Upsells" refreshState={''} dados={dados} refreshList={() => this.refreshList()} />

                <Paginacao atual={this.state.atual} total={this.props.upsells?.total || 1} 
                    limite={this.state.limit} onClick={(numeroAtual) => this.changeNumeroAtual(numeroAtual)} />        
            </div>
        ) : <Loading />;
    }
}

const mapStateToProps = state => ({
    upsells: state.upsell.upsells,
    usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Upsells);