import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Voltar from '../../components/Links/Voltar';
import { TextoDados } from '../../components/Texto/Dados';
import InputValor from '../../components/Inputs/Valor';
import InputSimples from '../../components/Inputs/Simples';
import AlertGeral from '../../components/Alert/Geral';
import { connect } from 'react-redux';
import * as actions from '../../actions';

var timer;

class Perfil extends Component {

	constructor(props){
		super();
		this.state = {
			nome: props.usuario ? props.usuario.nome : "",
			email: props.usuario ? props.usuario.email : "",
	
			senhaAntiga: "",
			novaSenha: "",
			confirmarNovaSenha: "",

			aviso: null,
			erros: {}
		}
	}

	componentDidMount(){
		this.props.getUser();
	}
	componentWillUpdate(prevProps){
		if(this.props.usuario && prevProps.usuario &&	this.props.usuario.updatedAt !== prevProps.usuario.updatedAt){
			this.setState({
				nome: this.props.usuario ? this.props.usuario.nome : "",
				email: this.props.usuario ? this.props.usuario.email : "",
			});
		}
	}

	onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate());

	validate(){
		const { nome, email, senhaAntiga, novaSenha, confirmarNovaSenha } = this.state;
		const erros = {};

		if(!nome) erros.nome = "Preencha aqui com o nome";
		if(!email) erros.email = "Preencha aqui com o e-mail";

		if(senhaAntiga || novaSenha || confirmarNovaSenha){
			if(!senhaAntiga) erros.senhaAntiga = "Preencha aqui com a senha antiga";
			if(!novaSenha) erros.novaSenha = "Preencha aqui com a nova senha";
			if(!confirmarNovaSenha) erros.confirmarNovaSenha = "Confirme a nova senha";
			if(novaSenha !== confirmarNovaSenha) erros.confirmarNovaSenha = "As senhas não coincidem";
		}

		this.setState({ erros });
		return !(Object.keys(erros).length > 0);
	}

	updateUsuario(){
		if(!this.validate()) return null;
		const { nome, email, novaSenha, senhaAntiga } = this.state;

		const dados = {};
		dados.nome = nome;
		dados.email = email;
		if(novaSenha){
			dados.password = novaSenha;
			dados.oldPassword = senhaAntiga;
		}

		clearTimeout(timer)
		
		timer = setTimeout(() => {
			this.props.updateUser(dados, (error) => {
				if(!error) this.setState({ senhaAntiga: "", novaSenha: "", confirmarNovaSenha: "" });
				this.setState({
					aviso: {
						status: !error,
						msg: error ? error.message : "Dados atualizados com sucesso"
					}
				});
			})
			clearTimeout(timer)
		}, 500)

		return
	}

	renderDadosConfiguracao(){
		const { nome, email, erros } = this.state;
		return (
			<div className="item">
				<TextoDados chave="Nome" valor={
					<InputValor noStyle value={nome} name="nome" erro={erros.nome} handleSubmit={(valor) => this.onChangeInput( "nome", valor )} />
				} />
				<TextoDados chave="E-mail" valor={
					<InputValor noStyle value={email} name="email" erro={erros.email} handleSubmit={(valor) => this.onChangeInput( "email", valor )} />
				} />
			</div>
		)
	}

	renderDadosSenha(){
		const { senhaAntiga, novaSenha, confirmarNovaSenha, erros } = this.state;
		return (
			<div className="item">
				<InputSimples type="password" label="Senha Antiga:" name="senha-antiga" value={senhaAntiga} error={erros.senhaAntiga} onChange={(ev) => this.onChangeInput( "senhaAntiga", ev.target.value )} />
				<InputSimples type="password" label="Nova Senha:" name="nova-senha" value={novaSenha} error={erros.novaSenha} onChange={(ev) => this.onChangeInput( "novaSenha", ev.target.value )} />
				<InputSimples type="password" label="Confirmar Nova Senha:" name="confirmar-nova-senha" value={confirmarNovaSenha} error={erros.confirmarNovaSenha} onChange={(ev) => this.onChangeInput( "confirmarNovaSenha", ev.target.value )} />
				<div className="ButtonSubmit" onClick={() => this.updateUsuario()}>
					<button>Salvar Dados</button>
				</div>
			</div>
		)
	}

	render(){
		return (
			<div className="container-centered">
				<div className="Page-Title">
					<Titulo tipo="h1" titulo="Perfil"/>
					<AlertGeral aviso={this.state.aviso} />
				</div>
				{ this.renderDadosConfiguracao() }
				{ this.renderDadosSenha() }
			</div>
		)
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Perfil);