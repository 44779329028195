export default function Modal({ text, active, close, confirm }) {
  return (
    <div className={`modalOfficial${active ? ' active' : ''}`}>
      <div className="content min">
        <div className="deleting">
          <h2>{text}</h2>

          <div className="actions">
            <button className="cancel" onClick={close}>Cancelar</button>
            <button className="accept" onClick={confirm}>Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  )
}