import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import ButtonSimples from '../../components/Button/Simples';
import Voltar from '../../components/Links/Voltar';
import AlertGeral from '../../components/Alert/Geral';
import { connect } from 'react-redux';
import * as actions from '../../actions/avaliacoes';

class Avaliacao extends Component {

	state = { aviso: null };

	getAvaliacao(props){
		const { usuario, produto } = props;
		if(!usuario || !produto) return;
		const { id: avaliacao } = props.match.params;
		this.props.getAvaliacao(avaliacao, produto._id, usuario.loja);
	}

	componentDidMount(){
		this.getAvaliacao(this.props);
	}
	componentDidUpdate(prevProps){
		if((!prevProps.usuario || !prevProps.produto) && this.props.usuario && this.props.produto)
		this.getAvaliacao(this.props);
	}
	componentWillUnmount(){
		this.props.limparAvaliacao();
	}

	aprovarAvaliacao(){
		const { usuario, produto, avaliacao } = this.props;
		if(!usuario || !produto || !avaliacao) return null;
		if(window.confirm("Você realmente aprovar esta avaliação?")){
			let status = 2;
			this.props.aprovarAvaliacao(avaliacao._id, produto._id, usuario.loja, status, (error) => {
				if(error) return this.setState({ aviso: { state: false, msg: error.message } });
				else this.props.history.goBack();
			});
		}
	}

	removeAvaliacao(){
		const { usuario, produto, avaliacao } = this.props;
		if(!usuario || !produto || !avaliacao) return null;
		if(window.confirm("Você realmente deseja remover essa avaliação?")){
			this.props.removeAvaliacao(avaliacao._id, produto._id, usuario.loja, (error) => {
				if(error) return this.setState({ aviso: { state: false, msg: error.message } });
				else this.props.history.goBack();
			});
		}
	}

	renderCabecalho(){
		const { avaliacao, produto } = this.props;
		return (
			<div className="flex">
				<div className="flex flex-1 vertical">
					<Titulo tipo="h3" titulo={`Avaliação - ${produto ? produto.titulo : ""} - ${avaliacao ? avaliacao.pontuacao : ""} estrelas`}/>
					<Titulo tipo="h4" titulo={`Cliente: ${avaliacao ? avaliacao.nome : ""}`}/>
				</div>
				<div className="flex flex-1 flex-end">
					<ButtonSimples type="success" label="Aprovar" onClick={() => this.aprovarAvaliacao()}/>
					<ButtonSimples type="danger" label="Remover" onClick={() => this.removeAvaliacao()}/>
				</div>
			</div>
		)
	}

	renderMensagem(){
		const { avaliacao } = this.props;
		return (
			<div className="Mensagem">{ avaliacao ? avaliacao.texto : "..."}</div>
		)
	}

	render(){
		return (
			<div className="Avaliacao">
				<Voltar history={this.props.history} />
				<AlertGeral aviso={this.state.aviso} />
				{ this.renderCabecalho() }
				{ this.renderMensagem() }
			</div>
		)
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	produto: state.produto.produto,
	avaliacao: state.avaliacao.avaliacao
});

export default connect(mapStateToProps, actions)(Avaliacao);