import React from 'react';
import Titulo from '../Texto/Titulo';
import { api } from '../../config';
import { FaTimes} from 'react-icons/fa';

class BlocoImagens extends React.Component {
	render(){
		const { handleSubmit, imagens, onRemove } = this.props;
		return (
			<div className="imagesGrid">
				<Titulo tipo="h3" titulo="Imagens" />
				<div className="head-action">
					<input type="file" onChange={handleSubmit} />
				</div>
				<div className="images-container">
					{ imagens.map((src, idx) => {
						let ext = src.substring(src.lastIndexOf('.') + 1, src.length).toLowerCase();
						if (ext === 'webm' || ext === 'mp4' || ext === 'mov') {
							return <div key={idx} className="image"><a href={api+'/public/images/'+src} target={"_blank"}> Video</a>
								<div className="remove" onClick={() => onRemove(idx)}><FaTimes/></div>
							</div>
						}else {
							return <div key={idx} className="image"
										style={{backgroundImage: `url("${api}/public/images/${src}")`}}>
								<div className="remove" onClick={() => onRemove(idx)}><FaTimes/></div>
							</div>
						}
					}) }
				</div>
			</div>
		)
	}
}

export default BlocoImagens;