import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ButtonToolbar from '../Button/Toolbar';
import CardPrint from '../Card/CardPrint';
import OrderPrint from '../Card/OrderPrint';
import { connect } from 'react-redux'
import * as actions from '../../actions/entregas';
import AlertGeral from '../../components/Alert/Geral';

const Table = ({ cabecalho, dados, refreshState, refreshList, gridClass, admin, updateEntrega }) => {

  const [itemActive, setItemActive] = useState();

  const [printing, setPrinting] = useState({ status: false, type: "" });

  const cancelDelivery = (entrega) => {
		if(!entrega || !admin) return null;
		if(window.confirm("Você realmente deseja cancelar esta entrega?")){
			updateEntrega({ status: "Cancelado pela loja", responsavel: admin.nome }, entrega._id, admin.loja, (error) => {
				refreshList();
        setItemActive();
			});
		}
	}

	const confirmDelivery = (entrega) => {
		if(!entrega || !admin) return null;
		if(window.confirm("Deseja mesmo confirmar esta entrega? Lembre-se que uma notificação será enviada ao comprador!")){
			updateEntrega({ status: "Pedido Entregue", responsavel: admin.nome }, entrega._id, admin.loja, (error) => {
				refreshList();
        setItemActive();
			});
		}
	}

  return (
    <div className={`Table DynamicTable${gridClass ? ` ${gridClass}` : ''}${refreshState ? ' refreshing' : ''}`}>

      <div className="head">
        { cabecalho.map((item, idx) => (item !== "Seen" && <div key={idx}>{item}</div> )) }
        { refreshList && (
          <button className="refreshButton" onClick={refreshList}>
            <i className="fas fa-redo"></i>
          </button>
        ) }
      </div>

      <div className="body">
        { dados.map((linha, idx) => (
            <div className={`itemDelivery${itemActive === idx ? ' active' : ''}`} key={idx}>
              <div className={`item${linha["Seen"] === 1 ? ' novo' : ''}`} onClick={() => setItemActive(itemActive === idx ? '' : idx)}>
                <div>
                  { (linha["Seen"] === 1 && !linha["manual"]) && <span className="newMessage">Novo</span> }
                  { (linha["manual"]) && <span className="newMessage">Manual</span> }
                  { (linha["ID"]) && <span>{linha["ID"]}</span> }
                </div>
                { cabecalho.map((item, index) => item !== "ID" && item !== "Seen" && <span key={index}>{linha[item] || ""}</span>) }
              </div>
              { itemActive === idx && (
                <div className="content">
                  <div className="details">
                    <div className="item_info">
                      <span className="title">Quem envia:</span>
                      <span className="text">{linha.entrega.shippingSender}</span>
                    </div>
                    <div className="item_info">
                      <span className="title">Quem recebe:</span>
                      <span className="text">{linha.entrega.shippingRecipient}</span>
                    </div>
                  </div>

                  <div className="buttonactions">
                    { printing.status ? printing.type === "order" ? (<OrderPrint data={linha.entrega} onClick={() => setPrinting({ status: false, type: "" })} />) :
                      <CardPrint data={linha.entrega} onClick={() => setPrinting({ status: false, type: "" })} />
                    : ( 
                    <>
                      <Link to={`/pedido/${linha.entrega.pedido._id}`}>
                        <ButtonToolbar type="default max" label="Ver Pedido" icon="fas fa-eye" />
                      </Link>
  
                      <ButtonToolbar type="danger" label="Cancelar Entrega" icon="fas fa-times" onClick={() => cancelDelivery(linha.entrega)} />
                      <ButtonToolbar type="success" label="Confirmar Entrega" icon="fas fa-check" onClick={() => confirmDelivery(linha.entrega)} />
                      <ButtonToolbar type="blue" label="Imprimir Pedido" icon="fa fa-print" onClick={() => setPrinting({ status: true, type: "order" })} />
                      <ButtonToolbar type="orange" label="Imprimir Cartão" icon="fa fa-envelope" onClick={() => setPrinting({ status: true, type: "delivery" })} />
                    </> ) }
                  </div>
                </div>
              )}
            </div>
        )) }
      </div>
    </div>
  )
};

export default connect(null, actions)(Table);