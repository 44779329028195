import { getHeaders } from './localStorage';
import axios from 'axios';
import { api, versao } from '../config';
import errorHandling from './errorHandling';
import { GET_REPORT_PAYMENT, GET_REPORT_PRODUCTS, CLEAR_REPORTS, REPORTS_SHIPPING, GET_REPORT_RE_OFFER_ACTION } from './types';

export const getReportPayment = ({ initial, final }, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/reports/payments?loja=${loja}`, { initial, final }, getHeaders())
		.then(response => {
			dispatch({ type: GET_REPORT_PAYMENT, payload: response.data });
			cb();
			console.log('res-->>',response.data);
		})
		.catch(errorHandling);
	};
};

export const getReportProducts = ({ initial, final }, loja, cb) => {
	return function(dispatch){
		axios.put(`${api}/${versao}/api/reports/products?loja=${loja}`, { initial, final }, getHeaders())
		.then(response => {
			dispatch({ type: GET_REPORT_PRODUCTS, payload: response.data });
			cb();
		})
		.catch(errorHandling);
	};
};

export const generatePdfProductsReport = ({ initial, final }, loja, cb) => {
	return function(){
		axios.put(`${api}/${versao}/api/reports/products/pdf?loja=${loja}`, { initial, final }, getHeaders())
		.then(response => {
			cb(response.data);
		})
		.catch(errorHandling);
	};
};

export const getReportReOfferAction = ({ initial, final }, loja, cb) => {
	console.log(getHeaders());
	return function(dispatch){
		axios.post(`${api}/${versao}/api/reports/reOffer?loja=${loja}`, { initial, final }, getHeaders())
			.then(response => {
				console.log(response.data);
				dispatch({ type: GET_REPORT_RE_OFFER_ACTION, payload: response.data });
				cb();
				// console.log('res-->>',response.data.reOfferRows[0]);
			})
			.catch(errorHandling);
	};
};

export const clearReports = () => {
	return function(dispatch){
		dispatch({ type: CLEAR_REPORTS });
	};
};