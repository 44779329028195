import React from 'react';
import { Link } from 'react-router-dom';

export default ({ path, history, historyPath }) => {

    const goBackWithState = () => {
        if(historyPath) {
            return history.push({ pathname: historyPath, state: { currentPage: history.location.state ? history.location.state.currentPage : null } })
        } else {
            return history.goBack();
        }
    }

    if(path) return (<Link className="btntool btntool-default" to={path}><i className='fa fa-arrow-left'></i></Link>);
    else return (<span className="btntool btntool-default" onClick={() => goBackWithState()}><i className='fa fa-arrow-left'></i></span>);
};