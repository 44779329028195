import React, { Component } from 'react';
import Titulo from '../../components/Texto/Titulo';
import Button from '../../components/Button/Simples';
import InputSimples from '../../components/Inputs/Simples';
import Voltar from '../../components/Links/Voltar';

import { connect } from 'react-redux';
import * as actions from '../../actions/upsells';
import AlertGeral from '../../components/Alert/Geral';

import SingleImage from '../../components/Imagens/Single';
import { formatMoneyOnChange } from '../../utils';
import { formatMoney } from '../../actions';

class NovoUpsell extends Component {

  state = {
    titulo: "",
    preco: "",
    aviso: null,
    fotos: "",
    erros: {},
    imageTemp: null
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState && prevState.imageTemp !== this.state.imageTemp){
      console.log(this.state.imageTemp)
    }
  }

  handleImageUpload = (ev) => {
    const data = new FormData();
    data.append("files", ev.target.files[0]);

    return this.setState({ imageTemp: URL.createObjectURL(ev.target.files[0]), fotos: data });
    }

  validate(){
      const { titulo, preco } = this.state;
      const erros = {};

      if(!titulo) erros.titulo = "Preencha aqui com o titulo";
      if(!preco) erros.preco = "Preencha aqui com o preço";

      this.setState({ erros });
      return !( Object.keys(erros).length > 0 );
  }

  handleAdd(){
      if(!this.validate()) return null;
      this.props.criarUpsell(this.state, this.props.usuario?.loja, (error, upsell) => {
          this.setState({ aviso: { status: !error, msg: error ? error.message : "Upsell criado com sucesso" } });
          
          if(!error) {
            this.props.updateUpsellImage(this.state.fotos, upsell._id, upsell.loja, () => {
                window.scrollTo(0, 0);
                this.props.history.push("/upsells")
            })
          }
      });
  }

  onChangeInput = (field, value) => this.setState({ [field]: value }, () => this.validate())

  renderDados(){
      const { titulo, descricao, categoria, preco, promocao, erros } = this.state;
      return (
          <div className="novoUpsell">
              <SingleImage image={this.state.imageTemp} temp={true} handleSubmit={this.handleImageUpload} />
              <div className="fields">
                <InputSimples
                    label="Titulo"
                    value={titulo}
                    error={erros.titulo}
                    onChange={(ev) => this.onChangeInput("titulo", ev.target.value)} />
                <InputSimples label="Preço" value={preco} error={erros.preco} onChange={(ev) => this.onChangeInput("preco", formatMoneyOnChange(ev.target.value))} />
              </div>
          </div>
      )
  }

  render(){
      return (
          <div className="container-centered">
            <div className="head">
                <Voltar history={this.props.history} />
                <AlertGeral aviso={this.state.aviso} />
            </div>
            <div className="Page-Title">
                <Titulo tipo="h1" titulo="Novo Upsell" />
            </div>
            { this.renderDados() }
            <div className="ButtonSubmit">
                <button onClick={() => this.handleAdd()}>Adicionar</button>
            </div>
          </div>
      )
  }
}

const mapStateToProps = state => ({
    produto: state.produto.produto,
    categorias: state.categoria.categorias,
    usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(NovoUpsell);