import { useEffect, useState } from "react";
import SearchNeighborhood from "../../components/Search/Neighborhood";
import Titulo from "../../components/Texto/Titulo";

import { connect } from 'react-redux';
import * as actions from '../../actions/configuracoes';
import axios from "axios";

const onPostalCode = (value) => {
  return new Promise((resolve, reject) => {
    axios.get(`https://viacep.com.br/ws/RJ/Rio%20de%20Janeiro/${value}/json`)
    .then((response) => resolve(response.data))
    .catch(e => reject(console.log(e)));
  })
}

function ConfigNeighborhood({ loja, updateNeighborhoods }) {
  const [neighborhoods, setNeighborhoods] = useState([]);

  const searchNeighborhoods = async (search) => {
    const _neighborhoods = await onPostalCode(search)
		const _searchFormatted = search.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

		const _search = _neighborhoods.filter(fiItem => fiItem.bairro.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(_searchFormatted))
    if(_search.length <= 0) return setNeighborhoods([])
    
    const searchReduce = _search.reduce((obj, { bairro }) => {
      obj["name"] = bairro
      return obj;
    }, {});

    return setNeighborhoods([searchReduce]);
  }

  function callbackConfirmAdd(neighborhoods){
    updateNeighborhoods(neighborhoods, loja._id, (error) => console.log(error))
  }

  if(!loja) return null;

  return (
    <div className="config-neighborhood">
      <h3>Bairros <span className="success">disponíveis</span></h3>
      <SearchNeighborhood data={neighborhoods || []} currentNeighborhoods={loja.bairros} searchAction={(search) => searchNeighborhoods(search)} callbackConfirmAdd={(data) => callbackConfirmAdd(data)} />
    </div>
  )
}

const mapStateToProps = state => ({
	loja: state.configuracao.loja,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(ConfigNeighborhood);