import { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import * as actions from '../../actions/categorias';

import { Notification, notify } from '../../utils/notification';

import Details from './details';
import Products from './products';

function Category({ admin, match, history, getCategoria, categoriaProdutos, category, updateCategoria, getCategoriaProdutos }) {

	const [notification, setNotification] = useState("");
	const [products, setProducts] = useState([]);

	useEffect(() => {
		if(!admin) return;
		getCategoria(match.params.id, admin.loja);
	}, [])

	useEffect(() => {
		if(!category) return;
		getCategoriaProdutos(category._id, 0, 30, admin.loja, (_products) => setProducts(_products));
	}, [category])

	return (
		<div className="Category container-centered">
			<Notification notification={notification} />
			<Details history={history} category={category} update={updateCategoria} notify={notify} setNotification={setNotification} />
			<Products admin={admin} products={products} category={category} getCategoriaProdutos={getCategoriaProdutos} />
		</div>
	)
}

const mapStateToProps = state => ({
	admin: state.auth.usuario,
	category: state.categoria.categoria,
	categoriaProdutos: state.categoria.categoriaProdutos
});

export default connect(mapStateToProps, actions)(Category);