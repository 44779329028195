import React, { Component } from 'react';
import moment from 'moment';
import Paginacao from '../../components/Paginacao/Simples';
import OrdersTable from '../../components/Table/orders'
import Loading from '../../components/Loading'

import { connect } from 'react-redux';
import * as actions from '../../actions/clientes';
import { formatMoney } from '../../actions';

class Orders extends Component {

	state = {
		atual: 0,
		limit: 10
	}

	getPedidos(){
		const { atual, limit } = this.state;
		const { usuario, id } = this.props;
		
		if(!usuario || !id) return null;
		this.props.getClientePedidos(id, atual, limit, usuario.loja);
	}

	componentDidMount(){
		if(this.props.history?.location.state && this.props.history.location.state.currentPage) console.log({ atual: this.props.history });
		this.getPedidos();
		if(localStorage.getItem("pendingView")){
			this.setState({ pendingView: true });
		}
		
	}

	handleSubmitPesquisa(){
		this.setState({ atual: 0 }, () => {
			const { atual, limit, pesquisa } = this.state;
			const { usuario } = this.props;
			if(!usuario) return null;
			this.props.getPedidosPesquisa(pesquisa, atual, limit, usuario.loja, JSON.parse(window.localStorage.getItem('orderFilters')));
		});
		
	}

	onChangePesquisa = (ev) => this.setState({ pesquisa: ev.target.value });

	changeNumberCurrent = (newNumber) => {
		this.setState({ atual: newNumber }, () => {
			this.getPedidos();
		});
	}

	refreshList = () => {
		this.setState({ refreshing: true })
		this.getPedidos();
		
		const timer = setTimeout(() => {
			this.setState({ refreshing: false })
			clearTimeout(timer)
		}, 3000);
	}

	cancelSeen = () => {
		localStorage.removeItem("pendingView")
		this.setState({ pendingView: false })
	}

	confirmSeen = async () => {
		const idSeen = localStorage.getItem("pendingView");
		await this.props.seenOrder(idSeen, this.props.usuario.loja, 2)
		localStorage.removeItem("pendingView")
		this.setState({ pendingView: false });
		this.refreshList();
	}

	render(){
		const { refreshing } = this.state;
		const { pedidos } = this.props;

		const dados = [];
		(pedidos?.docs ? pedidos.docs : []).forEach((item) => {
			console.log(item)
			if(!item || !item.pagamento || !item.pagamento.pedido) return null;
			dados.push({
				"Ações": `/pedido/${item.pagamento.pedido._id}`,
				"Data do Pedido": moment(item.pagamento.pedido.createdAt).format("DD/MM/YYYY"),
				"Valor": formatMoney(item.pagamento.valor || 0),
				"valueCart": formatMoney(item.pagamento.valueCart ? item.pagamento.valueCart : 0),
				"Situação": item.pagamento.pedido.cancelado ? "Cancelado" : item.pagamento ? item.pagamento.status : "Não encontrado",
				"Seen": item.pagamento.pedido.seen,
				"manual": item.pagamento.pedido.manualOrder ? item.pagamento.pedido.manualOrder : false,
				"paymentMethod": item.pagamento.forma || "Não encontrado",
				"ID": item.pagamento.pedido.simpleId ? item.pagamento.pedido.simpleId : item.pagamento.pedido._id,
				pedido: item.pagamento.pedido,
				registros: {
					pagamento: (item.registros || []).reduce((all, log) => (log.tipo === "pagamento") ? all.concat([log]) : all, []),
					entrega: (item.registros || []).reduce((all, log) => (log.tipo === "entrega") ? all.concat([log]) : all, [])
				},
				pagamento: {
					parcelas: item.pagamento.parcelas,
					status: item.pagamento.status
				},
				cliente: {
					whatsapp: item.pagamento.pedido.cliente?.telefones[0]
				}
			});
		});

		return (
			<>

				{ pedidos ? (
					<div className="orders">
						<h2>Pedidos realizados</h2>
						<OrdersTable admin={this.props.usuario} cabecalho={["ID", "Data do Pedido", "Valor", "Seen" ]} gridClass="OrdersClient" refreshState={refreshing} dados={dados} refreshList={() => this.refreshList()} />

						{/* <Paginacao atual={this.state.atual}
							total={this.props.pedidos ? this.props.pedidos.total : 0} 
							limite={this.state.limit} 
							onClick={(newNumber) => this.changeNumberCurrent(newNumber)}/> */}
					</div>
				) : <Loading /> }
			</>
		);
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario,
	pedidos: state.cliente.clientePedidos
});

export default connect(mapStateToProps, actions)(Orders);