import {GET_REPORT_PAYMENT, GET_REPORT_PRODUCTS, CLEAR_REPORTS, GET_REPORT_RE_OFFER_ACTION} from '../actions/types';

export default (state = {}, action) => {
	switch(action.type){
    case GET_REPORT_PAYMENT:
      return {
        ...state,
        payments: action.payload.payments
      }
    case GET_REPORT_PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };
    case GET_REPORT_RE_OFFER_ACTION:
      return {
        ...state,
        reOfferReducer: action.payload.reOfferRows
      };
    case CLEAR_REPORTS:
      return {
        ...state,
        payments: null,
        products: null
      }
    default:
      return state;
	}
}