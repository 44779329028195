import React from 'react';

const Pesquisa = ({ valor, onChange, placeholder, onClick, style }) => {

	function handleSubmit(e) {
		e.preventDefault();
		onClick();
	}

	return (
		<form onSubmit={handleSubmit} className="Pesquisa" style={style || {}}>
			<input valor={valor} onChange={onChange} placeholder={placeholder}/>
			<button type="submit">
				<i className="fas fa-search"/>
			</button>
		</form>
	)
}

export default Pesquisa;