import React, { Component } from 'react';

import InputSimples from '../../components/Inputs/Simples';
import Checkbox from '../../components/Inputs/Checkbox';
import Button from '../../components/Button/Simples';
import AlertGeral from '../../components/Alert/Geral';

import { connect } from 'react-redux';
import * as actions from '../../actions';
import { api, versao } from '../../config';

import { getHeaders } from '../../actions/localStorage';

import { ReactComponent as Logo } from '../../assets/juliafloresrj-logo.svg';

class Login extends Component {

	state = { 
		email: '',
		password: '',
		opcaoLembrar: true,
		error: null
	}

	onChangeInput = (field, ev) => this.setState({ [field]: ev.target.value });

	onChangeCheckbox = (field) => this.setState({ [field]: !this.state[field] });

	handleLogin(){
		const { email, password, opcaoLembrar } = this.state;

		if((!email || email === "") || (!password || password === "")){
			this.setState({ error: { status: false, msg: 'Por favor, preencha o email e senha.' } })
			let timer = setTimeout(() => {
				clearTimeout(timer);
				this.setState({ error: null })
			}, 5000);
			return;
		} else {
			this.props.handleLogin({email, password, opcaoLembrar}, (error) => {
				this.setState({ error: { status: false, msg: 'E-mail ou senha incorretos!' } });
				let timer = setTimeout(() => {
					clearTimeout(timer);
					this.setState({ error: null })
				}, 5000);
				return;
			});
		}
	}

	render(){
		const { email, password, opcaoLembrar, error } = this.state;
		if(getHeaders().headers.authorization.length > 30) return null;

		return (
			<div className="Login">
				<div className="login-content">
					<div className="logo">
						<Logo />
					</div>
					<div className="data-container">
						
						{ error ? (<AlertGeral aviso={error} time={5000} />) : ''}

						<InputSimples label="Email" value={email} type="email" onChange={(ev) => this.onChangeInput("email", ev)}/>
						<InputSimples label="Password" value={password} type="password" onChange={(ev) => this.onChangeInput("password", ev)}/>

						<div className="actions-login">
							<Checkbox label="Lembrar?" value={opcaoLembrar} onChange={() => this.onChangeCheckbox("opcaoLembrar")}/>
							<Button type="primary" label="Acessar" onClick={() => this.handleLogin()}/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Login);
