import { useEffect, useState } from 'react';
import Loading from '../../components/Loading';
import { Notification, notify } from '../../utils/notification';

import Voltar from '../../components/Links/Voltar';
import Titulo from '../../components/Texto/Titulo';
import ButtonToolbar from '../../components/Button/Toolbar';

import { connect } from 'react-redux';
import * as actions from '../../actions/upsells';

import moment from 'moment';

import { TextoDados } from '../../components/Texto/Dados';
import InputSimples from '../../components/Inputs/Simples';
import InputSelect from '../../components/Inputs/Select';
import SingleImage from '../../components/Imagens/Single';
import Table from '../../components/Table';
import { formatMoney, formatMoneyOnChange } from '../../utils'

function Upsell({ admin, match, history, getUpsell, getUpsellProdutos, upsell, upsellProdutos, updateUpsell, deleteUpsell, updateUpsellImage, limparUpsell }) {

	const [upsellState, setUpsellState] = useState({});
	const [errors, setErrors] = useState({});
	const [notification, setNotification] = useState("");

	useEffect(() => {
		if(!upsell) {
			getUpsell(match.params.id, admin?.loja);
			getUpsellProdutos(match.params.id, admin?.loja);
		}
		if(upsell && Object.keys(upsellState).length === 0){
			upsell.preco = formatMoney(upsell.preco) 
			return setUpsellState(upsell)
		}
	}, [match.params.id, upsell])

	function removeUpsell() {
		if(window.confirm("Você realmente deseja excluir este upsell?")){
      if(upsellProdutos.docs.length >= 1) return notify("Impossível excluir, existem produtos utilizando este upsell no momento!", setNotification, "error")
			deleteUpsell(admin.nome, upsell._id, admin.loja, (err) => {
				if(err) return notify("Erro ao remover.", setNotification, "error")
				notify("Excluído com sucesso!", setNotification)
				window.scrollTo(0, 0);
        history.push("/upsells")
			});
		}
  }

  const onChangeInput = (field, value) => setUpsellState({ ...upsellState, [field]: value });
  
  function handleUpdate(){
		if(!admin || !upsell) return null;
		updateUpsell(upsellState, upsell._id, admin.loja, (err) => {
			if(err) return notify("Erro ao atualizar as informações.", setNotification, "error")
			return notify("Atualizado com sucesso!", setNotification)
		});
  }
  
  function handleImageUpload(ev) {
		const data = new FormData();
		data.append("files", ev.target.files[0]);

		updateUpsellImage(data, upsell._id, admin.loja, (err) => {
			if(err) return notify("Erro ao atualizar a imagem!", setNotification, "error")
			return notify("Imagem atualizada!", setNotification)
		});
	}
	
	const dados = [];
	(upsellProdutos ? upsellProdutos.docs : []).forEach((item) => {
		dados.push({
			"Produto": item.titulo,
			"Criado em": moment(item.createdAt).format("DD/MM/YYYY"),
			"Disponível": item.disponibilidade ? "Sim" : "Não",
			"Ações": `/produto/${item._id}`
		});
	});
	
	return !upsell ? <Loading /> : (
		<div className="Product container-centered">
			<div className="head">
				<Voltar history={history} historyPath={"/upsells/"} />
				<ButtonToolbar type="danger" label="Excluir" icon="fas fa-trash" onClick={() => removeUpsell()} />
			</div>
			<div className="content">
				<div className="Page-Title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Titulo tipo="h1" titulo={upsellState.titulo}/>
					<div className="ButtonSubmit">
						<button onClick={() => handleUpdate()}>Salvar</button>
					</div>
				</div>
				<Notification notification={notification} />
			
				<InputSimples type="text" value={upsellState.titulo} label="Nome" error={errors.titulo} onChange={(e) => onChangeInput("titulo", e.target.value)} />

				<InputSimples type="text" value={upsellState.preco} label="Preço" error={errors.preco} onChange={(e) => onChangeInput("preco", formatMoneyOnChange(e.target.value))} />

				<TextoDados chave="Disponíbilidade" valor={(
				<InputSelect nome="disponibilidade" value={upsellState.disponibilidade} opcoes={[
						{ label: "Disponível", value: true },
						{ label: "Indisponível", value: false }
					]} onChange={(ev) => onChangeInput("disponibilidade", ev.target.value)} />
				)} />

				{ upsell.fotos && <SingleImage image={upsell.fotos[0]} handleSubmit={handleImageUpload} />}
				
				<div className="upsell-products">
					<h2>Produtos conectados</h2>
					{ upsellProdutos && upsellProdutos.total > 0 ? (
						<>
							<Table cabecalho={["Produto", "Criado em", "Disponível"]} dados={dados} gridClass="Products productsUpsell" />
						</>
					) : (<h2 className="NotFoundData">Este Upsell não está sendo utilizado ainda.</h2>)}
				</div>

				</div>
		</div>
	)
}

const mapStateToProps = state => ({
  upsell: state.upsell.upsell,
  upsellProdutos: state.upsell.upsellProdutos,
	admin: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Upsell);