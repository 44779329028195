import React, { useState, useRef, useEffect } from 'react';
import { useOutsideAlerter } from '../../utils';
import useDebounce from '../../utils/useDebounce';

const SearchClient = ({ data, searchAction, callback, accessAction }) => {
  
  const [displayValue, setDisplayValue] = useState('');
  const debounceChange = useDebounce(searchAction, 500);

  const [searching, setSearching] = useState(false);
  const [dataSelected, setDataSelected] = useState(false);
  const [user, setUser] = useState([]);
  
  const closeSearch = () => {
    if(dataSelected && user.nome) {
      setSearching(false)
      setDataSelected(false)
      return
    }
    setSearching(false)
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => closeSearch());

  function handleChange(e) {
    !searching && setSearching(true)
    if (user.nome) {
      setDataSelected(false)
      setDisplayValue('')
      setSearching(false)
      setUser([])
    } else {
      setDisplayValue(e.target.value)
    }
    debounceChange(e.target.value)
    return
  }

  const selectData = (client) => {
    setDisplayValue(`${client.nome} - ${client.email}`)
    setDataSelected(true)
    setSearching(false)
    setUser(client)
    callback(client)
    accessAction()
  }

  return (
    <div ref={wrapperRef} className={`inputSearchItems${ dataSelected ? ' selected' : ''}`} onClick={() => user.nome && setDataSelected(false)}>
      { dataSelected && (<h3>Cliente</h3>) }
      <input name="client" placeholder="Pesquisar Cliente" value={displayValue} onChange={handleChange} onClick={() => !searching && setSearching(true)} />
      { (data && searching) && (
        <div className="result-search">          
          { data.length > 0 && data.map((item, idx) => (
            <div key={idx} className="item" onClick={() => selectData(item)}>
              {`${item.nome} - ${item.email}`}
            </div>
          )) }
        </div>
      ) }
    </div>
  )
}

export default SearchClient;