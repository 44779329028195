import { useEffect, useState } from 'react'
import OrderPrint from '../../components/Card/OrderPrint'
import Loading from '../../components/Loading'
import moment from 'moment';

import { ReactComponent as IconView } from '../../assets/icon-view.svg'
import { ReactComponent as IconViewNot } from '../../assets/icon-viewnot.svg'
import { api } from '../../config'

export default function ProductsReports({ reports, handleFind, clearReports, loading, generatePdfProductsReport }) {

  const [initial, setInitial] = useState("")
  const [final, setFinal] = useState("")

  const [productView, setProductView] = useState(0)
  const [printing, setPrinting] = useState(0)

  const [loadingPDF, setLoadingPDF] = useState(false)

  useEffect(() => {
    clearReports();
  }, []);

  function generatePdf() {
    setLoadingPDF(true)
    generatePdfProductsReport({ initial, final }, "60242e47e43639133fb5da2f", (report) => {
      setLoadingPDF(false)
      window.open(`${api}/${report.url}`)
    })
  }

  return (
    <div className="report-section">
      <h3>Produtos a serem entregues</h3>
      <h2>Listagem dos pedidos com <span>Entrega Agendada.</span></h2>
      <div className="report-search">
        <div className="input-search">
          <label>Do dia</label>
          <input min={moment(new Date()).format("YYYY-MM-DD")} defaultValue={moment(new Date()).format("YYYY-MM-DD")} type="date" name="date" autoComplete="new-password" placeholder="Do dia" onChange={(e) => setInitial(e.target.value)} />
        </div>
        <div className="input-search">
          <label>Ao dia</label>
          <input type="date" name="date" autoComplete="new-password" placeholder="Ao dia" onChange={(e) => setFinal(e.target.value)} />
        </div>
        <button onClick={() => handleFind({ initial, final })}>Gerar Relatório</button>
      </div>
      
      { (loading || loadingPDF) ? <Loading /> : (reports && (
          <div className="report-details products">
          <div className="product-list">
            { Object.keys(reports).map((item, index) => (
              <div className="product-item" key={index}>
                <div className="item-data" onClick={() => setProductView(productView === index+1 ? 0 : index+1)}>
                  <div>
                    <label>{reports[item].length}</label>
                    <span>{item}</span>
                  </div>
                  { productView === index+1 ? <IconViewNot className="button-view" width={24} /> : <IconView className="button-view" width={24} /> }
                </div>
                { productView === index+1 && (
                  <div className="item-details">
                    <h3>Entregas</h3>
                    {reports[item].map((order, indexOrder) => (
                      <>
                        { printing === index+1 && <OrderPrint data={order} cliente={{ telefones: [""]}} onClick={() => setPrinting(0)} />}
                        <div className="order-item" key={indexOrder}>
                          <div>ID Pedido/Entrega <span>{order.pedido.simpleId}</span></div>
                          <div>Quem Envia <span>{order.entrega.shippingSender}</span></div>
                          <div>Quem Recebe <span>{order.entrega.shippingRecipient}</span></div>
                          <div>Agendada para <span>{order.entrega.shippingDate}</span></div>
                          <button className="view-order" title="Visualizar resumo do pedido" onClick={() => setPrinting(index+1)}>
                            <i className="fa fa-print"></i>
                          </button>
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            )) }

            { Object.keys(reports).length > 0 ? <button type="button" className="generatePdf" onClick={generatePdf}>Imprimir Relatório</button> : <div className="notFound">Não há produtos a serem entregues!</div>}
          </div>
        </div>
      )) }
    </div>
  )
}