import { useState, useEffect, useRef } from 'react';

import Logo from '../../../images/logo.png';

import ReactToPrint from "react-to-print";
import html2canvas from 'html2canvas';
import { FiX, FiPrinter, FiCopy } from 'react-icons/fi';

import { Notification, notify } from '../../utils/notification';

function CardTemplate({ data, ref }) {
  return (
    <div className="cardDelivery" id="cardDelivery" ref={ref} >
      <div id="WrapperCardPrint"></div>
      <div className="cardDelivery_header">
        <div className="spanFromTo">
          <span>De</span>
          <h6>{data.shippingSender}</h6>
        </div>
        <div className="spanFromTo">
          <span>Para</span>
          <h6>{data.shippingRecipient}</h6>
        </div>
      </div>
      <p className={`cardMessage ${
        (data.shippingMessage.length > 120 && data.shippingMessage.length < 300) ? 'small' : '' ||
        (data.shippingMessage.length > 300) ? 'smaller' : ''
      }`}>{console.log(data.shippingMessage)}{String(data.shippingMessage)}</p>
      <img className="logo" src={Logo} alt="Julia Flores RJ" />
    </div>
  )
}

function CardPrint({ data, onClick, copy }) {
  
  const [notification, setNotification] = useState("")
  const componentRef = useRef();

  function handlePrepareCanvas() {

    var card = document.getElementById("cardDelivery")

    html2canvas(card)
      .then((canvas) => {
        card.remove();
        document.getElementById("WrapperPrint").appendChild(canvas)
      })
    ;
  }

  useEffect(() => {
    handlePrepareCanvas()
  }, [])

  function copyText(message) {
    navigator.clipboard.writeText(message)
    notify("Mensagem copiada com sucesso!", setNotification)
  }

  return (
    <div className="OrderPrint" id="OrderPrint">
      <CardTemplate data={data} />
      <Notification notification={notification} />
      <div id="WrapperPrint" ref={componentRef}></div>
      <div className="actions">
        <button onClick={() => copyText(String(data.shippingMessage))} className="btntool copy"><FiCopy /></button>
        <ReactToPrint 
          trigger={() => <button className="btntool confirm"><FiPrinter /></button>}
          content={() => componentRef.current}
        />
        <button onClick={onClick} className="btntool cancel"><FiX /></button>
      </div>
    </div>
  )
}

export default CardPrint;