import React, {useEffect, useState, useRef} from 'react';

import Titulo from '../../components/Texto/Titulo';
import Pesquisa from '../../components/Inputs/Pesquisa';
import Paginacao from '../../components/Paginacao/Simples';

import {connect} from 'react-redux';
import * as actions from '../../actions/clientes';
import {Link} from 'react-router-dom';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import { useDispatch } from 'react-redux';

import {CSVLink, CSVDownload} from "react-csv";
import { Parser } from 'json2csv';
import * as XLSX from 'xlsx'; // Import all named exports from 'xlsx'
import {saveAs} from 'file-saver';
import axios from "axios";
import {api, versao} from "../../config";
import {getHeaders} from "../../actions/localStorage";

function Clients({clientes, admin, getClientes, getClientesAll, getClientesPesquisa}) {

    const [inputSearch, setInputSearch] = useState({state: false, text: ""});
    const [paginationState, setPaginationState] = useState({current: 0, limit: 30});
    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleClients() {
        if (inputSearch.state) getClientesPesquisa(inputSearch.text, paginationState.current, paginationState.limit, admin.loja);
        else getClientes(paginationState.current, paginationState.limit, admin.loja);

        setInputSearch({...paginationState, state: false})
    }

    useEffect(() => {
        if (admin && !clientes) handleClients();
    }, [])

    function refreshList() {
        setRefreshing(true);

        handleClients();

        const timer = setTimeout(() => {
            setRefreshing(false)
            clearTimeout(timer)
        }, 1800);
    }

    function handleSubmitPesquisa() {
        setPaginationState({...paginationState, current: 0});
        return handleClients();
    }

    const changeCurrent = (current) => {
        setPaginationState({...paginationState, current});
        handleClients()
    }


    const generateExcel = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}/${versao}/api/clientes?loja=${admin.loja}&offset=${paginationState.current}&limit=${clientes.total}&sortType=decrescente`, getHeaders());
            const clientesAll = response.data.clientes;

            const dados = (clientesAll?.docs || []).map(item => {
                const nameParts = item.nome.split(' ');
                const firstName = nameParts[0] || '';
                const lastName = nameParts.slice(1).join(' ') || '';

                return {
                    "Email": item.email,
                    "Phone": item.telefones[0],
                    "First name": firstName,
                    "Last name": lastName,
                    "Zip": '22705085',
                    "Country":"BR"
                };
            });

            // Create a new workbook
            const workbook = XLSX.utils.book_new();

            // Convert data to worksheet
            const worksheet = XLSX.utils.json_to_sheet(dados);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

            // Write workbook to a binary Excel file and save it
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(excelBlob, 'clientes.xlsx');
        } catch (error) {
            console.error("Error generating Excel file:", error);
        } finally {
            setLoading(false);
        }
    };


    const generateCSV = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${api}/${versao}/api/clientes?loja=${admin.loja}&offset=${paginationState.current}&limit=${clientes.total}&sortType=decrescente`, getHeaders());
            const clientesAll = response.data.clientes;

            const dados = (clientesAll?.docs || []).map(item => {
                const nameParts = item.nome.split(' ');
                const firstName = nameParts[0] || '';
                const lastName = nameParts.slice(1).join(' ') || '';

                return {
                    "Email": item.email,
                    "Phone": item.telefones[0],
                    "First name": firstName,
                    "Last name": lastName,
                    "Zip": '22705085',
                    "Country": "BR"
                };
            });

            // Convert data to CSV
            const json2csvParser = new Parser();
            const csv = json2csvParser.parse(dados);

            // Create a blob and save the CSV file
            const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            saveAs(csvBlob, 'clientes.csv');
        } catch (error) {
            console.error("Error generating CSV file:", error);
        } finally {
            setLoading(false);
        }
    };

    const dados = [];

    (clientes?.docs || []).forEach((item) => {
        dados.push({
            "Ações": `/cliente/${item._id}`,
            "Cliente": item.nome,
            "E-mail": item.email,
            "Telefone": item.telefones[0],
            "CPF": item.cpf
        });
    });

    return clientes ? (
        <div className="Clients">
            <div className="Page-Title">
                <Titulo tipo="h1" titulo="Clientes"/>
            </div>

            <div className="actionsTollBar">
                <Pesquisa valor={inputSearch} style={{margin: 0}} placeholder="Pesquisar Cliente"
                          onChange={(ev) => setInputSearch({state: true, text: ev.target.value})}
                          onClick={() => handleSubmitPesquisa()}/>

                {/*<Link onClick={handleAllClients}>*/}
                {/*	<div className="btnIcon fas fa-users" title={'Todos os clientes'}></div>*/}
                {/*</Link>*/}
                <button title="CSV" className="btnIcon fas fa-file-csv"
                        onClick={generateCSV}>        {loading ? <Loading/> : ''}</button>
                <button title="XML facebook store" className="btnIcon fas fa-file-excel"
                        onClick={generateExcel}>        {loading ? <Loading/> : ''}</button>
                <Link to="/newclient">
                    <div className="btnIcon plus"></div>
                </Link>
            </div>

            <Table cabecalho={["Cliente", "E-mail", "Telefone", "CPF"]} gridClass="Clients"
                   refreshState={refreshing || ''} dados={dados} refreshList={() => refreshList()}/>

            {/*<Link className="btnIcon plus" onClick={handleAllClients}>Download transactions to csv</Link>*/}
            {/*<CSVLink data={clientes?.docs}  filename='clientes.csv'*/}
            {/*		 target='_blank'*/}
            {/*><div className="btnIcon fas fa-file" title={"Download CSV"}></div></CSVLink>*/}
            <Paginacao atual={paginationState.current} total={clientes?.total || 1} limite={paginationState.limit}
                       onClick={(numeroAtual) => changeCurrent(numeroAtual)}/>
        </div>
    ) : <Loading/>;
}

const mapStateToProps = state => ({
    clientes: state.cliente.clientes,
    admin: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Clients);