import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../css/newOrder.css'

import { connect } from 'react-redux';
import * as actions from '../../actions/newOrder';
import { formatCPF, formatMoney } from '../../actions';
import useDebounce from '../../utils/useDebounce';

import Voltar from '../../components/Links/Voltar';
import SearchClient from '../../components/Search/Client';
import SearchProducts from '../../components/Search/Products';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/Form/Input';
import TextArea from '../../components/Form/TextArea';
import { FaInfo, FaEdit } from 'react-icons/fa'

import DatePicker, { registerLocale }  from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import br from 'date-fns/locale/pt-BR';
import SearchUpsells from '../../components/Search/Upsells';

const NewOrder = ({ history, usuario, clientes, cliente, produtos, upsells, getClientesPesquisa, getProdutosPesquisa, getUpsellsPesquisa, newOrderManual, createNewCliente }) => {

  registerLocale('br', br)

  const [clientSelection, setClientSelection] = useState(true);
  const [getClient, setGetClient] = useState(false);
  const [newClient, setNewClient] = useState(false);

  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const [cart, setCart] = useState([]);
  const [cartUpsell, setCartUpsell] = useState([]);
  const [errorsGeneral, setErrorsGeneral] = useState(false);
  const [client, setClient] = useState({})
  const [accessSection, setAccessSection] = useState(1)
  const [documentExist, setDocumentExist] = useState(false);
  const [responseSuccess, setResponseSuccess] = useState({});

  const [form, setForm] = useState({ cpf: null });

  const searchClient = (search) => {
    getClientesPesquisa(search, usuario.loja);
  }

  const searchProducts = (search) => {
    getProdutosPesquisa(search, usuario.loja);
  }

  const searchUpsells = (search) => {
    getUpsellsPesquisa(search, usuario.loja);
  }

  const timerFn = (callback) => {
    let timer;
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback()
    }, 5000)

    return
  }

  const goSuccess = (response) => {
    setAccessSection(3)
    setResponseSuccess(response)
  }

  const handleAdd = (data) => {
    newClient && createNewCliente(data, usuario.loja, (error, success) => {
      console.log(error)
      if(error) {
        setDocumentExist('Este CPF já está sendo utilizado!');
        timerFn(() => setDocumentExist(false))
        return
      } else {
      setDocumentExist(false)
        setNewClient(false)
        setClient(success.cliente)
        setAccessSection(2)
      }
    })

    if(!client || newClient){
      setErrorsGeneral("Por favor, informe os dados do cliente.")
      timerFn(() => setErrorsGeneral(false))
      return
    } else setErrorsGeneral(false);

    if(!data.deliveryValue || data.deliveryValue === ''){
      setErrorsGeneral("Por favor, informe o valor de entrega.")
      timerFn(() => setErrorsGeneral(false))
      return
    } else setErrorsGeneral(false);

    newOrderManual({ form: data, client, cart, cartUpsell }, (response) => goSuccess(response))
  }

  const debounceSubmit = useDebounce(handleAdd, 500);

  const cartCallback = (newCart) => {
    const _reduceCart = (newCart || []).reduce((prev, current) => {
      return prev + +(current.price*current.quantity)
    }, 0)
    setCart({ items: newCart, total: _reduceCart });
  }

  const cartCallbackUpsell = (newCart) => {
    const _reduceCart = (newCart || []).reduce((prev, current) => {
      return prev + +(current.price*current.quantity)
    }, 0)
    setCartUpsell({ items: newCart, total: _reduceCart });
  }

  return (
    <div className="newOrder container-centered">
      <div className="head">
        <Voltar history={history} />
      </div>
      <div className="content">
          { accessSection < 3 ? (
            <>
              <h1>Criar novo pedido</h1>

              <form className="form" onSubmit={handleSubmit(debounceSubmit)}>
                <div className="sectionClient">

                  { (!newClient && !getClient) && accessSection === 1 && clientSelection && (
                    <div className="clientSelection">
                      <button onClick={() => setGetClient(true)}>Selecionar cliente existente</button>
                      <button onClick={() => setNewClient(true)}>Adicionar novo cliente</button>
                    </div>
                  )}

                  { accessSection === 1 && newClient && (
                    <div className="newClient">
                      <h2>Adicionando novo cliente</h2>
                      <div className="item">
                        <div className="double">
                          <Input type="text" errors={errors} register={{...register('clientName', { required: true })}} name="clientName" placeholder="Nome Completo" />
                          <Input type="text" errors={errors} register={{...register('clientCpf')}} value={form.cpf} name="clientCpf" placeholder="CPF" onChange={(e) => setForm({ ...form, cpf: formatCPF(e.target.value) }) } />
                        </div>
                        <div className="double">
                          <Input type="text" errors={errors} register={{...register('clientEmail')}} name="clientEmail" placeholder="E-mail" />
                          <Input type="text" errors={errors} register={{...register('clientPhone')}} name="clientPhone" placeholder="Telefone" />
                        </div>
                        <div className="ButtonSubmit">
                          { documentExist ? (
                            <div className="errors">
                              <span><FaInfo /> {documentExist}</span>
                            </div>
                          ) : '' }
                          <button>Adicionar Cliente</button>
                        </div>
                      </div>
                      <span className="added">Cliente já existe? <button onClick={() => { setNewClient(false); setGetClient(true) }}>Voltar para a Busca</button></span>
                    </div>
                  )}
                    
                  { accessSection === 1 && getClient && (
                    <div className="newClient">
                      <h2>Selecionar cliente existente</h2>
                      <SearchClient data={clientes?.docs} searchAction={(search) => searchClient(search)} callback={(clientProps) => setClient(clientProps)} accessAction={() => setAccessSection(2)} />
                      <span className="added">Não encontrou o cliente? <button onClick={() => { setNewClient(true); setGetClient(false) }}>Clique aqui para adicionar um novo</button></span>
                    </div>
                  ) }
                  
                  { accessSection === 2 && (
                    <div className="clientConnected">
                      <h2>Cliente</h2>
                      <h4 onClick={() => { setClient({}); setAccessSection(1); setNewClient(false) }}>{client.nome} <FaEdit /></h4>
                    </div>
                  ) }
              </div>
    
              { accessSection === 2 ? (
                <div className="stepTwo">
                  <SearchProducts data={produtos} cartCallback={(newCart) => cartCallback(newCart)} searchAction={(search) => searchProducts(search)} />
    
                  <SearchUpsells data={upsells} cartCallback={(newCart) => cartCallbackUpsell(newCart)} searchAction={(search) => searchUpsells(search)} />

                  <div className="formSection">
                    <h2>Dados de entrega</h2>
                    <div className="streetAndNumber">
                      <Input type="text" errors={errors} register={{...register('deliveryStreet', { required: true })}} name="deliveryStreet" placeholder="Endereço" />
                      <Input type="text" errors={errors} register={{...register('deliveryNumber', { required: true })}} name="deliveryNumber" placeholder="Número" />
                    </div>
                    <div className="double">
                      <Input type="text" errors={errors} register={{...register('deliveryNeighbor', { required: true })}} name="deliveryNeighbor" placeholder="Bairro" />
                      <Input type="text" errors={errors} register={{...register('deliveryComplement')}} name="deliveryNeighbor" placeholder="Complemento" />
                    </div>
                    <div className="double">
                      <div className="Input inputDate">
                        <Controller
                          control={control}
                          name='deliveryDate'
                          render={({ field }) => (
                            <DatePicker locale="br" dateFormat="dd/MM/yyyy"
                              placeholderText='Data da Entrega'
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                            />
                        )} />
                      </div>
    
                      <div className="Input Select">
                        <select {...register("deliveryPeriod")}>
                          <option value="Manhã 9h às 12h">Manhã 9h às 12h</option>
                          <option value="Tarde 12h às 18h">Tarde 12h às 18h</option>
                          <option value="Noite 18h às 21h">Noite 18h às 21h</option>
                        </select>
                      </div>
                    </div>
                  </div>
    
                  <div className="formSection">
                    <h2>Dados do Cartão</h2>
                    <div className="double">
                      <Input type="text" errors={errors} register={{...register('senderShipping', { required: true })}} name="senderShipping" placeholder="Quem Envia" />
                      <Input type="text" errors={errors} register={{...register('senderReciever', { required: true })}} name="senderReciever" placeholder="Quem Recebe" />
                    </div>
                    <TextArea rows="4" errors={errors} register={{...register('messageCard')}} name="messageCard" placeholder="Mensagem no cartão" />
                  </div>
    
                  { cart.total > 20 && (
                    <div className="formSection flex-end align-right">
                      <h2>Detalhes</h2>
                      <div className="details">
                        <div className="item">
                          <h4>Valor do Pedido</h4>
                          <span>{formatMoney((Number(cart.total + (cartUpsell?.total? cartUpsell.total : 0))))}</span>
                        </div>
                        <div className="item">
                          <h4>Valor da Entrega</h4>
                          <div className="inputValue">
                            <span>R$</span>
                            <Input type="text" errors={errors} register={{...register('deliveryValue')}} name="deliveryValue" placeholder="Valor" />
                            <span>,00</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) }
    
                  <div className="formSection">
                    <h2>Observação</h2>
                    <TextArea rows="4" errors={errors} register={{...register('observation')}} name="observation" placeholder="Alguma mensagem?" />
                  </div>
    
                  <div className="ButtonSubmit">
                    { errorsGeneral ? (
                      <div className="errors">
                        <span><FaInfo /> {errorsGeneral}</span>
                      </div>
                    ) : '' }
                    <button>Finalizar Pedido</button>
                  </div>
                </div>
                ) : '' }
              
              </form>
            </>
          ) : (
            <div className="success">
              <h1>Tudo certo!</h1>
              <p>Pedido criado com sucesso, clique no botão abaixo para ver mais :D</p>
              <div className="action">
                <Link to={`/`}>Ver Pedidos</Link>
              </div>
            </div>
          )}

      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
	usuario: state.auth.usuario,
	clientes: state.cliente.clientes,
	cliente: state.cliente.cliente,
	produtos: state.produto.produtos,
  upsells: state.upsell.upsells
});

export default connect(mapStateToProps, actions)(NewOrder);