import React, { Component, useEffect, useState } from 'react';

import Titulo from '../../components/Texto/Titulo';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import * as actions from '../../actions/categorias';
import Table from '../../components/Table';
import Loading from '../../components/Loading';

function Categories({ categorias: categories, usuario: admin, getCategorias, getCategoriaProdutos }) {
	const [refreshing, setRefreshing] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		if(!categories && admin) getCategorias(admin.loja);
	}, []);

	async function getProducts() {
		const _data = await Promise.all(categories?.map(async (item) => {
			return await new Promise((resolve) => getCategoriaProdutos(item._id, 0, 100, admin.loja, __products => {
				resolve({
					"Ações": `/categoria/${item._id}`,
					"Categoria": item.nome,
					"Quantidade de Produtos": __products?.length,
					"Ordem": item.ordem,
				});
			}));
		}));

		setData(_data);
	}

	useEffect(() => {
		if(!categories) return;

		getProducts();
	}, [categories])

	const refreshList = () => {
		setRefreshing(true)
		getCategorias(admin.loja)

		const timer = setTimeout(() => {
			setRefreshing(false)
			clearTimeout(timer)
		}, 1800);
	}

	return data?.length > 0 ? (
		<div className="Categories container-centered">
			<div className="Page-Title">
				<Titulo tipo="h1" titulo="Categorias"/>
				<div className="actionsTollBar">
					<Link to="/newcategory">
						<div className="btnIcon plus" style={{ marginLeft: 0 }}></div>
					</Link>
				</div>
			</div>

			<Table cabecalho={["Categoria", "Quantidade de Produtos", "Ordem"]} gridClass="Categories" refreshState={refreshing || ''} dados={data} refreshList={() => refreshList()} />
			
		</div>
	) : <Loading />;
}

const mapStateToProps = state => ({
	categorias: state.categoria.categorias,
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, actions)(Categories);