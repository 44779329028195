import React, { Component } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { ReactComponent as IconEdit } from '../../assets/icon-edit.svg'

class InputValor extends Component {
	state = {
		value: this.props.value,
		form: false
	}

	onChange = (ev) => {
		this.setState({ value: ev.target.value })
	};

	toggleForm = () => this.setState({ form: !this.state.form, value: this.props.value });

	handleSubmit(value){
		this.props.handleSubmit(value);
		this.toggleForm();
	}

	renderForm(){
		const { value } = this.state;
		const { erro } = this.props;
		return (
			<div className="value">
				<input value={value} onChange={this.onChange} name={this.props.name} autoComplete="new-password" type={ this.props.type || "text" } />
				<button className="success" onClick={() => this.handleSubmit(value)}><FaCheck /></button>
				<button className="danger" onClick={this.toggleForm}><FaTimes /></button>
			</div>
		)
	}

	renderValue(){
		const { value, erro } = this.props;
		return (
			<div className="edit" onClick={() => this.toggleForm()}>
				{ value !== "" && (<span className="wrapper-value">{ value }</span>)}
				{ erro && (<small className="error-message">{erro}</small>)}
				<IconEdit height={20} fill="var(--gray-dark)" />
			</div>
		)
	}

	render(){
		const { form } = this.state;
		return (form) ? this.renderForm() : this.renderValue();
	}

}

export default InputValor;