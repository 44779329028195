import React from 'react';
import ButtonSimples from '../Button/Simples';
import InputSimples from '../Inputs/Simples';

class ListaDinamicaSimples extends React.Component {

	state = { texto: "" }
	onChangeInput = (ev) => this.setState({ texto: ev.target.value });

	onAdd(){
		const { texto } = this.state;
		this.props.onAdd(texto);
		this.setState({ texto: "" });
	}

	render(){
		const { dados, onRemove } = this.props;
		const { texto } = this.state;
		return (
			<div className="List-Status">
				<div className="list-add">
					<input type="text" value={texto} placeholder="Adicionar Novo" onChange={this.onChangeInput} />
					<button onClick={() => this.onAdd(texto)}>+</button>
				</div>
				{ dados.map((item, idx) => (
					<div key={idx} className="status">
						<span>{item}</span>
						<button onClick={() => onRemove(idx)}>-</button>
					</div>
				))
				}
			</div>
		)
	}
}

export default ListaDinamicaSimples;