import React, { Component } from 'react';

import DetalhesDoProduto from './DetalhesDoProduto';
import UpsellInProduct from './UpsellInProduct';

import { connect } from 'react-redux';
import * as actionsProdutos from '../../actions/produtos';
import * as actionsCategorias from '../../actions/categorias'; 
import * as actionsUpsells from '../../actions/upsells'; 

class Produto extends Component {

	componentDidMount(){
		const { usuario, getProduto, getCategorias, getUpsells } = this.props;
		if(!usuario) return;
		getProduto(this.props.match.params.id, usuario.loja);
		getCategorias(usuario.loja);
		getUpsells("alfabetica_a-z", 0, 30, usuario.loja);
	}

	render(){
		return (
			<div className="Product container-centered">
				<DetalhesDoProduto history={this.props.history} />
				{/* <DetalhesDasVariacoes /> */}
				<UpsellInProduct />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	usuario: state.auth.usuario
});

export default connect(mapStateToProps, { ...actionsProdutos, ...actionsUpsells, ...actionsCategorias })(Produto);