import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ type, onClick, label, icon }) => (<button onClick={onClick} className={`btntool btntool-${type || 'default'}`}><i className={icon}></i><span>{label}</span></button>)

const ButtonToolbar = ({ type, rota, onClick, label, icon }) => {
	if(rota){
		return (<Link to={rota}><Button type={type} onClick={onClick} label={label} icon={icon} /></Link>)
	} else return <Button type={type} onClick={onClick} label={label} icon={icon} />
}

export default ButtonToolbar;