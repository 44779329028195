import {getHeaders} from './localStorage';
import axios from 'axios';
import {api, versao} from '../config';
import errorHandling from './errorHandling';
import {
    GET_ENTREGAS,
    GET_ENTREGA,
    LIMPAR_ENTREGA
} from './types';

export const getEntregas = (atual, limit, loja) => {
    return function (dispatch) {
        axios.get(`${api}/${versao}/api/entregas/admin?offset=${atual}&limit=${limit}&loja=${loja}&sortType=decrescente`, getHeaders())
            .then(response => dispatch({type: GET_ENTREGAS, payload: response.data}))
            .catch(errorHandling);
    }
}

export const getEntrega = (id, loja) => {
    return function (dispatch) {
        axios.get(`${api}/${versao}/api/entregas/admin/${id}?loja=${loja}`, getHeaders())
            .then(response => dispatch({type: GET_ENTREGA, payload: response.data}))
            .catch(errorHandling);
    }
}

export const limparEntrega = () => ({type: LIMPAR_ENTREGA});

export const updateEntregaSender = (id, loja, fieldAndValue) => {
    return function (dispatch) {
        axios.put(`${api}/${versao}/api/entregas/admin/${id}/updateSender?loja=${loja}`, fieldAndValue, getHeaders())
            .then(response => {

            })
            .catch(e => console.log(e));
    };
};